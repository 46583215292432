/* eslint-disable camelcase */
import React from 'react'
import { compose } from 'redux';
import { Field, GenericField, reduxForm } from 'redux-form';
import { validation } from '../../utils/constants';
import InputComponent from '../input/form/input';
import CheckboxComponent from '../input/form/checkbox';
import { useDispatch } from 'react-redux';
import { warning } from 'react-notification-system-redux';
import { css } from '@emotion/react';
interface ExamPlacementOrderCustomProps {
  label: string;
  placeholder: string;
  onlyNumbers: boolean
}

const FieldCustom = Field as new () => GenericField<ExamPlacementOrderCustomProps>;

const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #FDFEFF;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

const form_name = 'examPlacementForm';


export const ExamPlacementFormBody = (props: {
  not_allowed_orders: string[]
}) => {
  const {not_allowed_orders} = props
  const dispatch = useDispatch()
  const handleOrderChange = (event:React.ChangeEvent<any> | undefined, value: string) => {
    if(not_allowed_orders.includes(value)){
      event?.preventDefault()
      dispatch(
        warning({
          message: "Ordem escolhida já está sendo utilizada"
        })
      )
    }
  }
  
  return (
    <div style={{ width: '100%' }}>
      <Field
        name='name'
        component={InputComponent}
        label={'Nome'}
        placeholder={'Insira o nome da avaliação'}
        validate={[validation.required]}
      />
      <FieldCustom
        label='Ordem'
        component={InputComponent}
        onChange={handleOrderChange}
        name='order'
        onlyNumbers
        placeholder={'Ordem'}
        validate={[validation.required]}
      />
      <Field
        name='mandatory'
        component={CheckboxComponent}
        placeholder={'Avaliação Mandatória'}
      />
      <Field
        name='replaceable'
        component={CheckboxComponent}
        placeholder={'Substituível'}
      />
    </div>
  );
};

const ExamPlacementForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  close_form: () => void;
  not_allowed_orders: string[]
}) => {
  const { handleSubmit, onSubmit, close_form, not_allowed_orders } = props;

  return (
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar Avaliação</span>
      <form css={useStyles.form}>
        <ExamPlacementFormBody not_allowed_orders={not_allowed_orders} />
        <div css={useStyles.buttons}>
          <button
            onClick={(e) => {
              e.preventDefault();
              close_form();
            }}
            className='red small'
          >
            <span> Cancelar </span>
          </button>
          <button onClick={handleSubmit(onSubmit)} className='blue small'>
            <span> Salvar Avaliação </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(ExamPlacementForm);
