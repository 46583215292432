/* eslint-disable camelcase */
import React from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, change } from 'redux-form';

import DatePickerComponent from '../input/form/datepicker';
import SelectMultipleComponent from '../input/form/selectmultiple';

import {
  colors,
  validation,
  ProductStepFormData,
  DefaultOptionType,
  ProductOptions,
  PaymentOptionAttributes,
  PaginationType,
  RepresentativeAttributes,
} from '../../utils/constants';
import Loading from '../loading/Loading';
import { selectProductPaymentSimulation } from '../../utils/paymentSimulation';
import InsertProductStepTable from '../table/InsertProductStepTable';
import InsertProductFilters from '../filters/InsertProductFilters';
import InputComponent from '../input/form/input';
import { checkResourceActivity } from '../../utils/functions';
import { css } from '@emotion/react';

const useStyles = {
  buttons: css`
    margin-top: 4.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
  `,
  form: css`
    background: ${colors.lightBlue};
    padding: 2rem 1rem;
    display: grid;
    row-gap: 0.5rem;

    & .title {
      font-weight: bold;
      font-size: 1.75rem;
      justify-self: center;
      margin-bottom: 1rem;
    }

    & .subtitle-one {
      justify-self: start;
    }
  `,
  paymentOption: css`
    padding: 1rem;
    background-color: ${colors.darkBlue};
    display: grid;
    row-gap: 1rem;
    position: relative;

    & span {
      color: white;
    }
  `,
  paymentBlock: css`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0.5);
  `
};

const ActionButton = ({
  payment_option,
  option,
  disabled
}: {
  payment_option: PaymentOptionAttributes;
  option: PaymentOptionAttributes;
  disabled: boolean
}) => {
  const dispatch = useDispatch();
  if (payment_option?.id === option.id) {
    return (
      <button
        className='red'
        onClick={(e) => {
          e.preventDefault();
          dispatch(change('registration-productstep', 'payment_option', null));
        }}
      >
        Desfazer
      </button>
    );
  }

  return (
    <button
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        dispatch(change('registration-productstep', 'payment_option', option));
      }}
      className='green'
    >
      Selecionar
    </button>
  );
};

const PaymentOption = ({
  currentProduct,
  currentPaymentOption,
  option,
  provisionMonths,
  disabled = false
}: {
  currentProduct: ProductOptions;
  currentPaymentOption: PaymentOptionAttributes;
  option: PaymentOptionAttributes;
  provisionMonths: number[];
  disabled: boolean
}) => {
  const { parsedTotal, parsedPortionsValue, parsedRegistrationFee, description } = selectProductPaymentSimulation({
    product: currentProduct,
    payment_option_portions: option.portions,
    provision_months: provisionMonths,
  });
  const blockOption = currentPaymentOption && currentPaymentOption?.id !== option.id;
  return (
    <div key={option.name} css={useStyles.paymentOption}>
      {blockOption ? <div css={useStyles.paymentBlock} /> : null}
      <span>
        {' '}
        <strong> {option.name} </strong>{' '}
      </span>
      <hr style={{ background: 'white', margin: '0.125rem auto' }} />
      <span>
        {' '}
        <strong>{description}</strong>{' '}
      </span>
      <>
        <span>Total: {parsedTotal}</span>
        <span>Parcela: {parsedPortionsValue}</span>
        <span>Entrada: {parsedRegistrationFee}</span>
      </>
      <ActionButton disabled={disabled} payment_option={currentPaymentOption} option={option} />
    </div>
  );
};

const PaymentOptionAttributesComponent = (props: {
  paymentOptionAttributes: PaymentOptionAttributes[];
  currentPaymentOption: PaymentOptionAttributes;
  paymentOptionRef: React.RefObject<HTMLSpanElement>;
  currentProduct: ProductOptions;
  loadingPaymentOptionAttributes: boolean;
  provisionMonths: number[];
}) => {
  const {
    paymentOptionAttributes,
    currentPaymentOption,
    paymentOptionRef,
    currentProduct,
    loadingPaymentOptionAttributes,
    provisionMonths,
  } = props;

  if (loadingPaymentOptionAttributes) {
    return <Loading />;
  }
  return (
    <>
      <span ref={paymentOptionRef} className='subtitle-one'>
        Escolha a opção de pagamento
      </span>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '32% 32% 32%',
          justifyContent: 'space-between',
          margin: '1rem 0',
          rowGap: '1rem',
        }}
      >
        {paymentOptionAttributes.map((option) => {
          const { active_ends_at, active_starts_at } = option
          const result = checkResourceActivity(active_starts_at, active_ends_at, currentProduct.periods['ends_at'])
          return (
            <PaymentOption
              disabled={!result}
              key={option.id}
              currentProduct={currentProduct}
              currentPaymentOption={currentPaymentOption}
              option={option}
              provisionMonths={provisionMonths}
            />
          );
        })}
      </div>
    </>
  );
};

const ProductListComponent = (props: {
  coursesOptions: DefaultOptionType[];
  classroomOptions: DefaultOptionType[];
  loadingDynamicOptions: string[];
  periodOptions: DefaultOptionType[];
  productOptions: ProductOptions[];
  productValue: string;
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
}) => {
  const {
    coursesOptions,
    classroomOptions,
    loadingDynamicOptions,
    periodOptions,
    productOptions,
    productValue,
    pagination,
    handlePageChange,
    handleChangePageSize,
  } = props;
  return (
    <>
      <InsertProductFilters
        coursesOptions={coursesOptions}
        classroomOptions={classroomOptions}
        periodOptions={periodOptions}
        loadingDynamicOptions={loadingDynamicOptions}
      />
      <span className='subtitle-one'>Lista de produtos</span>
      <InsertProductStepTable
        productValue={productValue}
        productOptions={productOptions}
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        loadingDynamicOptions={loadingDynamicOptions}
      />
    </>
  );
};

const RemainingFields = ({
  provisionMonths,
  currentProduct,
  paymentOptionAttributes,
  currentPaymentOption,
  loadingPaymentOptionAttributes,
  paymentOptionRef,
  financialRepresentativeOptions,
}: {
  provisionMonths: number[];
  currentProduct: ProductOptions;
  paymentOptionAttributes: PaymentOptionAttributes[];
  currentPaymentOption: PaymentOptionAttributes;
  loadingPaymentOptionAttributes: boolean;
  paymentOptionRef: React.RefObject<HTMLSpanElement>;
  financialRepresentativeOptions: DefaultOptionType[];
}) => {
  if (currentProduct && paymentOptionAttributes.length && provisionMonths.length) {
    return (
      <>
        <PaymentOptionAttributesComponent
          paymentOptionAttributes={paymentOptionAttributes}
          loadingPaymentOptionAttributes={loadingPaymentOptionAttributes}
          currentPaymentOption={currentPaymentOption}
          paymentOptionRef={paymentOptionRef}
          currentProduct={currentProduct}
          provisionMonths={provisionMonths}
        />
        <div
          style={{
            display: 'grid',
            gap: '1rem',
            gridTemplateColumns: '20% 20%',
          }}
        >
          <Field
            label='Responsável Financeiro *'
            placeholder='Selecione um responsável'
            name='financial_responsible'
            component={SelectMultipleComponent}
            options={financialRepresentativeOptions}
            validate={[validation.required]}
            limitSelection
          />
          <Field
            label='Data do início das aulas'
            placeholder='01/01/2022'
            name='starts_at'
            component={DatePickerComponent}
            validate={currentProduct.invoices_through_product ? [] : [validation.dateRequired]}
            disabled={currentProduct.invoices_through_product}
            datePickerProps={{
              allowSameDateSelection: true,
              disabled: currentProduct.invoices_through_product
            }}
          />
          <Field
            label='Email extra para notificação de faturas'
            name='notification_email'
            component={InputComponent}
            placeholder='Email extra para notificação de faturas'
            validate={[validation.validEmail]}
          />
        </div>
      </>
    );
  }
  return null;
};

const ControlButtons = ({
  onSubmit,
  handleSubmit,
  loggedInAccountId,
  isFormValid,
  handleTabChange,
}: {
  onSubmit: ({ data }: { data: ProductStepFormData }) => void;
  handleSubmit: (arg: (args: ProductStepFormData) => void) => any;
  loggedInAccountId: any;
  isFormValid: boolean;
  handleTabChange: (_: any, value: number) => void;
}) => {
  const cancelProductInsertion = React.useCallback(() => {
    handleTabChange(null, 0);
  }, []);
  const insertProduct = React.useCallback(
    handleSubmit((values) => {
      onSubmit({
        data: { ...values, conceded_by: loggedInAccountId },
      });
    }),
    [loggedInAccountId, handleSubmit, onSubmit],
  );
  return (
    <div css={useStyles.buttons}>
      <button onClick={cancelProductInsertion} className='red small'>
        {' '}
        Cancelar{' '}
      </button>
      <button className='green small' disabled={!isFormValid} onClick={insertProduct}>
        Adicionar
      </button>
    </div>
  );
};

const InsertProductStepForm = (props: {
  handleTabChange: (_: any, value: number) => void;
  representatives: RepresentativeAttributes[];
  onSubmit: ({ data }: { data: ProductStepFormData }) => void;
  handleSubmit: (arg: (args: ProductStepFormData) => void) => any;
  coursesOptions: DefaultOptionType[];
  classroomOptions: DefaultOptionType[];
  loadingDynamicOptions: string[];
  productOptions: ProductOptions[];
  currentProduct: ProductOptions;
  paymentOptionAttributes: PaymentOptionAttributes[];
  loggedInAccountId: any;
  currentPaymentOption: PaymentOptionAttributes;
  pagination: {
    pageCount: number;
    totalCount: number;
    pageSize: number;
    pageNumber: number;
  };
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  productValue: string;
  periodOptions: DefaultOptionType[];
  paymentOptionRef: React.RefObject<HTMLSpanElement>;
  loadingPaymentOptionAttributes: boolean;
  provisionMonths: number[];
  isFormValid: boolean;
}) => {
  const {
    handleTabChange,
    representatives,
    onSubmit,
    handleSubmit,
    coursesOptions,
    classroomOptions,
    loadingDynamicOptions,
    productOptions,
    currentProduct,
    paymentOptionAttributes,
    loggedInAccountId,
    paymentOptionRef,
    currentPaymentOption,
    pagination,
    handlePageChange,
    handleChangePageSize,
    productValue,
    periodOptions,
    loadingPaymentOptionAttributes,
    provisionMonths,
    isFormValid,
  } = props;
  const financialRepresentativeOptions = representatives?.map((item) => {
    return {
      value: item.id,
      label: item.representative_name,
    };
  });
  return (
    <form css={useStyles.form}>
      <ProductListComponent
        coursesOptions={coursesOptions}
        classroomOptions={classroomOptions}
        loadingDynamicOptions={loadingDynamicOptions}
        periodOptions={periodOptions}
        productOptions={productOptions}
        productValue={productValue}
        pagination={pagination}
        handlePageChange={handlePageChange}
        handleChangePageSize={handleChangePageSize}
      />
      <RemainingFields
        provisionMonths={provisionMonths}
        currentProduct={currentProduct}
        paymentOptionAttributes={paymentOptionAttributes}
        currentPaymentOption={currentPaymentOption}
        loadingPaymentOptionAttributes={loadingPaymentOptionAttributes}
        paymentOptionRef={paymentOptionRef}
        financialRepresentativeOptions={financialRepresentativeOptions}
      />
      <ControlButtons
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        loggedInAccountId={loggedInAccountId}
        handleTabChange={handleTabChange}
        isFormValid={currentPaymentOption && isFormValid}
      />
    </form>
  );
};

export default compose<any>(
  reduxForm({ form: 'registration-productstep', initialValues: { type: 'kit', from_year: new Date(2025, 0) } }),
)(InsertProductStepForm);
