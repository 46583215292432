import { css } from '@emotion/react';
import React from 'react'
import { auditActionOptions, AuditAttributes, colors, defaultPagination, PaginationType, RoomStudentAttributes, roomStudentRoomStatusOptions } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { FETCH_AUDITS } from '../../store/audits';
import { error } from 'react-notification-system-redux';
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { format, parseISO } from 'date-fns';
import TablePaginationActions from '../shared/TablePaginationActions';
import { find } from 'lodash';


const TableCss = css`
  margin-bottom: 15%;
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const RoomStudentLogTable = (props: { room_student_id: string }) => {
  const dispatch = useDispatch();
  const [audits, setAudits] = React.useState<AuditAttributes[]>([]);
  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);

  const fetchAudits = React.useCallback(
    async ({
    newPagination = pagination,
  }: {
    newPagination?: PaginationType;
  }) => {
    const { room_student_id } = props;
    try {
      const audits_response = await dispatch(
        FETCH_AUDITS.request({
          params: {
            filters: {
              'q[auditable_type_eq]': 'RoomStudent',
              'q[auditable_id_eq]': room_student_id,
              'page[number]': (newPagination.pageNumber + 1).toString(),
              'page[size]': newPagination.pageSize.toString(),
            },
          },
        }),
      );
      const {
        data: { data, meta },
      } = audits_response;
      const formatted_data = data.map((item) => {
        return {
          id: item.id,
          ...item.attributes,
        };
      });
      setPagination((current) => ({
        ...current,
        pageCount: meta.page_count,
        totalCount: meta.total_count,
      }));
      setAudits(formatted_data);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar logs',
        }),
      );
    }
  }, [pagination]);
  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchAudits({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchAudits({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  const init = async () => {
    await fetchAudits({});
  };

  React.useEffect(() => {
    init();
  }, []);
  const columns = [
    'Tipo',
    'Data de Alteração',
    'Data de Entrada',
    'Data de Saída',
    'Status',
    'Quem Alterou',
    ''
  ]
  return (
    <Table css={TableCss} size='small' aria-label='exam_audits'>
      <TableHead>
        <TableRow>
          {
            columns.map(item => <TableCell align='center' key={item}>{item}</TableCell>)
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {audits.map((audit) => {
          const actionLabel = auditActionOptions.find(item => item.value === audit.action)?.label || ''
          const revision = audit.revision as RoomStudentAttributes
          const room_status_label = find(roomStudentRoomStatusOptions, opt => opt.value === revision.room_status)
          return (
            <React.Fragment key={audit.id}>
              <TableRow>
                <TableCell align='center'>{actionLabel}</TableCell>
                <TableCell align='center'>{format(parseISO(audit.created_at), 'dd-MM-yyyy H:mm')}</TableCell>
                <TableCell align='center'>{format(parseISO(revision.starts_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='center'>{format(parseISO(revision.ends_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='center'>{room_status_label}</TableCell>
                <TableCell align='center'>{audit.user_name}</TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            colSpan={columns?.length + 1}
            count={pagination.totalCount}
            rowsPerPage={pagination.pageSize}
            page={pagination.pageNumber}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangePageSize}
            labelRowsPerPage='Itens Por página'
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
            }
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default RoomStudentLogTable