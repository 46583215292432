import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import SelectProfile from '../input/SelectProfileInput'
import { colors, ssoUrl } from '../../utils/constants'
import { nameToInitials, encodeQueryData } from '../../utils/functions'
import { SIGN_OUT } from '../../store/auth'
import { RootState } from '../../store/configureStore'
import { css } from '@emotion/react';

const useStyles = {
  header: css`
    background: white;
    height: 5rem;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;
  `,
  
  initials: css`
    background: lightblue;
    border-radius: 50%;
    display: flex;
    width: 3rem;
    height: 3rem;

    & > span {
      font-size: 1rem;
      margin: auto;
    }

    & > img {
      width: 100%;
      height: 100%;
      margin: auto;
      border-radius: 50%;
    }
  `,

  input: css`
    & .MuiOutlinedInput-root {
      border-radius: 8px;
      background: ${colors.lightBlue};

      & fieldset {
        border: 0;
      }
    }

    & label {
      color: ${colors.darkGrayBlue};
    }
  `,

  user: css`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;

    & > .info {
      display: grid;
      text-align: end;
      color: ${colors.darkBlue};
    }

    & > img {
      max-width: 3rem;
      border-radius: 50%;
      margin-left: 1rem;
    }
  `,

  selectProfile: css`
    display: flex;
    align-items: center;

    & > div {
      width: 15rem;
      margin-right: 1rem;
    }

    & > span {
      margin-bottom: 14px;
      margin-right: 1rem;
    }
  `,
};

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const {
    REACT_APP_SERVER_ENV = 'local',
    REACT_APP_SSO_ENV = REACT_APP_SERVER_ENV,
  } = process.env
  const data = {
    app_id: 44,
  }
  const urlString = encodeQueryData(data)
  const url = `${ssoUrl[REACT_APP_SSO_ENV]}?${urlString}`
  const {
    auth: { user, profile },
  } = useSelector((state: RootState) => state)
  const profileLabel = profile.label
  const dispatch = useDispatch()
  const handleClick = (event: any) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div css={useStyles.header}>
      <div css={useStyles.selectProfile}>
        <SelectProfile />
      </div>
      <div css={useStyles.user}>
        <div className='info'>
          <span>
            <strong>{user.name}</strong>
          </span>
          <span>{profileLabel}</span>
        </div>
        <div css={useStyles.initials}>
          {user.avatar_url ? (
            <img alt={user.name} src={user.avatar_url} />
          ) : (
            <span> {nameToInitials(user.name)} </span>
          )}
        </div>
        <ArrowDropDownIcon onClick={handleClick} />
        <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem>
            <a style={{ textDecoration: 'none', color: 'inherit' }} href={url} rel='noopener noreferrer' target='_blank'>
              Trocar senha
              </a>
            </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(SIGN_OUT.request())
              handleClose()
            }}
          >
            Sair
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default Header
