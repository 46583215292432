/* eslint-disable camelcase */
import React from 'react';
import { Theme } from '@mui/system';


import {
    CurrentRegistrationData, InvoiceStatusTypes, defaultPagination, InvoiceStatusEnum, InvoiceAttributes, InvoiceChargeStatusTypes, InvoiceChargeStatusEnum, PaginationType, OrderOptions,
    InvoiceItemAttributes,
    RepresentativeAttributes,
} from '../../../../utils/constants';
import InvoiceTable from '../../../table/InvoiceTable';
import { Tab, Tabs} from '@mui/material';
import TabPanel from '../../../shared/TabPanel';
import ContractInvoicesTable from '../../../table/ContractInvoicesTable';
import InvoiceFormContainer from '../../../../containers/InvoiceFormContainer';
import RenegotiationFormContainer from '../../../../containers/RenegotiationFormContainer';
import { differenceInDays } from 'date-fns'
import InvoicesToolbar from '../../../shared/InvoicesToolbar';
import { filter, orderBy as lodashOrderBy, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { FETCH_REGISTRATION } from '../../../../store/registrations';
import { InvoiceJson } from '../../../../store/invoices';
import { InvoiceItemJson } from '../../../../store/invoice_items';
import { RepresentativeJson } from '../../../../store/representatives';
import { error } from 'react-notification-system-redux';
import { UI_SET_LOADING_OPEN } from '../../../../store/ui';

import { css, useTheme } from '@emotion/react';

export const useStyles = () => {
  const theme = useTheme() as Theme;
  return {
    aboveContent: css`
      display: flex;
      gap: 1rem;
      justify-content: end;
      margin-bottom: 3.5rem;
    `,
    wrapper: css`
      flex: 1;
    `,
    view: css`
      padding: 2rem;
      position: relative;
      height: 100%;
      flex: 1;

      ${theme.breakpoints?.down('md')} {
        padding: 2rem 0;
      }
    `,
    buttons: css`
      padding: 4rem;
      display: flex;
      justify-content: end;
      gap: 1rem;
    `,
    tabStyle: css`
      & .Mui-selected {
        background: #fdfefe;
        border-radius: 1rem 1rem 0 0;
      }
    `,
  };
};

const default_pagination = {...defaultPagination, pageSize: 25}
const default_filters = {
  status_filter: [InvoiceStatusEnum.TO_PAYMENT_SERVICE],
  representative_filter: '',
  charge_status_filter: [],
  from_expiration_date_filter_value: null,
  to_expiration_date_filter_value: null,
  order_by: 'expiration_date' as string,
  order: 'asc' as OrderOptions
}

const InvoiceStep = ({
    currentRegistration,
    setStep,
}: {
    currentRegistration: CurrentRegistrationData;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [tab, setTab] = React.useState('all_invoices')
    const [invoice_items, setInvoiceItems] = React.useState<InvoiceItemAttributes[]>([])
    const [invoices, setInvoices] = React.useState<InvoiceAttributes[]>([])
    const [representatives, setRepresentatives] = React.useState<RepresentativeAttributes[]>([])
    const [selectedInvoiceItems, setSelectedInvoiceItems] = React.useState<number[]>([])
    const [selectedInvoices, setSelectedInvoices] = React.useState<number[]>([])
    const [statusFilter, setStatusFilter] = React.useState<InvoiceStatusTypes[]>(default_filters.status_filter)
    const [representativeFilter, setRepresentativeFilter] = React.useState<string>(default_filters.representative_filter)
    const [chargeStatusFilter, setChargeStatusFilter] = React.useState<InvoiceChargeStatusTypes[]>(default_filters.charge_status_filter)
    const [filteredInvoices, setFilteredInvoices] = React.useState(invoices)
    const [currentInvoice, setCurrentInvoice] = React.useState<InvoiceAttributes|null>(null)
    const [fromExpirationDateFilterValue, setFromExpirationDateFilterValue] = React.useState<Date | null>(default_filters.from_expiration_date_filter_value)
    const [toExpirationDateFilterValue, setToExpirationDateFilterValue] = React.useState<Date | null>(default_filters.to_expiration_date_filter_value)
    const [pagination, setPagination] = React.useState<PaginationType>(default_pagination);
    const [orderBy, setOrderBy] = React.useState(default_filters.order_by);
    const [order, setOrder] = React.useState<OrderOptions>(default_filters.order); 
    
    const setLoading = React.useCallback((value: boolean) => {
      dispatch(UI_SET_LOADING_OPEN(value))
    }, [])

    const handleTabChange = (_: any, newValue: string) => {
      setTab(newValue);
    };
    const changeInvoiceStepTab = (invoice?: InvoiceAttributes, destiny = 'invoice_form') => {
      if(invoice){
        setCurrentInvoice(invoice)
      }
      handleTabChange(null, destiny)
    }
    const handleFiltering = React.useCallback((this_props: {
      status_filter_value?: InvoiceStatusEnum[]
      representative_filter_value?: string
      charge_status_filter_value?: InvoiceChargeStatusEnum[]
      from_expiration_date_filter_value?: Date | null
      to_expiration_date_filter_value?: Date | null
      order_filter?: OrderOptions
      order_by_filter?: string
      page_number?: number
      page_size?: number
      invoicesData?: InvoiceAttributes[]
    }) => {
      const {
        status_filter_value = statusFilter,
        representative_filter_value = representativeFilter,
        charge_status_filter_value = chargeStatusFilter,
        from_expiration_date_filter_value = fromExpirationDateFilterValue,
        to_expiration_date_filter_value = toExpirationDateFilterValue,
        order_filter = order,
        order_by_filter = orderBy,
        page_number = pagination.pageNumber,
        page_size = pagination.pageSize,
        invoicesData = invoices
      } = this_props
      let filtered_invoices = invoicesData
      if(representative_filter_value){
        filtered_invoices = filtered_invoices.filter(invoice => {
          const check = ~~representative_filter_value === ~~invoice.representative_id
          return check
        })
      }
      
      if(status_filter_value.length) {
        filtered_invoices = filtered_invoices.filter(invoice => {
          const status_check = status_filter_value.includes(invoice.status)
          return status_check
        })
      }
      
      if(charge_status_filter_value.length){
        filtered_invoices = filtered_invoices.filter(invoice => {
          const status_check = charge_status_filter_value.includes(invoice.charge_status)
          return status_check
        })
      }
      
      if(from_expiration_date_filter_value){
        filtered_invoices = filtered_invoices.filter(invoice => {
          if(invoice.expiration_date) {
            const parsed_expiration_date = new Date(invoice.expiration_date)
            return differenceInDays(parsed_expiration_date, from_expiration_date_filter_value) >= 0
          }
        })
      }
      
      if(to_expiration_date_filter_value){
        filtered_invoices = filtered_invoices.filter(invoice => {
          if(invoice.expiration_date) {
            const parsed_expiration_date = new Date(invoice.expiration_date)
            return differenceInDays(parsed_expiration_date, to_expiration_date_filter_value) <= 0
          }
        })
      }
      
      if(order_filter && order_by_filter){
        if (order_by_filter === 'expiration_date') {
          filtered_invoices = lodashOrderBy(filtered_invoices, (invoice) => new Date(invoice.expiration_date), order_filter);
        } else {
          filtered_invoices = lodashOrderBy(filtered_invoices, [order_by_filter], [order_filter]);
        }
      }
      const count = filtered_invoices.length
      
      filtered_invoices = filtered_invoices.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / page_size);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk'
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, [] as InvoiceAttributes[][])[page_number] || [];
      setSelectedInvoices([])
      setPagination({...pagination, pageNumber: page_number, pageSize: page_size, totalCount: count})
      setFilteredInvoices(filtered_invoices)
    }, [
      statusFilter,
      invoices,
      filteredInvoices,
      fromExpirationDateFilterValue,
      toExpirationDateFilterValue,
      chargeStatusFilter,
      representativeFilter,
      order,
      orderBy,
      pagination
    ]
  )
    const fetchData = React.useCallback(async () => {
      setLoading(true)
      try {
        const response = await dispatch(
          FETCH_REGISTRATION.request({
            id: currentRegistration.id,
            params: {
              filters: {
                'include': ['contracts.billings.invoice_items', 'invoices.representative'].join(',')
              },
            },
          }),
        );
        const { data: { included } } = response
        const includedInvoices = map(filter(included, incl => incl.type === 'invoices') as InvoiceJson[], item => ({
          id: item.id,
          ...item.attributes
        }))
        const includedInvoiceItems = map(filter(included, incl => incl.type === 'invoice_items') as InvoiceItemJson[], item => ({
          id: item.id,
          ...item.attributes
        }))
    
        const includedRepresentatives = map(filter(included, incl => incl.type === 'representatives') as RepresentativeJson[], item => ({
          id: item.id,
          ...item.attributes
        }))
        setInvoices(includedInvoices)
        setInvoiceItems(includedInvoiceItems)
        setRepresentatives(includedRepresentatives)
        handleFiltering({invoicesData: includedInvoices})
        setLoading(false)
      } catch (err) {
        setLoading(false)
        dispatch(
          error({
            message: 'Falha ao carregar faturas da matrícula'
          })
        )
      }
  
    }, [])

    const resetFilters = React.useCallback(() => {
      setRepresentativeFilter(default_filters.representative_filter)
      setOrder(default_filters.order)
      setOrderBy(default_filters.order_by)
      setStatusFilter(default_filters.status_filter)
      setChargeStatusFilter(default_filters.charge_status_filter)
      setFromExpirationDateFilterValue(null)
      setToExpirationDateFilterValue(null)
    }, [])

    const handleFetchInvoices = React.useCallback(async () => {
      await fetchData()
    }, [])

    
    
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof InvoiceAttributes) => {
    const isAsc = orderBy === property && order === 'asc';
    const new_order = isAsc ? 'desc' : 'asc';
    setOrder(new_order);
    setOrderBy(property);
    handleFiltering({
      order_filter: new_order,
      order_by_filter: property
    })
  };
    const handlePageChange = (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      handleFiltering({
        page_number: newPage
      })
    };
    const handleChangePageSize = (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      handleFiltering({
        page_size: e.target.value
      })
    };

 

    React.useEffect(() => {
      setSelectedInvoices([])
    }, [currentRegistration])

    React.useEffect(() => {
      fetchData()
    }, [])
    const hideElementsStyle = { display: ['invoice_form', 'renegotiation', 'installment_renegotiation'].includes(tab) ? 'none' : 'initial' }
    return (
      <div css={classes.wrapper}>
        <div css={classes.view}>
          <Tabs css={classes.tabStyle} style={hideElementsStyle} value={tab} onChange={handleTabChange}>
            <Tab label='Todas as faturas' value={'all_invoices'} />
            <Tab label='Faturas por contrato' value={'invoices_by_contract'} />
            <Tab label='Ajuste de fatura' value={'invoice_form'} style={{display: 'none'}} />
            <Tab label='Renegociação' value={'renegotiation'} style={{display: 'none'}} />
          </Tabs>
          <TabPanel style={{ background: 'white' }} value={tab} index={'all_invoices'}>
            <InvoicesToolbar
              changeInvoiceStepTab={changeInvoiceStepTab}
              chargeStatusFilter={chargeStatusFilter}
              fromExpirationDateFilterValue={fromExpirationDateFilterValue}
              handleFetchInvoices={handleFetchInvoices}
              handleFiltering={handleFiltering}
              invoices={invoices}
              representativeFilter={representativeFilter}
              representatives={representatives}
              setChargeStatusFilter={setChargeStatusFilter}
              setFromExpirationDateFilterValue={setFromExpirationDateFilterValue}
              setRepresentativeFilter={setRepresentativeFilter}
              setStatusFilter={setStatusFilter}
              setToExpirationDateFilterValue={setToExpirationDateFilterValue}
              statusFilter={statusFilter}
              toExpirationDateFilterValue={toExpirationDateFilterValue}
              selectedInvoices={selectedInvoices}
              setSelectedInvoices={setSelectedInvoices}
            />
            <InvoiceTable
              changeInvoiceStepTab={changeInvoiceStepTab}
              handleChangePageSize={handleChangePageSize}
              handlePageChange={handlePageChange}
              handleRequestSort={handleRequestSort}
              handleUpdateResources={handleFetchInvoices}
              invoice_items={invoice_items}
              invoices={filteredInvoices}
              invoicesDisposition={filteredInvoices}
              order={order}
              orderBy={orderBy}
              pagination={pagination}
              selectedInvoices={selectedInvoices}
              setSelectedInvoices={setSelectedInvoices}
              resetFilters={resetFilters}
            />
          </TabPanel>
          <TabPanel style={{ background: 'white' }} value={tab} index={'invoices_by_contract'}>
            <ContractInvoicesTable
              changeInvoiceStepTab={changeInvoiceStepTab}
              selectedInvoiceItems={selectedInvoiceItems}
              setSelectedInvoiceItems={setSelectedInvoiceItems}
              registration_product_id={currentRegistration.id}
            />
          </TabPanel>
          <TabPanel style={{ backgorund: 'white' }} value={tab} index={'invoice_form'}>
            <InvoiceFormContainer
              handleFetchInvoices={handleFetchInvoices}
              handleTabChange={handleTabChange}
              invoice={currentInvoice as InvoiceAttributes}
              load_resources
            />
          </TabPanel>
          <TabPanel style={{ background: 'white' }} value={tab} index={'renegotiation'}>
            <RenegotiationFormContainer
              resetFilters={resetFilters}
              handleFetchInvoices={handleFetchInvoices}
              handleTabChange={handleTabChange}
              selected_invoice_ids={selectedInvoices}
              setSelectedInvoices={setSelectedInvoices}
            />
          </TabPanel>
					<div css={classes.buttons}>
            <button
              style={hideElementsStyle}
              className='red small'
              onClick={() => {
                  setStep((step) => step - 1);
              }}
            >
              Voltar
            </button>
					</div>
        </div>
      </div>
    );
};

export default InvoiceStep;