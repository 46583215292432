import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';

import {
  colors,
  defaultPagination,
  KtwelveCurriculumRegistrationAttributes,
  PaginationType,
  ProductTypesEnum,
  RegistrationAttributes,
  RoomAttributes,
} from '../../utils/constants';
import { useDispatch } from 'react-redux';
import Loading from '../loading/Loading';
import { error, success } from 'react-notification-system-redux';
import { FETCH_STUDENTS_MISSING_REGISTRATIONS } from '../../store/registrations';
import InputComponent from '../input/form/input';
import { filter, find, get, head, isEmpty } from 'lodash';
import { CREATE_KTWELVE_CURRICULUM_REGISTRATION, KtwelveCurriculumRegistrationJson } from '../../store/ktwelve_curriculum_registrations';
import { RegistrationProductJson } from '../../store/registration_products';
import { ProductJson } from '../../store/products';
import { ProductItemJson } from '../../store/product_items';
import { parse } from 'date-fns';
import { Checkbox } from '@mui/material';
import { CREATE_ROOM_STUDENT, FETCH_ROOM_STUDENTS, UPDATE_ROOM_STUDENT } from '../../store/room_students';
import { CheckCircle, HourglassEmpty } from '@mui/icons-material';
import { FiAlertTriangle } from 'react-icons/fi';
import { css, useTheme } from '@emotion/react'
import LoadingIndicator from '../shared/LoadingWithLabel';
const columns = ['Nome', 'Descrição', 'Vagas'];

const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    table: css`
      background: #fdfefe;
      padding: 1rem;
      border-radius: 1rem;

      & .delete {
        color: ${colors.lightRed};
        cursor: pointer;

        &:hover {
          color: #cc8080;
        }
      }

      & th {
        color: ${colors.darkGrayBlue};
        border-bottom: none;
      }

      & .MuiPaper-root {
        flex-grow: 1;
      }

      & span.empty {
        color: ${colors.lightRed};
      }

      & span.empty:empty:before {
        content: '\\200b';
      }

      & tbody {
        position: relative;
      }

      & td {
        padding: 2px;
        color: ${colors.darkBlue};
        font-size: 1rem;
        border-bottom: 1px solid ${colors.grayBlue};

        ${theme.breakpoints?.down('lg')} {
          font-size: 0.75rem;
        }
      }

      & tfoot {
        & > tr {
          border: 1px solid ${colors.grayBlue};
          border-radius: 0px 0px 8px 8px;
          background: white;

          & > td {
            padding: 0.5rem 0;
            font-weight: bold;
          }
        }
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & td {
          color: ${colors.darkBlue};

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};

const ButtonsCss = css`
  display: flex;
  justify-content: end;
  gap: 2rem;
  margin: 2rem 0;
`
interface CustomRegistrationOptions extends RegistrationAttributes {
  ktwelve_curriculum_registration?: KtwelveCurriculumRegistrationAttributes;
  starts_at: string;
}

const InsertRoomStudentTable = ({
  room,
  close_form,
  onSave
}: {
  room: RoomAttributes;
  close_form: () => void;
  onSave: () => void
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const product_id = room.product_id;
  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [registrationOptions, setRegistrationOptions] = React.useState([] as CustomRegistrationOptions[]);
  const [nameFilterValue, setNameFilterValue] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [selected_students, setSelectedStudents] = React.useState<string[]>([])
  const [processingStudents, setProcessingStudents] = React.useState<{ id: string; status: 'awaiting'|'running'|'successful'|'error' }[]>([]);
  const fetchStudentsMissingRegistration = React.useCallback(
    async (props: { newPagination?: PaginationType }) => {
      const { newPagination = pagination } = props;
      try {
        setLoading(true);
        const registrations = await dispatch(
          FETCH_STUDENTS_MISSING_REGISTRATIONS.request({
            product_id: product_id.toString(),
            params: {
              filters: {
                ...(room.multiple_rooms ? {room_id: room.id} : {}),
                'q[company_id_eq]': room.company_id.toString(),
                'q[student_user_name_start]': nameFilterValue,
                'q[s]': `student_user.name asc`,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
                include: ['ktwelve_curriculum_registrations', 'registration_products.product.product_items'].join(','),
              },
            },
          }),
        );
        if (registrations) {
          const {
            data: { data, included, meta },
          } = registrations;
          const formattedData = data.map(({ attributes, id }) => {
            const ktwelve_curriculum_registration = find(
              included,
              (incl) => incl.type === 'ktwelve_curriculum_registrations' && incl.attributes.registration_id === ~~id && incl.attributes.ktwelve_curriculum_id === room.ktwelve_curriculum_id,
            ) as KtwelveCurriculumRegistrationJson;
            const registration_product = find(
              filter(
                included,
                (incl) => incl.type === 'registration_products' && incl.attributes.active,
              ) as RegistrationProductJson[],
              (incl) => {
                const product = find(
                  included,
                  (x) =>
                    x.type === 'products' &&
                    ~~x.id === incl.attributes.product_id &&
                    incl.attributes.kind === ProductTypesEnum.KIT,
                ) as ProductJson;
                const product_item = find(
                  included,
                  (x) =>
                    x.type === 'product_items' &&
                    x.attributes.product_id === ~~get(product, 'id') &&
                    x.attributes.item_id === room.product_id,
                ) as ProductItemJson;
                return !isEmpty(product) && !isEmpty(product_item);
              },
            );
            return {
              id,
              ...attributes,
              ...(ktwelve_curriculum_registration
                ? {
                    ktwelve_curriculum_registration: {
                      id: ktwelve_curriculum_registration.id,
                      ...ktwelve_curriculum_registration.attributes,
                    },
                  }
                : {}),
              ...(registration_product ? { starts_at: registration_product.attributes.starts_at } : {}),
            };
          });
          setRegistrationOptions(formattedData);
          setPagination((current) => ({
            ...current,
            pageCount: meta.page_count,
            totalCount: meta.total_count,
          }));
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar opções de alunos',
          }),
        );
      }
    },
    [room, pagination, nameFilterValue],
  );

  const init = () => {
    fetchStudentsMissingRegistration({});
  };

  const processStudent = React.useCallback(async (registration_id: string) => {
    try {
      await setProcessingStudents(current => current.map((item) => {
        if(item.id === registration_id){
          return ({...item, status: 'running' })
        }
        return item
      }));
      const found_registration = find(
        registrationOptions,
        (opt) => opt.id === registration_id,
      ) as CustomRegistrationOptions;
      let current_room_student = null
      const ktwelve_curriculum_registration = found_registration?.ktwelve_curriculum_registration;
      const starts_at = parse(found_registration.starts_at, 'yyyy-MM-dd', new Date()).toISOString();
      const ends_at = room.last_day
      let ktwelve_curriculum_registration_id = ktwelve_curriculum_registration &&
      ktwelve_curriculum_registration.ktwelve_curriculum_id === room.ktwelve_curriculum_id ? ktwelve_curriculum_registration.id : ''
      if(isEmpty(ktwelve_curriculum_registration_id)) {
        const response = await dispatch(
          CREATE_KTWELVE_CURRICULUM_REGISTRATION.request({
            data: {
              registration_id,
              ktwelve_curriculum_id: room.ktwelve_curriculum_id
            },
          }),
        )
        const { data: { data: response_data } } = response
        ktwelve_curriculum_registration_id = response_data.id
      } else {
        const room_students_response = await dispatch(
          FETCH_ROOM_STUDENTS.request({
            params: {
              filters: {
                'q[room_id_eq]': room.id,
                'q[ktwelve_curriculum_registration_id_eq]': ktwelve_curriculum_registration_id
              }
            }
          })
        )
        const { data: { data } } = room_students_response
        current_room_student = head(filter(data, rs => rs.attributes.ktwelve_curriculum_registration_id.toString() === ktwelve_curriculum_registration_id.toString() && rs.attributes.room_id.toString() === room.id.toString()))  
      }
      
      const room_student_attributes = {
        starts_at,
        ends_at,
        room_id: ~~room.id,
        product_id: room.product_id,
        ktwelve_curriculum_registration_id: ~~ktwelve_curriculum_registration_id
      }
      if(current_room_student && !isEmpty(current_room_student)){
        await dispatch(
          UPDATE_ROOM_STUDENT.request({
            id: current_room_student?.id,
            data: {
              ...room_student_attributes,
              active: true,
              audit_comment: "Aluno reensalado"
            }
          })
        )
      } else {
        await dispatch(
          CREATE_ROOM_STUDENT.request({
            data: {
              ...room_student_attributes
            }
          })
        )
      }
      setProcessingStudents(current => current.map((item) => {
        if (item.id === registration_id) {
          return { ...item, status: 'successful' };
        }
        return item;
      }))
    } catch (err) {
      setProcessingStudents(current => current.map((item) => {
        if (item.id === registration_id) {
          return { ...item, status: 'error' };
        }
        return item;
      }))
    }
  }, [registrationOptions, selected_students, processingStudents, setProcessingStudents])
  const onStartProcessingStudents = React.useCallback(
    async () => {
      try {
          await setProcessingStudents(selected_students.map((item) => ({ id: item.toString(), status: 'awaiting' })));
          for (const registration_id of selected_students) {
            await processStudent(registration_id);
          }

        dispatch(
          success({
            message: 'Ensalamento concluído',
          }),
        );
        close_form()
        onSave()
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao ensalar alunos',
          }),
        );
      }
    },
    [room, processingStudents, selected_students],
  );

  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchStudentsMissingRegistration({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchStudentsMissingRegistration({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );
  const handleSelectRegistration = (props: {
    event: React.ChangeEvent<HTMLInputElement>;
    registration_id: string;
  }) => {
    const { event, registration_id } = props;
    const { target: { checked } } = event
    if (checked) {
      setSelectedStudents(current => current.concat(registration_id))
    } else {
      setSelectedStudents(current => current.filter(item => item !== registration_id))
    }
  };
  const selectAllStudents = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if(event?.target.checked){
      setSelectedStudents(registrationOptions.map(item => item.id))
    } else {
      setSelectedStudents([])
    }
  }, [registrationOptions])
  React.useEffect(() => {
    init();
  }, []);

  if (loading) {
    return <Loading />;
  }



  return (
    <div style={{ display: 'grid' }}>
      <span style={{ justifySelf: 'self-start' }} className='subtitle-one'>
        {' '}
        Selecionar aluno{' '}
      </span>
      <InputComponent
        placeholder={`Buscar por nome do aluno`}
        search
        small
        onSearch={() => {
          fetchStudentsMissingRegistration({});
        }}
        input={{
          value: nameFilterValue,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setNameFilterValue(e.target.value);
          },
        }}
      />
      <div css={classes.table}>
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='left'>
                  <Checkbox
                    onChange={selectAllStudents}
                    checked={registrationOptions.length > 0 && selected_students.length > 0 && registrationOptions.every(item => selected_students.includes(item.id))}
                    indeterminate={registrationOptions.length > 0 && selected_students.length > 0 && registrationOptions.some(item => !selected_students.includes(item.id))}
                    disabled={!isEmpty(processingStudents) || registrationOptions.length === 0}
                  />
                </TableCell>
                <TableCell align='center'>Nome</TableCell>
                <TableCell align='center'>RA</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {registrationOptions.map((option) => {
                const processing_student = processingStudents.find(item => item.id === option.id)
                return (
                  <TableRow key={option.code}>
                    <TableCell align='left'>
                      <Checkbox
                        checked={selected_students.includes(option.id)}
                        disabled={!isEmpty(processingStudents)}
                        onChange={(event) =>
                          handleSelectRegistration({ event, registration_id: option.id })}
                      />
                    </TableCell>
                    <TableCell align='center'>{option.user_data.name}</TableCell>
                    <TableCell align='center'>{option.code}</TableCell>
                    <TableCell>

                      {
                        processing_student && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {
                              processing_student.status === 'awaiting' ? (
                                <>
                                  <HourglassEmpty />
                                  <span>Aguardando</span>
                                </>
                              ) : processing_student.status === 'running' ? (
                                <>
                                  <LoadingIndicator message=''/>
                                  <span>Ensalando...</span>
                                </>
                              ) : processing_student.status === 'successful' ? (
                                <>
                                  <CheckCircle style={{color: colors.green}} />
                                  <span>Ensalado</span>
                                </>
                              ) : processing_student.status === 'error' ? (
                                <>
                                  <FiAlertTriangle style={{ color: colors.lightRed }} />
                                  <span>Erro</span>
                                </>
                              ) : <></>
                            }
                          </div>
                        )
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20, 30, 50]}
                  colSpan={columns?.length + 2}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : ` 'mais de' ${to}`}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <div css={ButtonsCss}>
        <button onClick={onStartProcessingStudents} disabled={!isEmpty(processingStudents) || isEmpty(selected_students)} className='green small'>
          Iniciar Ensalamento
        </button>
        <button disabled={!isEmpty(processingStudents)} className='red small' onClick={close_form}>
          Fechar
        </button>
      </div>
    </div>
  );
};

export default InsertRoomStudentTable;
