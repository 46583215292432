import React from 'react'
import { Spinner } from './styles/Loading.style'

const Loading = (props: {loading_message?: string}) => {
  const { loading_message = 'Carregando' } = props
  return (
    <div style={{ display: 'flex', height: 'fit-content', background: 'inherit', minHeight: '10rem', flexDirection: 'column', alignItems: 'center', fontSize: '18px', width: '100%' }}>
      <Spinner />
      <span>{loading_message}</span>
    </div>
  )
}

export default Loading
