/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  colors,
  CompositionPeriodAttributes,
  KtwelveCurriculumAttributes,
  KtwelveSubjectAttributes,
  ktwelveSubjectEvaluationKindOptions,
  ktwelveSubjectKindOptions,
} from '../../utils/constants';
import { PiChalkboardTeacher } from 'react-icons/pi';
import TooltipButton from '../shared/TooltipButton';
import { Article } from '@mui/icons-material';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { FETCH_KTWELVE_CURRICULUM } from '../../store/ktwelve_curriculums';
import { KtwelveSubjectJson } from '../../store/ktwelve_subjects';
import { error } from 'react-notification-system-redux';
import { map } from 'lodash';
import Loading from '../loading/Loading';
import { Collapse } from '@mui/material';
import KtwelveSubjectsFormContainer from '../../containers/KtwelveSubjectsFormContainer';
import SubjectPeriodsTable from './SubjectPeriodsTable';
import PenIcon from '../icon/PenIcon';
import TeacherSubjectsTable from './TeacherSubjectsTable';
import { CompositionPeriodJson } from '../../store/composition_periods';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const KtwelveSubjectsTable = ({ ktwelve_curriculum }: { ktwelve_curriculum: KtwelveCurriculumAttributes }) => {
  const [expandedPeriods, setExpandedPeriods] = React.useState<number[]>([]);
  const [expandedTeachers, setExpandedTeachers] = React.useState<number[]>([]);
  const [ktwelve_subjects, setKtwelveSubjects] = React.useState<KtwelveSubjectAttributes[]>([]);
  const [composition_periods, setCompositionPeriods] = React.useState<CompositionPeriodAttributes[]>([])
  const [editKtwelveSubject, setEditingKtwelveSubject] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const dispatch = useDispatch();

  const fetchKtwelveSubject = React.useCallback(async () => {
    try {
      const response = await dispatch(
        FETCH_KTWELVE_CURRICULUM.request({
          id: ktwelve_curriculum.id,
          params: {
            filters: {
              include: ['ktwelve_subjects', 'composition.composition_periods'].join(','),
            },
          },
        }),
      );
      const {
        data: { included = [] },
      } = response;
      const ktwelve_subjects_attributes = map(
        included.filter((incl) => incl.type === 'ktwelve_subjects') as KtwelveSubjectJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      const composition_periods = map(
        included.filter((incl) => incl.type === 'composition_periods') as CompositionPeriodJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      setKtwelveSubjects(ktwelve_subjects_attributes);
      setCompositionPeriods(composition_periods)
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar periodos',
        }),
      );
    }
  }, [ktwelve_curriculum]);
  const init = async () => {
    setLoading(true);
    await fetchKtwelveSubject();
    setLoading(false);
  };
  React.useEffect(() => {
    init();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Table css={TableCss} size='small' aria-label='ktwelve_subjects'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Disciplina</TableCell>
          <TableCell align='center'>Tipo</TableCell>
          <TableCell align='center'>Tipo de avaliação</TableCell>
          <TableCell align='center'>Disciplina Mãe</TableCell>
          <TableCell align='right'>
            {' '}
            <button disabled={editKtwelveSubject.length > 0} onClick={() => setCreate(!create)} className='green small'>
              <span> Adicionar Disciplina </span>
            </button>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} align='right'>
            <Collapse in={create} timeout='auto' unmountOnExit>
              <KtwelveSubjectsFormContainer
                onSave={async () => {
                  await init();
                  setCreate(false);
                }}
                close_form={() => setCreate(false)}
                ktwelve_curriculum={ktwelve_curriculum}
                ktwelve_subjects_options={ktwelve_subjects.map((item) => ({
                  value: item.id,
                  label: item.subject_name,
                }))}
              />
            </Collapse>
          </TableCell>
        </TableRow>
        {ktwelve_subjects.map((ktwelve_subject) => {
          const kindLabel = ktwelveSubjectKindOptions.find((item) => item.value === ktwelve_subject.kind)?.label;
          const evaluationLabel = ktwelveSubjectEvaluationKindOptions.find(
            (item) => item.value === ktwelve_subject.evaluation_kind,
          )?.label;
          const close_editing_form = () => {
            setEditingKtwelveSubject((current) => current.filter((item) => item !== ~~ktwelve_subject.id));
          };
          return (
            <React.Fragment key={ktwelve_subject.id}>
              <TableRow>
                <TableCell align='center'>{ktwelve_subject.subject_name}</TableCell>
                <TableCell align='center'>{kindLabel}</TableCell>
                <TableCell align='center'>{evaluationLabel}</TableCell>
                <TableCell align='center'>{ktwelve_subject.parent_name}</TableCell>
                <TableCell align='right'>
                  <TooltipButton
                    tooltipProps={{
                      title: expandedPeriods.includes(~~ktwelve_subject.id) ? 'Ocultar períodos' : 'Ver períodos',
                    }}
                    Icon={Article}
                    iconButtonProps={{
                      onClick: () =>
                        setExpandedPeriods((current) => {
                          if (current.includes(~~ktwelve_subject.id)) {
                            return current.filter((item) => item !== ~~ktwelve_subject.id);
                          } else {
                            return current.concat(~~ktwelve_subject.id);
                          }
                        }),
                    }}
                  />{' '}
                  <TooltipButton
                    tooltipProps={{
                      title: expandedTeachers.includes(~~ktwelve_subject.id)
                        ? 'Ocultar professores'
                        : 'Ver professores da disciplina',
                    }}
                    Icon={PiChalkboardTeacher}
                    iconButtonProps={{
                      onClick: () =>
                        setExpandedTeachers((current) => {
                          if (current.includes(~~ktwelve_subject.id)) {
                            return current.filter((item) => item !== ~~ktwelve_subject.id);
                          } else {
                            return current.concat(~~ktwelve_subject.id);
                          }
                        }),
                    }}
                  />{' '}
                  <TooltipButton
                    tooltipProps={{
                      title: editKtwelveSubject.includes(~~ktwelve_subject.id)
                        ? 'Cancelar edição'
                        : 'Editar disciplina',
                    }}
                    Icon={PenIcon}
                    iconButtonProps={{
                      disabled:
                        create || (editKtwelveSubject.length > 0 && !editKtwelveSubject.includes(~~ktwelve_subject.id)),
                      onClick: () =>
                        setEditingKtwelveSubject((current) => {
                          if (current.includes(~~ktwelve_subject.id)) {
                            return current.filter((item) => item !== ~~ktwelve_subject.id);
                          } else {
                            return current.concat(~~ktwelve_subject.id);
                          }
                        }),
                    }}
                  />{' '}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                  <Collapse in={editKtwelveSubject.includes(~~ktwelve_subject.id)} timeout='auto' unmountOnExit>
                    <KtwelveSubjectsFormContainer
                      onSave={async () => {
                        close_editing_form();
                        await init();
                      }}
                      close_form={close_editing_form}
                      ktwelve_subject_id={ktwelve_subject.id}
                      ktwelve_curriculum={ktwelve_curriculum}
                      ktwelve_subjects_options={ktwelve_subjects.map((item) => ({
                        value: item.id,
                        label: item.subject_name,
                      }))}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                  <Collapse in={expandedPeriods.includes(~~ktwelve_subject.id)} timeout='auto' unmountOnExit>
                    <SubjectPeriodsTable ktwelve_subject_ids={map(ktwelve_subjects, ks => ks.id)} ktwelve_subject={ktwelve_subject} composition_periods={composition_periods} />
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                  <Collapse in={expandedTeachers.includes(~~ktwelve_subject.id)} timeout='auto' unmountOnExit>
                    <TeacherSubjectsTable ktwelve_subject={ktwelve_subject} />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default KtwelveSubjectsTable;
