/* eslint-disable camelcase */
import { compose } from 'redux';
import {
  change,
  Field,
  FieldArray,
  FormSection,
  formValueSelector,
  reduxForm,
  WrappedFieldArrayProps,
  arrayPush,
  GenericField,
} from 'redux-form';
import {
  colors,
  NestedOperationAttributes,
  OperationAttributes,
  operationKindOptions,
  operationOperableOptions,
  OperationOperableType,
  validation,
  CompositionPeriodKindEnum,
  DefaultOptionType,
  FormulableType,
  OperationKindEnum,
  ExamPlaceableType,
  ExamPlacementAttributes,
  FormulaAttributes,
  CompositionPeriodAttributes,
  FormulaFormData,
} from '../../utils/constants';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import { css } from '@emotion/react';
import React, { BaseSyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoCompleteComponent from '../input/form/autocomplete';
import { RootState } from '../../store/configureStore';
import { FETCH_EXAM_PLACEMENT, FETCH_EXAM_PLACEMENTS } from '../../store/exam_placements';
import { FETCH_FORMULA, FETCH_FORMULAS } from '../../store/formulas';
import { error, warning } from 'react-notification-system-redux';
import { compact, concat, filter, find, findIndex, flatten, isEmpty, last, map, orderBy, split, toString } from 'lodash';
import Loading from '../loading/Loading';
import { new_operation_simulation } from '../../utils/newOperationSimulation';
import { default_formulas, generateCharOnlyId } from '../../utils/functions';
import ButtonRadioComponent from '../input/form/button_radio';
import { PiMathOperations } from 'react-icons/pi';
import { IconModal } from '../modal/Modal';
import { FETCH_SUBJECT_PERIOD, FETCH_SUBJECT_PERIODS } from '../../store/subject_periods';

interface FormulaStepCustomProps {
  label: string;
  placeholder: string;
  onlyNumbers: boolean;
}

const FieldCustom = Field as new () => GenericField<FormulaStepCustomProps>;

const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #FDFEFF;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

const OperationSimulationCss = css`
  text-align: center;
  font-size: clamp(16px, 2vmax, 3vmin);
  display: flex;
  justify-content: center;
  & .operation-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
  & .selected {
    background-color: ${colors.lightRed};
  }

  & .operation-wrapper.active > .parenthesis,
  .operation-wrapper.active > span,
  .operation-wrapper.active.empty-operation {
    color: ${colors.lightBlue};
    cursor: pointer;
  }

  & .add-operation {
    color: ${colors.blue};
  }

  & .delete-operation,
  .add-operation {
    margin: 0 5px;
    background: white;
    padding: 0;
  }
`;

const CalculatorCss = css`
  display: grid;
  grid-template-columns: 50% 50%;
  background: ${colors.lightBlue};
  font-size: 16px;
  & .operations {
    display: grid;
    text-align: center;
  }

  & .operations > span {
    margin-bottom: 1%;
    padding: 5% 0;
    background: ${colors.grayBlue};
    border-radius: 10px 10px 0 0;
  }

  & .buttons-container-group {
    display: grid;
    grid-template-columns: 50% 50%;
    background: ${colors.grayBlue};
    border-radius: 0 0 10px 10px;
  }

  & .buttons-container-group > div:first-of-type {
    border-right: 1px solid ${colors.lightBlue};
  }

  & .buttons-container {
    display: grid;
    grid-template-columns: repeat(3, ${100 / 3}%);
    justify-items: center;
    padding: 5% 0;
    row-gap: 5%;
  }

  & .buttons-container > button {
    background: white;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
  }

  & .buttons-container > button.selected {
    background: ${colors.blue};
  }

  & .weight-selector {
    display: flex;
    background-color: ${colors.lightGrayBlue};
    justify-content: space-around;
    margin: 2% 0;
    border-radius: 10px;
  }

  & .weight-selector button {
    padding: 5%;
  }

  & .other-fields {
    align-content: center;
    display: grid;
    justify-self: center;
    width: 80%;
  }
`;

const form_name = 'formulaForm';

const CalculatorComponent = (props: {
  operation: NestedOperationAttributes;
  composition_period_id: string;
  composition_period_kind: CompositionPeriodKindEnum;
  subject_period_id?: string;
  subject_period_ids?: number[];
  composition_periods?: CompositionPeriodAttributes[];
  field_name: string;
  deleteChildrenOperation?: () => void;
  parent_operation?: NestedOperationAttributes;
  ktwelve_subject_ids: string[]
}) => {
  const {
    operation,
    composition_periods,
    composition_period_kind,
    composition_period_id,
    subject_period_id,
    subject_period_ids,
    field_name,
    ktwelve_subject_ids,
    deleteChildrenOperation,
    parent_operation,
  } = props;
  const [loadingOptions, setLoadingOptions] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const [operableOptions, setOperableOptions] = React.useState<DefaultOptionType[]>([]);
  const composition_period_ids = map(composition_periods as CompositionPeriodAttributes[], (item) => item.id);

  const handleClearField = () => {
    dispatch(change(form_name, `${field_name}.operable_id`, null));
    setOperableOptions([]);
  };
  const use_operation_operable_options = [
    ...(operation.kind === OperationKindEnum.OPERABLE_INPUT ? [...operationOperableOptions.filter(opt => opt.value !== OperationOperableType.SUBJECT_PERIOD)] : [...operationOperableOptions.filter(opt => opt.value === OperationOperableType.SUBJECT_PERIOD)])
  ]
  const fetchExamPlacementsMethod = async (this_props: {
    name_value: string;
    placeable_ids: (number | string)[];
    placeable_type: ExamPlaceableType;
  }) => {
    const { name_value, placeable_ids, placeable_type } = this_props;
    try {
      const exam_placements = await dispatch(
        FETCH_EXAM_PLACEMENTS.request({
          params: {
            filters: {
              'q[name_cont]': name_value,
              'q[exam_placeable_id_in]': placeable_ids,
              'q[exam_placeable_type_eq]': placeable_type,
              'page[size]': '100',
            },
          },
        }),
      );
      const {
        data: { data },
      } = exam_placements;
      const formatted_data = data.map((item) => ({ id: item.id, ...item.attributes }));
      return formatted_data;
    } catch (error) {
      return [];
    }
  };

  const fetchSubjectPeriods = async (this_props: {
    name_value: string;
  }) => {
    const { name_value } = this_props;
    try {
      const subject_periods = await dispatch(
        FETCH_SUBJECT_PERIODS.request({
          params: {
            filters: {
              'q[name_cont]': name_value,
              'q[ktwelve_subject_id_in]': ktwelve_subject_ids.join(','),
              'page[size]': '100',
            },
          },
        }),
      );
      const {
        data: { data },
      } = subject_periods;
      const formatted_data = data.map((item) => ({ id: item.id, ...item.attributes }));
      return formatted_data;
    } catch (error) {
      return [];
    }
  };

  const fetchFormulasMethod = async (this_props: {
    name_value: string;
    formulable_ids: (number | string)[];
    formulable_type: FormulableType;
  }) => {
    const { name_value, formulable_ids, formulable_type } = this_props;
    try {
      let extra_attrs = {}
      if(formulable_type === FormulableType.SUBJECT_PERIOD){
        extra_attrs = {
          ...extra_attrs,
          'q[formulable_of_SubjectPeriod_type_ktwelve_subject_id_in]': ktwelve_subject_ids
        }
      } else {
        extra_attrs = {
          'q[formulable_id_in]': formulable_ids,
          'q[formulable_type_eq]': formulable_type,
        }
      }
      const formulas = await dispatch(
        FETCH_FORMULAS.request({
          params: {
            filters: {
              'q[name_cont]': name_value,
              'page[size]': '100',
              ...extra_attrs
            },
          },
        }),
      );
      const {
        data: { data },
      } = formulas;
      const formatted_data = data.map((item) => ({ id: item.id, ...item.attributes }));
      return formatted_data;
    } catch (error) {
      return [];
    }
  };

  const fetchOperableOptions = React.useCallback(
    async (value: string) => {
      try {
        if (isEmpty(value)) {
          setOperableOptions([]);
          return;
        }
        setLoadingOptions(true);
        let options = [] as ExamPlacementAttributes[];
        const get_only_from_period = composition_period_kind === CompositionPeriodKindEnum.PARTIAL;
        if (operation.operable_type === OperationOperableType.EXAM_PLACEMENT) {
          const placeable_ids = get_only_from_period ? [composition_period_id] : composition_period_ids;
          const composition_period_exam_placements = await fetchExamPlacementsMethod({
            name_value: value,
            placeable_ids: placeable_ids,
            placeable_type: ExamPlaceableType.COMPOSITION_PERIOD,
          });
          options = options.concat(composition_period_exam_placements);
          if (subject_period_id) {
            const placeable_ids = subject_period_ids || [];
            const subject_period_exam_placements = await fetchExamPlacementsMethod({
              name_value: value,
              placeable_ids: placeable_ids,
              placeable_type: ExamPlaceableType.SUBJECT_PERIOD,
            });
            options = options.concat(subject_period_exam_placements);
          }
          const formatted_options = options.map((item) => ({ value: item.id, label: item.name }));
          setOperableOptions(formatted_options);
        } else if (operation.operable_type === OperationOperableType.FORMULA) {
          let options = [] as FormulaAttributes[];
          const formulable_ids = get_only_from_period ? [composition_period_id] : composition_period_ids;
          const composition_period_formulas = await fetchFormulasMethod({
            name_value: value,
            formulable_ids: formulable_ids,
            formulable_type: FormulableType.COMPOSITION_PERIOD,
          });
          options = options.concat(composition_period_formulas);
          if (subject_period_id) {
            const placeable_ids = subject_period_ids || [];
            const subject_period_formulas = await fetchFormulasMethod({
              name_value: value,
              formulable_ids: placeable_ids,
              formulable_type: FormulableType.SUBJECT_PERIOD,
            });
            options = options.concat(subject_period_formulas);
          }
          const formatted_options = options.map((item) => ({ value: item.id, label: item.name }));
          setOperableOptions(formatted_options);
        } else if (operation.operable_type === OperationOperableType.COMPOSITION_PERIOD) {
          setOperableOptions(
            map(composition_periods, (item) => ({
              value: item.id,
              label: item.name,
            })),
          );
        } else if(operation.operable_type === OperationOperableType.SUBJECT_PERIOD) {
          const subject_periods = await fetchSubjectPeriods({name_value: value})
          setOperableOptions(subject_periods.map(sp => {
            return ({
              label:  [sp.subject_name, sp.composition_period_name, sp.ktwelve_name].join(' - '),
              value: sp.id
            })
          }))
        }
        setLoadingOptions(false);
      } catch (e) {
        dispatch(
          error({
            message: 'Erro ao carregar opções de operáveis',
          }),
        );
      }
    },
    [
      composition_period_id,
      composition_period_kind,
      operation,
      subject_period_id,
      subject_period_ids,
      composition_period_ids,
      ktwelve_subject_ids
    ],
  );

  const getCurrentOperable = async () => {
    if (operation.operable_type === OperationOperableType.EXAM_PLACEMENT) {
      const exam_placement = await dispatch(
        FETCH_EXAM_PLACEMENT.request({
          id: operation.operable_id as number,
        }),
      );

      const {
        data: { data },
      } = exam_placement;
      const option = [
        {
          label: data.attributes.name,
          value: (operation.operable_id as number).toString(),
        },
      ];
      setOperableOptions(option);
    } else if (operation.operable_type === OperationOperableType.FORMULA) {
      const formula = await dispatch(
        FETCH_FORMULA.request({
          id: operation.operable_id,
        }),
      );

      const {
        data: { data },
      } = formula;
      const option = [
        {
          label: data.attributes.name,
          value: operation.operable_id.toString(),
        },
      ];
      setOperableOptions(option);
    } else if(operation.operable_type === OperationOperableType.SUBJECT_PERIOD) {
      const subject_period = await dispatch(
        FETCH_SUBJECT_PERIOD.request({
          id: operation.operable_id,
        }),
      );

      const {
        data: { data },
      } = subject_period;
      const option = [
        {
          label: [data.attributes.subject_name, data.attributes.composition_period_name, data.attributes.ktwelve_name].join(' - '),
          value: operation.operable_id.toString(),
        },
      ];
      setOperableOptions(option);
    }
  };
  const addNewOperation = (num_of_runs = 1) => {
    const last_order =
      last(
        orderBy(
          filter(operation?.children_attributes, (pc: NestedOperationAttributes) => !pc._destroy),
          'order',
          'asc',
        ) as NestedOperationAttributes[],
      )?.order || 0;
    for (let index = 0; index < num_of_runs; index++) {
      const order = index + last_order;
      dispatch(
        arrayPush(form_name, [field_name, 'children_attributes'].join('.'), {
          ...(operation?.id ? { parent_id: operation.id } : {}),
          id: generateCharOnlyId(5),
          order,
          weight: 1,
        }),
      );
    }
  };

  const handleChangeOperationsKind = React.useCallback(() => {
    if (
      filter(operation?.children_attributes, (item: Partial<NestedOperationAttributes>) => !item._destroy).length === 0
    ) {
      return addNewOperation(2);
    }
  }, [operation]);

  const handleChangeKind = React.useCallback((event: BaseSyntheticEvent<PointerEvent, HTMLInputElement, HTMLInputElement>) => {
    if(event.target.checked && ![OperationKindEnum.EXAM_PLACEMENT_ORDER, OperationKindEnum.COMPOSITION_PERIOD_ORDER, OperationKindEnum.FORMULA_STEP].includes(operation.kind) && [OperationKindEnum.EXAM_PLACEMENT_ORDER, OperationKindEnum.COMPOSITION_PERIOD_ORDER, OperationKindEnum.FORMULA_STEP].includes(event.target.value as OperationKindEnum)) {
      dispatch(
        change(
          form_name, [field_name, 'operable_id'].join('.'), null
        )
      )
      dispatch(
        change(
          form_name, [field_name, 'operable_type'].join('.'), null
        )
      )
    }
  }, [operation]);

  const handleOrderChange = React.useCallback(
    (new_order: number) => {
      dispatch(change(form_name, [field_name, 'order'].join('.'), new_order));
    },
    [operation],
  );

  const increaseOrder = () => {
    const current_order = operation.order;
    const current_element_at_that_order = find(
      parent_operation?.children_attributes,
      (item: Partial<NestedOperationAttributes>) => !item._destroy && item.order === operation.order + 1,
    );
    if (current_element_at_that_order) {
      const index = findIndex(
        parent_operation?.children_attributes,
        (item: Partial<NestedOperationAttributes>) => !item._destroy && item.order === operation.order + 1,
      );
      const parent_field_name = [
        field_name.split('.').slice(0, -1).join('.'),
        `children_attributes[${index}]`,
        'order',
      ].join('.');
      dispatch(change(form_name, parent_field_name, current_order));
    }
    handleOrderChange(current_order + 1);
  };

  const decreaseOrder = () => {
    const current_order = operation.order;
    const current_element_at_that_order = find(
      parent_operation?.children_attributes,
      (item: Partial<NestedOperationAttributes>) => !item._destroy && item.order === operation.order - 1,
    );
    if (current_element_at_that_order) {
      const index = findIndex(
        parent_operation?.children_attributes,
        (item: Partial<NestedOperationAttributes>) => !item._destroy && item.order === operation.order - 1,
      );
      const parent_field_name = [
        field_name.split('.').slice(0, -1).join('.'),
        `children_attributes[${index}]`,
        'order',
      ].join('.');
      dispatch(change(form_name, parent_field_name, current_order));
    }
    handleOrderChange(current_order - 1);
  };

  const insertOperationButtonsEventListener = React.useCallback(async () => {
    const active_add_button = document.querySelector(
      '.operation-wrapper.active.selected > .add-operation',
    ) as HTMLButtonElement;
    if (active_add_button) {
      active_add_button.addEventListener('click', () => addNewOperation());
    }
    const active_remove_button = document.querySelector(
      '.operation-wrapper.active.selected > .delete-operation',
    ) as HTMLButtonElement;
    if (active_remove_button && deleteChildrenOperation) {
      active_remove_button.addEventListener('click', deleteChildrenOperation);
    }
    const increment_order_element = document.querySelector(
      '.operation-wrapper.active.selected > .arrow-right',
    ) as HTMLButtonElement;
    if (increment_order_element) {
      increment_order_element.addEventListener('click', increaseOrder);
    }

    const decrement_order_element = document.querySelector(
      '.operation-wrapper.active.selected > .arrow-left',
    ) as HTMLButtonElement;
    if (decrement_order_element) {
      decrement_order_element.addEventListener('click', decreaseOrder);
    }
  }, [operation]);

  const removeOperationButtonsEventListener = React.useCallback(async () => {
    const active_add_button = document.querySelector(
      '.operation-wrapper.active.selected > .add-operation',
    ) as HTMLButtonElement;
    if (active_add_button) {
      active_add_button.removeEventListener('click', () => addNewOperation());
    }
    const active_remove_button = document.querySelector(
      '.operation-wrapper.active.selected > .delete-operation',
    ) as HTMLButtonElement;
    if (active_remove_button && deleteChildrenOperation) {
      active_remove_button.removeEventListener('click', deleteChildrenOperation);
    }
    const increment_order_element = document.querySelector(
      '.operation-wrapper.active.selected > .arrow-right',
    ) as HTMLButtonElement;
    if (increment_order_element) {
      increment_order_element.removeEventListener('click', increaseOrder);
    }

    const decrement_order_element = document.querySelector(
      '.operation-wrapper.active.selected > .arrow-left',
    ) as HTMLButtonElement;
    if (decrement_order_element) {
      decrement_order_element.removeEventListener('click', decreaseOrder);
    }
  }, [operation]);

  const init = React.useCallback(async () => {
    setLoading(true);
    await insertOperationButtonsEventListener();
    if (operation?.operable_id) {
      await getCurrentOperable();
    }
    setLoading(false);
  }, [operation]);

  React.useEffect(() => {
    init();
    return () => {
      removeOperationButtonsEventListener();
    };
  }, [operation]);
  if (loading) {
    return <Loading />;
  }
  return (
    <div css={CalculatorCss}>
      <div className='operations'>
        <span>Entradas</span>
        <div className='buttons-container-group'>
          <div>
            <span>Operações Básicas</span>
            <div className='buttons-container'>
              {operationKindOptions
                .filter((option) => {
                  return [
                    OperationKindEnum.ADDITION,
                    OperationKindEnum.SUBTRACTION,
                    OperationKindEnum.MULTIPLICATION,
                    OperationKindEnum.DIVISION,
                    OperationKindEnum.MAX_VALUE,
                    OperationKindEnum.MIN_VALUE,
                  ].includes(option.value);
                })
                .map((option) => {
                  return (
                    <Field
                      name='kind'
                      key={option.label}
                      onChange={() => handleChangeOperationsKind()}
                      component={ButtonRadioComponent}
                      props={{ value: option.value, title: option.label }}
                      validate={[validation.required]}
                      label={option.symbol}
                    />
                  );
                })}
            </div>
          </div>
          <div>
            <span>Avaliações e Médias</span>
            <div className='buttons-container'>
              {operationKindOptions
                .filter((option) => {
                  return ![
                    OperationKindEnum.ADDITION,
                    OperationKindEnum.SUBTRACTION,
                    OperationKindEnum.MULTIPLICATION,
                    OperationKindEnum.DIVISION,
                    OperationKindEnum.MAX_VALUE,
                    OperationKindEnum.MIN_VALUE,
                  ].includes(option.value);
                })
                .map((option) => {
                  return (
                    <Field
                      name='kind'
                      key={option.label}
                      onChange={handleChangeKind}
                      component={ButtonRadioComponent}
                      props={{ value: option.value, title: option.label }}
                      validate={[validation.required]}
                      label={option.symbol}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className='other-fields'>
        {operation.kind && operation?.kind !== OperationKindEnum.OPERABLE_INPUT && (
          <Field
            label={
              operation.kind === OperationKindEnum.NUMBER_INPUT
                ? 'Valor numérico'
                : operation.kind === OperationKindEnum.COMPOSITION_PERIOD_ORDER
                ? 'Ordem do Período'
                : operation.kind === OperationKindEnum.EXAM_PLACEMENT_ORDER
                ? 'Ordem da Avaliação'
                : operation.kind === OperationKindEnum.FORMULA_STEP
                ? 'Ordem da Média/Fórmula'
                : 'Definir escolha de N maiores elementos da operação'
            }
            component={InputComponent}
            name='number_input'
            onlyNumbers
            placeholder={'7'}
            validate={
              [
                OperationKindEnum.NUMBER_INPUT,
                OperationKindEnum.COMPOSITION_PERIOD_ORDER,
                OperationKindEnum.EXAM_PLACEMENT_ORDER,
                OperationKindEnum.FORMULA_STEP,
              ].includes(operation.kind)
                ? [validation.required]
                : []
            }
          />
        )}
        {!isEmpty(operation.kind) && operation.kind !== OperationKindEnum.NUMBER_INPUT ? (
          <Field label='Peso' component={InputComponent} name='weight' onlyNumbers placeholder={'7'} />
        ) : null}
        {OperationKindEnum.OPERABLE_INPUT === operation.kind || [OperationKindEnum.COMPOSITION_PERIOD_ORDER, OperationKindEnum.EXAM_PLACEMENT_ORDER, OperationKindEnum.FORMULA_STEP].includes(operation.kind) && !isEmpty(subject_period_id) && (
          <Field
            name='operable_type'
            component={SelectComponent}
            options={use_operation_operable_options}
            label={'Fórmula ou Avaliação especifica'}
            placeholder={'Selecione o tipo'}
            validate={[validation.required]}
          />
        )}
        {operation.kind === OperationKindEnum.OPERABLE_INPUT || ([OperationKindEnum.COMPOSITION_PERIOD_ORDER, OperationKindEnum.EXAM_PLACEMENT_ORDER, OperationKindEnum.FORMULA_STEP].includes(operation.kind) && operation.operable_type === OperationOperableType.SUBJECT_PERIOD) && (
          <Field
            name='operable_id'
            label={'Operável'}
            placeholder={'Buscar Operável'}
            component={AutoCompleteComponent}
            disabled={isEmpty(operation.operable_type)}
            autoCompleteProps={{
              disabled: isEmpty(operation.operable_type),
              filterOptions: (x: any) => x,
              loading: loadingOptions,
              clearable: true,
              getValueOnChange: true,
              fetchOptions: fetchOperableOptions,
              onClearField: handleClearField,
            }}
            options={operableOptions}
            validate={isEmpty(operation.operable_type) ? [] : [validation.required]}
          />
        )}
      </div>
    </div>
  );
};

const OperationsForm = (props: {
  current_operation: OperationAttributes;
  composition_period_id: string;
  composition_period_kind: CompositionPeriodKindEnum;
  subject_period_id?: string;
  subject_period_ids?: number[];
  composition_periods?: CompositionPeriodAttributes[];
  formulable_type: FormulableType;
  field_name: string;
  all_operations: Partial<OperationAttributes>[];
  selected_operation: string;
  deleteChildrenOperation?: () => void;
  setSelectedOperation: React.Dispatch<React.SetStateAction<string>>;
  parent_operation?: OperationAttributes;
  ktwelve_subject_ids: string[]
}) => {
  const {
    field_name,
    current_operation,
    composition_period_id,
    selected_operation,
    composition_period_kind,
    formulable_type,
    composition_periods = [],
    subject_period_id,
    subject_period_ids = [],
    all_operations,
    deleteChildrenOperation,
    setSelectedOperation,
    parent_operation,
    ktwelve_subject_ids
  } = props;
  return (
    <div>
      {selected_operation &&
        current_operation &&
        current_operation?.id?.toString() === selected_operation?.toString() && (
          <CalculatorComponent
            operation={current_operation}
            field_name={field_name}
            composition_period_id={composition_period_id}
            composition_period_kind={composition_period_kind}
            subject_period_id={subject_period_id}
            subject_period_ids={subject_period_ids}
            composition_periods={composition_periods}
            deleteChildrenOperation={deleteChildrenOperation}
            parent_operation={parent_operation}
            ktwelve_subject_ids={ktwelve_subject_ids}
          />
        )}
      <FieldArray
        name='children_attributes'
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        component={renderChildrenOperationForm}
        children_operation_preffix={field_name}
        subject_period_id={subject_period_id}
        composition_period_id={composition_period_id}
        composition_period_kind={composition_period_kind}
        formulable_type={formulable_type}
        composition_periods={composition_periods}
        selected_operation={selected_operation}
        all_operations={all_operations}
        parent_operation={current_operation}
        setSelectedOperation={setSelectedOperation}
        ktwelve_subject_ids={ktwelve_subject_ids}
      />
    </div>
  );
};

interface ChildrenOperationForm extends WrappedFieldArrayProps {
  children_operation_preffix: string;
  parent_operation: NestedOperationAttributes;
  composition_period_id: string;
  composition_period_kind: CompositionPeriodKindEnum;
  formulable_type: FormulableType;
  subject_period_id?: string;
  subject_period_ids?: number[];
  composition_periods?: CompositionPeriodAttributes[];
  all_operations: Partial<OperationAttributes>[];
  setSelectedOperation: React.Dispatch<React.SetStateAction<string>>;
  selected_operation: string;
  ktwelve_subject_ids: string[]
}

const renderChildrenOperationForm = (props: ChildrenOperationForm) => {
  const {
    fields,
    children_operation_preffix,
    composition_period_id,
    selected_operation,
    composition_period_kind,
    formulable_type,
    composition_periods,
    subject_period_id,
    subject_period_ids,
    all_operations,
    setSelectedOperation,
    ktwelve_subject_ids,
    parent_operation,
  } = props;
  const dispatch = useDispatch();

  const deleteChildrenOperation = React.useCallback(
    (current_children_operation: NestedOperationAttributes, children_operation: string, index: number) => {
      const current_children_operation_id = current_children_operation.id;
      current_children_operation_id && Number.isNaN(Number(current_children_operation_id)) === false
        ? dispatch(change(form_name, `${[children_operation_preffix, children_operation].join('.')}._destroy`, true))
        : fields.remove(index);
      if (current_children_operation.parent_id) {
        setSelectedOperation(current_children_operation.parent_id.toString());
      }
    },
    [fields],
  );

  return (
    <div>
      {fields.map((children_operation, index) => {
        const currentChildrenOperation = fields.get(index) as NestedOperationAttributes;
        if (currentChildrenOperation._destroy) {
          return null;
        }
        return (
          <div key={children_operation}>
            <FormSection name={children_operation}>
              <OperationsForm
                field_name={[children_operation_preffix, children_operation].join('.')}
                formulable_type={formulable_type}
                composition_period_id={composition_period_id}
                current_operation={currentChildrenOperation}
                composition_period_kind={composition_period_kind}
                subject_period_id={subject_period_id}
                subject_period_ids={subject_period_ids}
                composition_periods={composition_periods}
                all_operations={all_operations}
                selected_operation={selected_operation}
                setSelectedOperation={setSelectedOperation}
                ktwelve_subject_ids={ktwelve_subject_ids}
                deleteChildrenOperation={() =>
                  deleteChildrenOperation(currentChildrenOperation, children_operation, index)
                }
                parent_operation={parent_operation}
              />
            </FormSection>
          </div>
        );
      })}
    </div>
  );
};

const getAllOperations = (operation: Partial<OperationAttributes>): Partial<OperationAttributes>[] => {
  if (operation.children_attributes && !isEmpty(operation.children_attributes)) {
    return [operation].concat(flatten(operation.children_attributes.map((item) => getAllOperations(item))));
  } else {
    return [operation];
  }
};

interface CustomChooseFormulaProps {
  handleClose?: () => void
  defaultInitialValues: Partial<FormulaFormData>
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
  operation_attributes: OperationAttributes
}

const ChooseFormulaModalContent = (props: CustomChooseFormulaProps) => {
  const { handleClose, defaultInitialValues, setLoading, operation_attributes } = props
  const dispatch = useDispatch()
  const handleChooseFormula = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    try {
      setLoading?.(true)
      const element = event.target as HTMLButtonElement
      const element_id = last(split(element.getAttribute('id'), '-'))
      const button_option = find(default_formulas(defaultInitialValues), cfg => cfg.id === toString(element_id))
      if(button_option) {
        const new_children_attributes = compact(concat(button_option.formula.operation_attributes?.children_attributes, operation_attributes.children_attributes.map(item => ({ id: item.id, _destroy: true }))))
        
        const resulting_operation_attributes: OperationAttributes = {
          ...operation_attributes,
          ...button_option.formula.operation_attributes,
          children_attributes: new_children_attributes
        
        }
        dispatch(
          change(
            form_name, 'operation_attributes', resulting_operation_attributes
          )
        )
        setLoading?.(false)
        handleClose?.()
      }
    } catch (error) {
      setLoading?.(false)
    }
  }
  return (
    <div style={{ display: 'grid', gridTemplateColumns: '50% 50%', gap: '5px', textAlign: 'center' }}>
      <div style={{ display: 'grid' }}>
        <span>Parcial</span>
        {map(filter(default_formulas(defaultInitialValues), item => item.kind === 'partial'), formula_data => (
          <button onClick={(handleChooseFormula)} key={formula_data.id} id={`formula-${formula_data.id}`}>{formula_data.label}</button>
        ))}
      </div>
      <div style={{ display: 'grid' }}>
      <span>Final</span>
      {map(filter(default_formulas(defaultInitialValues), item => item.kind === 'final'), formula_data => (
          <button onClick={(handleChooseFormula)} key={formula_data.id} id={`formula-${formula_data.id}`}>{formula_data.label}</button>
        ))}
      </div>
    </div>
  )
}

interface FormulaFormProps {
  composition_period_id: string;
  composition_period_kind: CompositionPeriodKindEnum;
  close_form?: () => void;
  composition_periods?: CompositionPeriodAttributes[];
  subject_period_ids?: number[];
  subject_period_id?: string;
  not_allowed_steps: string[];
  defaultInitialValues: Partial<FormulaFormData>
  ktwelve_subject_ids: string[]
}

const FormulasForm = (props:FormulaFormProps & {
  handleSubmit?: (arg: () => void) => any;
  onSubmit: () => void
}) => {
  const {
    handleSubmit,
    onSubmit,
    composition_period_id,
    composition_period_kind,
    close_form,
    composition_periods = [],
    subject_period_id,
    subject_period_ids,
    not_allowed_steps,
    defaultInitialValues,
    ktwelve_subject_ids
  } = props;
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state);
  const formValues = formValueSelector(form_name);
  const operation_attributes = formValues(state, 'operation_attributes') as OperationAttributes;
  const formulable_type = formValues(state, 'formulable_type') as FormulableType;
  const all_operations = getAllOperations(operation_attributes);
  const [selected_operation, setSelectedOperation] = React.useState(operation_attributes.id);

  const handleStepChange = (event: React.ChangeEvent<any> | undefined, value: string) => {
    if (not_allowed_steps.includes(value)) {
      event?.preventDefault();
      dispatch(
        warning({
          message: 'Ordem escolhida já está sendo utilizada',
        }),
      );
    }
  };


  return (
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar fórmula</span>
      <div>
        <span>Escolher fórmula</span>
        <IconModal
          icon={PiMathOperations}
          onConfirm={() => null}
          title={`Escolher fórmula`}
          tooltipText={'Ajustar Número de Avaliações e/ou Médias'}
          cancelButtonText='Cancelar'
        >
          <ChooseFormulaModalContent operation_attributes={operation_attributes} defaultInitialValues={defaultInitialValues} />
        </IconModal>
      </div>
      <form css={useStyles.form} autoComplete='off'>
        <div style={{ display: 'grid', gridTemplateColumns: '32% 32% 32%', justifyContent: 'space-between' }}>
          <Field
            name='name'
            component={InputComponent}
            label={'Nome'}
            placeholder={'Insira o nome da fórmula'}
            validate={[validation.required]}
          />
          <Field
            label='Média de aprovação'
            component={InputComponent}
            name='threshold'
            onlyNumbers
            placeholder={'7'}
            validate={[validation.required]}
          />
          <FieldCustom
            label='Ordem da fórmula/média'
            component={InputComponent}
            onChange={handleStepChange}
            name='step'
            onlyNumbers
            placeholder={'1'}
            validate={[validation.required]}
          />
        </div>
        <div css={OperationSimulationCss}>
          {new_operation_simulation({
            operation: operation_attributes,
            selected_operation_id: selected_operation,
            setSelectedOperation,
          })}
        </div>
        <FormSection name='operation_attributes'>
          <OperationsForm
            ktwelve_subject_ids={ktwelve_subject_ids}
            field_name='operation_attributes'
            selected_operation={selected_operation}
            formulable_type={formulable_type}
            current_operation={operation_attributes}
            composition_period_id={composition_period_id}
            composition_period_kind={composition_period_kind}
            subject_period_id={subject_period_id}
            subject_period_ids={subject_period_ids}
            composition_periods={composition_periods}
            all_operations={all_operations}
            setSelectedOperation={setSelectedOperation}
            parent_operation={operation_attributes}
          />
        </FormSection>
        <div css={useStyles.buttons}>
          <button
            onClick={(e) => {
              e.preventDefault();
              close_form?.();
            }}
            className='red small'
          >
            <span> Cancelar </span>
          </button>
          <button onClick={handleSubmit?.(onSubmit)} className='blue small'>
            <span> Salvar fórmula </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(FormulasForm);
