/* eslint-disable camelcase */
import React from 'react';
import {
  colors,
  genderOptions,
  RegDetailsCurrentRegistrationData,
  RegDetailsCurrentStudentData,
  representativeTypes,
  FullRepresentativeName,
} from '../../../utils/constants';
import { convertDocument, ensure } from '../../../utils/functions';
import { css } from '@emotion/react';

export const useStyles = {
  wrapper: css`
    padding: 2rem;
  `,
  view: css`
    width: inherit;
    height: auto;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    flex-grow: 1;
    background: ${colors.lightBlue};
    border-radius: 4px;

    & hr {
      border: 1px solid ${colors.grayBlue};
      margin: 3rem 0;
    }
  `,
  student: css`
    display: grid;
    row-gap: 1rem;

    & > span {
      justify-self: flex-start;
      font-size: 1.25rem;
      margin-top: 1rem;
    }

    & > div.grid {
      display: grid;
      width: fit-content;

      & > div {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        & > div {
          & > span:first-of-type {
            color: ${colors.darkGrayBlue};
            margin: 0 0.25rem;
          }
          & > span:last-of-type {
            color: ${colors.darkBlue};
            margin: 0 0.25rem;
          }
        }
      }
    }

    & > div {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;

      & > div {
        & > span:first-of-type {
          color: ${colors.darkGrayBlue};
          margin: 0 0.25rem;
        }
        & > span:last-of-type {
          color: ${colors.darkBlue};
          margin: 0 0.25rem;
        }
      }
    }

    & .centered {
      margin: auto 0;
    }
  `,
  tableHeader: css`
    align-items: center;
    display: flex;
    padding: 0 2rem;
    justify-content: space-between;
    margin-bottom: 1rem;
  `,
  button: css`
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
    background: ${colors.green};
    padding: 1rem 4rem;
    border: none;
  `,
  title: css`
    font-size: 2rem;
    font-weight: bold;
    align-self: flex-start;
  `,
  filters: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  `,
  table: css`
    background: ${colors.lightBlue};
    padding: 2rem 4rem;
    border-radius: 16px;

    & .MuiTableContainer-root {
      background: inherit;
      box-shadow: none;

      & .MuiTableCell-root {
        border-bottom: 1px solid ${colors.grayBlue};
      }

      & th {
        color: ${colors.darkGrayBlue};
      }

      & td {
        color: ${colors.darkBlue};

        & svg {
          cursor: pointer;
        }
      }

      & td.MuiTableCell-footer {
        border-bottom: none;
      }
    }
  `,
};


const RegistrationDetailsView = (props: {
  currentStudent: RegDetailsCurrentStudentData;
  currentRegistration: RegDetailsCurrentRegistrationData;
}) => {
  const { currentStudent, currentRegistration } = props;
  const {
    name,
    document_number,
    document_type,
    additional_data,
    email,
    gender,
    phone,
    race,
    ethnicity,
    birthplace,
    birthdate,
    nationality,
    address,
    user_connections,
    alergies,
    deficiencies,
  } = currentStudent;

  const { representatives, products, documents } = currentRegistration;
  const repAlternatives: FullRepresentativeName[] = [
    'financial_representatives',
    'pedagogical_representatives',
    'pedagogical_financial_representatives',
  ];
  return (
    <div css={useStyles.wrapper}>
      <div css={useStyles.view}>
        <span css={useStyles.title}>Aluno</span>
        <div css={useStyles.student}>
          <span> Dados Pessoais </span>
          <div>
            <div>
              <span>Nome</span>
              <span>{name}</span>
            </div>
            <div>
              <span>{document_type.toUpperCase()}</span>
              <span>{convertDocument(document_number)}</span>
            </div>
            {!!additional_data && (
              <div>
                <span> Nome Social </span>
                <span> {additional_data?.social_name} </span>
              </div>
            )}
            <div>
              <span> Email </span>
              <span> {email} </span>
            </div>
            <div>
              <span> Sexo </span>
              <span> {genderOptions.find((item) => item.value === gender)?.label} </span>
            </div>
            <div>
              <span> Telefone </span>
              <span> {phone} </span>
            </div>
            {!!race && (
              <div>
                <span> Cor/Raça </span>
                <span> {race} </span>
              </div>
            )}
            {!!ethnicity && (
              <div>
                <span> Etnia </span>
                <span> {ethnicity} </span>
              </div>
            )}
            <div>
              <span> Cidade </span>
              <span> {birthplace} </span>
            </div>
            <div>
              <span> Data de Nascimento </span>
              <span> {birthdate} </span>
            </div>
            <div>
              <span> Nacionalidade </span>
              <span> {nationality} </span>
            </div>
          </div>
          {!!address && (
            <>
              <span> Endereço </span>
              <div>
                <div>
                  <span>CEP</span>
                  <span>{address?.zipcode}</span>
                </div>
                <div>
                  <span>Rua</span>
                  <span>{address?.street}</span>
                </div>
                <div>
                  <span>Número</span>
                  <span>{address?.number}</span>
                </div>
                <div>
                  <span>Bairro</span>
                  <span>{address?.neighbourhood}</span>
                </div>
                <div>
                  <span> Estado </span>
                  <span>{address?.state}</span>
                </div>
                <div>
                  <span>Cidade</span>
                  <span>{address?.city}</span>
                </div>
                <div>
                  <span>País</span>
                  <span>{address?.country}</span>
                </div>
                {address?.complement && (
                  <div>
                    <span>Complemento</span>
                    <span>{address?.complement}</span>
                  </div>
                )}
              </div>
            </>
          )}
          {!!user_connections.length && (
            <>
              <span>Relacionamentos</span>
              <div>
                {user_connections.map((item) => {
                  return (
                    <React.Fragment key={item.connectionId}>
                      <div>
                        <span>Tipo de Relacionamento</span>
                        <span>{item.kindLabel}</span>
                      </div>
                      <div>
                        <span>Nome ou CPF</span>
                        <span>{item.connectionLabel}</span>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          )}
          {!!alergies.length && (
            <>
              <span> Alergia </span>
              <div className='grid'>
                {alergies.map((item) => {
                  return (
                    <div key={item.alergyId}>
                      <div>
                        <span>Tipo de Alergia</span>
                        <span>{item.alergy}</span>
                      </div>
                      <div>
                        <span>Nome</span>
                        <span>{item.description}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {!!deficiencies.length && (
            <>
              <span> Deficiências </span>
              <div className='grid'>
                {deficiencies.map((item) => {
                  return (
                    <div key={item.deficiencyId}>
                      <div>
                        <span>Tipo de deficiência</span>
                        <span>{item.deficiency}</span>
                      </div>
                      <div>
                        <span>Nome</span>
                        <span>{item.description}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <hr />
        {!!representatives &&
          !!Object.keys(representatives).length &&
          repAlternatives
            .filter((type) => Object.keys(representatives).includes(type))
            .map((type) => {
              const getTitle = ensure(representativeTypes.find((item) => type.includes(item.value)));
              return (
                <>
                  {representatives[type].length ? (
                    <span css={useStyles.title}>{`Responsável(is) ${getTitle.label}(s)`}</span>
                  ) : null}
                  <div css={useStyles.student}>
                    {representatives[type].map((representative) => {
                      const {
                        name,
                        document_number,
                        document_type,
                        email,
                        gender,
                        phone,
                        birthplace,
                        birthdate,
                        nationality,
                        additional_data,
                      } = representative;
                      let city;
                      let complement;
                      let country;
                      let neighbourhood;
                      let number;
                      let state;
                      let street;
                      let zipcode;
                      if (representative.address) {
                        city = address?.city;
                        complement = address?.complement;
                        country = address?.country;
                        neighbourhood = address?.neighbourhood;
                        number = address?.number;
                        state = address?.state;
                        zipcode = address?.zipcode;
                      }

                      return (
                        <React.Fragment key={representative.id + representative.kind}>
                          <span>Dados Pessoais</span>
                          <div>
                            {!!name && (
                              <div>
                                <span>Nome</span>
                                <span>{name}</span>
                              </div>
                            )}
                            {!!(document_type || document_number) && (
                              <div>
                                {document_type && <span>{document_type.toUpperCase()}</span>}
                                {document_number && <span>{convertDocument(document_number)}</span>}
                              </div>
                            )}
                            {!!additional_data && (
                              <div>
                                <span> Nome Social </span>
                                <span> {additional_data?.social_name} </span>
                              </div>
                            )}
                            {!!email && (
                              <div>
                                <span> Email </span>
                                <span> {email} </span>
                              </div>
                            )}
                            {!!gender && (
                              <div>
                                <span> Sexo </span>
                                <span> {genderOptions.find((item) => item.value === gender)?.label} </span>
                              </div>
                            )}
                            {!!phone && (
                              <div>
                                <span> Telefone </span>
                                <span> {phone} </span>
                              </div>
                            )}
                            {!!birthplace && (
                              <div>
                                <span> Naturalidade </span>
                                <span> {birthplace} </span>
                              </div>
                            )}
                            {!!birthdate && (
                              <div>
                                <span> Data de Nascimento </span>
                                <span> {birthdate} </span>
                              </div>
                            )}
                            {!!nationality && (
                              <div>
                                <span> Nacionalidade </span>
                                <span> {nationality} </span>
                              </div>
                            )}
                          </div>
                          <span> Endereço </span>
                          <div>
                            {!!zipcode && (
                              <div>
                                <span>CEP</span>
                                <span>{zipcode}</span>
                              </div>
                            )}
                            {!!street && (
                              <div>
                                <span>Rua</span>
                                <span>{street}</span>
                              </div>
                            )}
                            {!!number && (
                              <div>
                                <span>Número</span>
                                <span>{number}</span>
                              </div>
                            )}
                            {!!neighbourhood && (
                              <div>
                                <span>Bairro</span>
                                <span>{neighbourhood}</span>
                              </div>
                            )}
                            {!!state && (
                              <div>
                                <span> Estado </span>
                                <span>{state}</span>
                              </div>
                            )}
                            {!!city && (
                              <div>
                                <span>Cidade</span>
                                <span>{city}</span>
                              </div>
                            )}
                            {!!country && (
                              <div>
                                <span>País</span>
                                <span>{country}</span>
                              </div>
                            )}
                            {!!complement && (
                              <div>
                                <span>Complemento</span>
                                <span>{complement}</span>
                              </div>
                            )}
                          </div>
                          <hr />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </>
              );
            })}
        {!!products?.length && (
          <>
            <span css={useStyles.title} style={{ marginBottom: '1rem' }}>
              Produtos
            </span>
            <div css={useStyles.student}>
              {products.map((item) => {
                return (
                  <div key={item.id}>
                    <div>
                      <span>Tipo</span>
                      <span>{item.kind}</span>
                    </div>
                    <div>
                      <span>Valor</span>
                      <span>{item.price}</span>
                    </div>
                    <div>
                      <span>Desconto</span>
                      <span>{item.discount}</span>
                    </div>
                    <div>
                      <span>Subtotal</span>
                      <span>{item.subtotal}</span>
                    </div>
                    <div>
                      <span>Descrição</span>
                      <span>{item.description}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <hr />
          </>
        )}
        {!!documents?.length && (
          <>
            <span css={useStyles.title} style={{ marginBottom: '1rem' }}>
              Documentos
            </span>
            <div css={useStyles.student}>
              {documents.map((item) => {
                return (
                  <div key={item.id}>
                    <div>
                      <span>Documento</span>
                      <span>{item.name}</span>
                    </div>
                    <div>
                      <span>Arquivo</span>
                      <a href={item.url} target='_blank' rel='noreferrer'>
                        {item.filename}
                      </a>
                    </div>
                    <div>
                      <span>Data de Entrega</span>
                      <span>{item.date}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <hr />
          </>
        )}
      </div>
    </div>
  );
};

export default RegistrationDetailsView;
