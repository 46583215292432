import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { all } from 'redux-saga/effects'
import { reducer as notifications } from 'react-notification-system-redux'

// Reducers
import authReducer from './auth/'
import { accountReducer } from './accounts/'
import { UIReducer } from './ui'

// Sagas
import accountSagas from './accounts/sagas'
import alergySagas from './alergies/sagas'
import asaasSagas from './asaas/sagas'
import authSagas from './auth/sagas'
import citySagas from './cities/sagas'
import classrooomsSagas from './classrooms/sagas'
import companySagas from './companies/sagas'
import contractSagas from './contracts/sagas'
import contractSettingSagas from './contract_settings/sagas'
import countrySagas from './countries/sagas'
import coursesSagas from './courses/sagas'
import deficienciesSagas from './deficiencies/sagas'
import discountTypesSagas from './discount_types/sagas'
import documentsSagas from './documents/sagas'
import ethnicitySagas from './ethnicities/sagas'
import invoiceItemSagas from './invoice_items/sagas'
import invoiceSagas from './invoices/sagas'
import ktwelvesSagas from './ktwelves/sagas'
import paymentOptionSagas from './payment_options/sagas'
import pendingSignaturesagas from './pending_signatures/sagas'
import productFamilySagas from './product_families/sagas'
import productsSagas from './products/sagas'
import profileDashboardsSagas from './profile_dashboards/sagas'
import racesSagas from './races/sagas'
import registrationClosureSagas from './registration_closures/saga'
import registrationConnectionsSagas from './registration_connections/sagas'
import registrationProductsSagas from './registration_products/sagas'
import registrationSagas from './registrations/sagas'
import renegotiationSagas from './renegotiations/sagas'
import representativesSagas from './representatives/sagas'
import stateSagas from './states/sagas'
import subsidySagas from './subsidies/sagas'
import userSagas from './users/sagas'
import walletCreditSagas from './wallet_credits/sagas'
import walletsSagas from './wallets/sagas'
import transactionsSagas from './transactions/sagas'
import coordinatorsSagas from './coordinators/sagas'
import teachersSagas from './teachers/sagas'
import subjectsSagas from './subjects/sagas'
import curriculumsSagas from './curriculums/sagas'
import compositionsSagas from './compositions/sagas'
import compositionPeriodsSagas from './composition_periods/sagas'
import examPlacementsSagas from './exam_placements/sagas'
import formulasSagas from './formulas/sagas'
import ktwelveCurriculumsSagas from './ktwelve_curriculums/sagas'
import ktwelveSubjectsSagas from './ktwelve_subjects/sagas'
import subjectPeriodsSagas from './subject_periods/sagas'
import roomsSagas from './rooms/sagas'
import roomStudentsSagas from './room_students/sagas'
import roomSchedulesSagas from './room_schedules/sagas'
import classTimesSagas from './class_times/sagas'
import classTimeSubjectsSagas from './class_time_subjects/sagas'
import teacherSubjectsSagas from './teacher_subjects/sagas'
import calendarEventSagas from './calendar_events/sagas'
import student_absencesSagas from './student_absences/sagas'
import subjectPeriodRegistrationsSagas from './subject_period_registrations/sagas'
import ktwelveCurriculumRegistrationsSagas from './ktwelve_curriculum_registrations/sagas'
import examsSagas from './exams/sagas'
import auditsSagas from './audits/sagas'
import absenceJustificationSagas from './absence_justifications/sagas'


export function * rootSaga () {
  yield all([
    absenceJustificationSagas(),
    accountSagas(),
    alergySagas(),
    asaasSagas(),
    auditsSagas(),
    authSagas(),
    calendarEventSagas(),
    citySagas(),
    classrooomsSagas(),
    classTimesSagas(),
    classTimeSubjectsSagas(),
    companySagas(),
    contractSagas(),
    contractSettingSagas(),
    compositionsSagas(),
    compositionPeriodsSagas(),
    coordinatorsSagas(),
    countrySagas(),
    coursesSagas(),
    curriculumsSagas(),
    deficienciesSagas(),
    discountTypesSagas(),
    documentsSagas(),
    ethnicitySagas(),
    examsSagas(),
    examPlacementsSagas(),
    formulasSagas(),
    invoiceItemSagas(),
    invoiceSagas(),
    ktwelvesSagas(),
    ktwelveCurriculumsSagas(),
    ktwelveCurriculumRegistrationsSagas(),
    ktwelveSubjectsSagas(),
    paymentOptionSagas(),
    pendingSignaturesagas(),
    productFamilySagas(),
    productsSagas(),
    profileDashboardsSagas(),
    racesSagas(),
    registrationClosureSagas(),
    registrationConnectionsSagas(),
    registrationProductsSagas(),
    registrationSagas(),
    renegotiationSagas(),
    representativesSagas(),
    roomsSagas(),
    roomSchedulesSagas(),
    roomStudentsSagas(),
    stateSagas(),
    subjectsSagas(),
    subjectPeriodsSagas(),
    subjectPeriodRegistrationsSagas(),
    subsidySagas(),
    student_absencesSagas(),
    teachersSagas(),
    teacherSubjectsSagas(),
    transactionsSagas(),
    userSagas(),
    walletCreditSagas(),
    walletsSagas()
  ])
}

export default combineReducers({
  account: accountReducer,
  auth: authReducer,
  form: formReducer,
  ui: UIReducer,
  notifications
})
