import React from 'react';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Theme } from '@mui/system';
import {
  colors,
  contractSettingServiceOptions,
  PaginationType,
  Paths,
  defaultPagination,
  Role,
  ContractSettingsViewAttributes,
} from '../../../utils/constants';
import TablePaginationActions from '../../shared/TablePaginationActions';
import { IconModal } from '../../modal/Modal';
import { find, isNil } from 'lodash';
import { useHistory } from 'react-router-dom';
import PenIcon from '../../icon/PenIcon';
import InputComponent from '../../input/form/input';
import SelectComponent from '../../input/form/select';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';
import { css, useTheme } from '@emotion/react';

export const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    tableTitle: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      background: ${colors.green};
      padding: 1rem 4rem;
      border: none;
    `,
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
    table: css`
      background: ${colors.lightBlue};
      padding: 2rem;
      border-radius: 4px;
      display: grid;

      & .subtitle {
        text-align: start;
        margin-bottom: 1rem;
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};



const ContractSettingsView = ({
  contractSettings,
  pagination,
  handleChangePageSize,
  handlePageChange,
  descriptionFilterValue,
  setDescriptionFilterValue,
  fetchContractSettingsMethod,
  companyFilterValue,
  setCompanyFilterValue,
}: {
  contractSettings: ContractSettingsViewAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  descriptionFilterValue: string;
  setDescriptionFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fetchContractSettingsMethod: ({
    newPagination,
    company_filter,
  }: {
    newPagination?: PaginationType | undefined;
    company_filter?: string;
  }) => Promise<void>;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const columns = [...(is_above_school_director ? ['Unidade'] : []), 'Descrição', 'Serviço', 'Ações'];

  return (
    <div css={classes.view}>
      <div css={classes.tableTitle}>
        <span className='title'>Configurações de contrato</span>
        <button className='green small' onClick={() => history.push(Paths.CONTRACT_SETTINGS_FORM)}>
          <span>Criar configuração</span>
        </button>
      </div>
      <div css={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div css={classes.filters}>
          {is_above_school_director && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchContractSettingsMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}
          <InputComponent
            placeholder={`Buscar por descrição`}
            search
            small
            onSearch={() => {
              fetchContractSettingsMethod({});
            }}
            input={{
              value: descriptionFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setDescriptionFilterValue(e.target.value);
              },
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {contractSettings.map((setting) => {
                const contractSettingService = find(
                  contractSettingServiceOptions,
                  (option) => option.value === setting.service,
                )?.label;
                return (
                  <TableRow key={setting.id}>
                    {is_above_school_director && <TableCell>{setting.company}</TableCell>}
                    <TableCell>{setting.description}</TableCell>
                    <TableCell>{contractSettingService}</TableCell>
                    <TableCell>
                      <IconModal
                        icon={VisibilityIcon}
                        title={'Cláusulas'}
                        disabled={isNil(setting.clauses)}
                        onConfirm={({ handleClose }) => handleClose()}
                        tooltipText='Ver cláusulas'
                      >
                        <div style={{ maxHeight: '80vh', overflow: 'auto' }}>
                          <div dangerouslySetInnerHTML={{ __html: setting.clauses }} />
                        </div>
                      </IconModal>
                      <IconButton
                        onClick={() => {
                          history.push(Paths.CONTRACT_SETTINGS_FORM, {
                            contract_setting_id: setting.id,
                            company_id: setting.company_id
                          });
                        }}
                      >
                        <PenIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ContractSettingsView;
