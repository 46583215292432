
import { Theme } from '@mui/system'
import {
  AccountsAttributes,
  BillingAttributes,
  InvoiceItemAttributes,
  InvoiceAttributes,
  UserAttributes,
  RegistrationAttributes,
  ProductAttributes,
  RepresentativesInvoiceViewGraphData,
  InvoiceStatusEnum,
  ContractStatusEnum,
  RepresentativeInvoicePageData,
  InvoiceStatusTypes,
  PaginationType,
  OrderOptions,
  defaultPagination
} from '../../../utils/constants'
import InvoiceTable from '../../table/InvoiceTable';
import { find, includes, reduce, size, uniq, orderBy as lodashOrderBy } from 'lodash';
import RepresentativeInvoiceGraphTable from '../../table/RepresentativeInvoiceGraphTable';
import SelectComponent from '../../input/form/select';
import DatePickerComponent from '../../input/form/datepicker';
import SelectMultipleComponent from '../../input/form/selectmultiple';
import React from 'react';
import { differenceInDays } from 'date-fns';
import { css, useTheme } from '@emotion/react';

export const useStyles = () => {
  const theme = useTheme() as Theme;
  return {
    view: css`
      gap: 1rem;
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    title: css`
      font-size: 2rem;
      font-weight: bold;
      align-self: self-start;
    `,
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
  };
};




const default_pagination = {...defaultPagination, pageSize: 25}

const RepresentativesInvoiceView = ({
  registrationFilterValue,
  setRegistrationFilterValue,
  loadPayments,
  statusFilterValue,
  setStatusFilterValue,
  fromExpirationDateFilterValue,
  toExpirationDateFilterValue,
  setFromExpirationDateFilterValue,
  setToExpirationDateFilterValue,
  initialPageData,
}:{
  initialPageData?: RepresentativeInvoicePageData
  registrationFilterValue: string
  statusFilterValue: (InvoiceStatusTypes|string)[]
  fromExpirationDateFilterValue: Date|null
  toExpirationDateFilterValue: Date|null
  setRegistrationFilterValue: React.Dispatch<React.SetStateAction<string>>
  setStatusFilterValue: React.Dispatch<React.SetStateAction<(InvoiceStatusTypes|string)[]>>
  setFromExpirationDateFilterValue: React.Dispatch<React.SetStateAction<Date | null>>
  setToExpirationDateFilterValue: React.Dispatch<React.SetStateAction<Date | null>>
  loadPayments: (props?: {
    registration_filter?: string;
    status_filter_value?: InvoiceStatusTypes[];
    from_expiration_date_filter?: Date | null;
    to_expiration_date_filter?: Date | null;
}) => Promise<void>
  }) => {
  const classes = useStyles()
  const [pagination, setPagination] = React.useState<PaginationType>(default_pagination);
  const [orderBy, setOrderBy] = React.useState('expiration_date');
  const [order, setOrder] = React.useState<OrderOptions>('asc');  
  const [filteredInvoices, setFilteredInvoices] = React.useState(initialPageData?.invoices || [])

  const contracts_with_generated_invoices = initialPageData && reduce(initialPageData.contracts, (acc, contract) => {
    const {
      billings,
      invoice_items,
      invoices,
      registrations,
      accounts,
      users,
      products
    } = initialPageData
    const condition = [ContractStatusEnum.PENDING_SIGNATURE, ContractStatusEnum.SIGNED].includes(contract.status as ContractStatusEnum)

    if(!condition){
      return acc
    }
    const billings_for_this_contract = billings.filter(cb => cb.billable_id === ~~contract.id && cb.billable_type === 'Contract') as BillingAttributes[]
    const billing_ids = billings_for_this_contract.map(item => ~~item.id)
    const invoice_items_for_billings = invoice_items.filter(item => includes(billing_ids, item.billing_id))
    const invoice_item_invoice_ids = uniq(invoice_items_for_billings.map(item => item.invoice_id))
    const invoices_for_this_contract = invoices.filter(invoice => invoice_item_invoice_ids.includes(~~invoice.id))

    if(size(billings_for_this_contract) === 0){
      return acc
    }
    const registration = find(registrations, registration => ~~registration.id === contract.registration_id) as RegistrationAttributes
    const student_account = find(accounts, account => ~~account.id === registration.account_id) as AccountsAttributes
    const student_user = find(users, user => ~~user.id === student_account.user_id) as UserAttributes
    const product = find(products, product => contract.product_id === ~~product.id) as ProductAttributes
    return acc.concat({
      user: student_user.name,
      product: product.name,
      contract: contract.contract_number,
      contract_id: contract.id,
      contract_status: contract.status as ContractStatusEnum,
      billings: billings_for_this_contract,
      invoice_items: invoice_items_for_billings,
      invoices: invoices_for_this_contract
    })
  },[] as RepresentativesInvoiceViewGraphData[]) || []
  const registration_options = initialPageData?.registrations.map(registration => {
    const registration_student = initialPageData.users.find(user => {
      const registration_account = initialPageData.accounts.find(account => ~~account.id === registration.account_id)
      return ~~user.id === registration_account?.user_id
    })
    return ({
      label: registration_student?.name,
      value: registration.id
    })
  })
  // const invoices_to_show = initialPageData?.invoices.filter(invoice => [InvoiceStatusEnum.ACTIVE, InvoiceStatusEnum.TO_PAYMENT_SERVICE, InvoiceStatusEnum.FINISHED].includes(invoice.status as InvoiceStatusEnum))
  const possible_status_options = [
    {
      label: 'Em aberto',
      value: `${InvoiceStatusEnum.ACTIVE},${InvoiceStatusEnum.TO_PAYMENT_SERVICE}`
    },
    {
      label: 'Paga',
      value: InvoiceStatusEnum.FINISHED
    }
  ]

  const handleFiltering = React.useCallback((this_props: {
    status_filter_value?: InvoiceStatusEnum[]
    representative_filter_value?: string
    registration_filter_value?: string
    from_expiration_date_filter_value?: Date | null
    to_expiration_date_filter_value?: Date | null
    order_filter?: OrderOptions
    order_by_filter?: string
    page_number?: number
    page_size?: number
  }) => {
    const {
      status_filter_value = statusFilterValue,
      from_expiration_date_filter_value = fromExpirationDateFilterValue,
      to_expiration_date_filter_value = toExpirationDateFilterValue,
      order_filter = order,
      order_by_filter = orderBy,
      page_number = pagination.pageNumber,
      page_size = pagination.pageSize,
      registration_filter_value = registrationFilterValue
    } = this_props
    let filtered_invoices = initialPageData?.invoices || []
    
    if(status_filter_value.length) {
      filtered_invoices = filtered_invoices.filter(invoice => {
        const fixed_status_value = status_filter_value.reduce((acc, filter_value) => {
          if(filter_value.includes(',')){
            return acc.concat(filter_value.split(','))
          }
          return acc.concat(filter_value)
        }, [] as string[])
        const status_check = fixed_status_value.includes(invoice.status)
        return status_check
      })
    }
    
    if(registration_filter_value){
      filtered_invoices = filtered_invoices.filter(invoice => {
        const status_check = ~~registration_filter_value === invoice.registration_id
        return status_check
      })
    }
    
    if(from_expiration_date_filter_value){
      filtered_invoices = filtered_invoices.filter(invoice => {
        if(invoice.expiration_date) {
          const parsed_expiration_date = new Date(invoice.expiration_date)
          return differenceInDays(parsed_expiration_date, from_expiration_date_filter_value) >= 0
        }
      })
    }
    
    if(to_expiration_date_filter_value){
      filtered_invoices = filtered_invoices.filter(invoice => {
        if(invoice.expiration_date) {
          const parsed_expiration_date = new Date(invoice.expiration_date)
          return differenceInDays(parsed_expiration_date, to_expiration_date_filter_value) <= 0
        }
      })
    }
    
    if(order_filter && order_by_filter){
      if (orderBy === 'expiration_date') {
        filtered_invoices = lodashOrderBy(filtered_invoices, (invoice) => new Date(invoice.expiration_date), order_filter);
      } else {
        filtered_invoices = lodashOrderBy(filtered_invoices, [order_by_filter], [order_filter]);
      }
    }
    const count = filtered_invoices.length
    
    filtered_invoices = filtered_invoices.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / page_size);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk'
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, [] as InvoiceAttributes[][])[page_number] || [];
    setPagination({...pagination, pageNumber: page_number, pageSize: page_size, totalCount: count})
    setFilteredInvoices(filtered_invoices)
  }, [
    statusFilterValue,
    initialPageData?.invoices,
    filteredInvoices,
    fromExpirationDateFilterValue,
    toExpirationDateFilterValue,
    order,
    orderBy,
    pagination,
    registrationFilterValue
  ]
)
const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof InvoiceAttributes) => {
  const isAsc = orderBy === property && order === 'asc';
  const new_order = isAsc ? 'desc' : 'asc';
  setOrder(new_order);
  setOrderBy(property);
  handleFiltering({
    order_filter: new_order,
    order_by_filter: property
  })
};
  const handlePageChange = (_: any, newPage: number) => {
    setPagination({
      ...pagination,
      pageNumber: newPage,
    });
    handleFiltering({
      page_number: newPage
    })
  };
  const handleChangePageSize = (e: any) => {
    setPagination({
      ...pagination,
      pageSize: e.target.value,
    });
    handleFiltering({
      page_size: e.target.value
    })
  };

  React.useEffect(() => {
    handleFiltering({})
  }, [])

  return (
    <div css={classes.view}>
      <span css={classes.title}>Visão Geral</span>
      <span style={{ alignSelf: 'self-start' }}>Explore de forma rápida e simples todas as suas faturas em um único lugar</span>
      <RepresentativeInvoiceGraphTable
        contracts={contracts_with_generated_invoices}
      />
      <span css={classes.title}>Faturas</span>
      <div css={classes.filters}>
        <SelectComponent
          placeholder='Aluno'
          small
          options={registration_options}
          clearable
          clearFieldFunction={() => {
            setRegistrationFilterValue('');
            handleFiltering({ registration_filter_value: '' })
          }}
          input={{
            value: registrationFilterValue,
            onChange: (e: any) => {
              const value = e?.target?.value || ''
              setRegistrationFilterValue(value);
              handleFiltering({ registration_filter_value: value })
            },
          }}
        />
        <SelectMultipleComponent
          placeholder='Status'
          small
          allow_select_all
          options={possible_status_options}
          clearable
          clearFieldFunction={() => {
            setStatusFilterValue([]);
            handleFiltering({ status_filter_value: [] })
          }}
          input={{
            value: statusFilterValue,
            onChange: (e: any) => {
              const value = e?.target?.value
              setStatusFilterValue(value);
              handleFiltering({ status_filter_value: value })
            },
          }}
        />
          <DatePickerComponent
            placeholder='Vencimento a partir de'
            small
            datePickerProps={{
              allowSameDateSelection: true,
            }}
            clearFieldFunction={() => {
              setFromExpirationDateFilterValue(null);
              handleFiltering({ from_expiration_date_filter_value: null })
            }}
            input={{
              value: fromExpirationDateFilterValue,
              name: 'year',
              onChange: (e: Date) => {
                setFromExpirationDateFilterValue(e);
                handleFiltering({ from_expiration_date_filter_value: e })
              },
            }}
          />
          <DatePickerComponent
            placeholder='Vencimento até'
            small
            datePickerProps={{
              allowSameDateSelection: true,
            }}
            clearFieldFunction={() => {
              setToExpirationDateFilterValue(null);
              handleFiltering({ to_expiration_date_filter_value: null })
            }}
            input={{
              value: toExpirationDateFilterValue,
              name: 'year',
              onChange: (e: Date) => {
                setToExpirationDateFilterValue(e);
                handleFiltering({ to_expiration_date_filter_value: e })
              },
            }}
          />

      </div>
      <InvoiceTable
        handleUpdateResources={() => loadPayments({})}
        invoice_items={initialPageData?.invoice_items as InvoiceItemAttributes[]}
        invoices={initialPageData?.invoices||[]}
        is_representative_view
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        handleRequestSort={handleRequestSort}
        invoicesDisposition={filteredInvoices}
        order={order}
        orderBy={orderBy}
        pagination={pagination}
      />
    </div>
  )
}

export default RepresentativesInvoiceView