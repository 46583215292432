import React from 'react'
import AccountForm from '../form/AccountForm'
import { LoadingBackdrop } from '../modal/Modal'
import { css } from '@emotion/react';

export const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding: 2rem 10%;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
};


const CreateAccountView = (props: any) => {
  const { submitting, ...rest } = props
  return (
    <div css={useStyles.view}>
      <LoadingBackdrop open={submitting} />
      <span className={`${useStyles.title} title`}>Salvar usuário</span>
      <AccountForm {...rest} />
    </div>
  )
}

export default CreateAccountView
