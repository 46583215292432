import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles, DefaultTheme } from '@mui/styles';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';

import { colors } from '../../../utils/constants';
import { IconButton } from '@mui/material';
import { find } from 'lodash';

type Props = {
  disabled: boolean;
};

const useStyles = makeStyles<DefaultTheme, Props>(() => ({
  inputArea: {
    display: 'grid',
    height: '100%',
    '& span': {
      justifySelf: 'flex-start',
      color: colors.darkGrayBlue,
      fontSize: '0.8rem',
    },
    '& span.error': {
      color: colors.lightRed,
    },
    '& span.error:empty:before': {
      content: '"\\200b"',
    },
    '& .wrapper': {
      background: (props) => (props.disabled ? colors.lightBlue : 'white'),
      borderRadius: '4px',
      border: `1px solid ${colors.grayBlue}`,
      display: 'flex',
      width: '100%',
    },
    '& .clear': {
      color: colors.lightRed,
      cursor: 'pointer',
      pointerEvents: 'all',
    },
    '& div.inputArea': {
      display: 'grid',
      justifyItems: 'flex-start',
      cursor: 'pointer',
      padding: '0.5rem 0.75rem',
      position: 'relative',
      height: '2.5rem',
      width: '100%',
      '& label': {
        color: colors.darkGrayBlue,
        fontSize: '10px',
        position: 'absolute',
        zIndex: 3,
        top: '0.5rem',
        left: '0.75rem',
      },
      '& .select': {
        width: '100%',
        border: 'none',
        background: 'inherit',
        outline: 'none',
        position: 'absolute',
        height: '100%',
        cursor: 'inherit',
        zIndex: '2',
        borderRadius: '4px',
        '& .MuiInputBase-root': {
          paddingRight: 'unset',
          position: 'absolute',
          height: '100%',
          paddingLeft: '0.75rem',
          paddingTop: '1rem',
        },
      },
      '& .circular': {
        position: 'absolute',
        right: '1rem',
        top: '25%',
        cursor: 'inherit',
        zIndex: '1',
      },
      '& .icons': {
        position: 'absolute',
        right: '0.5rem',
        top: '15%',
        zIndex: '4',
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
        pointerEvents: 'none',
        '& svg.arrow': {
          pointerEvents: 'none',
        },
      },
      '& ::placeholder': {
        color: colors.grayBlue,
        fontSize: '16px',
      },
      '& span': {
        justifySelf: 'flex-start',
        fontSize: '16px',
        color: colors.darkBlue,
      },
      '& fieldset': {
        border: 'none',
      },
      '& .placeholder': {
        color: colors.grayBlue,
        fontSize: '16px',
        position: 'absolute',
        left: '0.75rem',
        bottom: '0.75rem',
        zIndex: '3',
      },
    },
  },
}));

const AutoCompleteComponent = (props: any) => {
  const { label, options, placeholder, input, disabled, autoCompleteProps, meta } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const {
    valueKey,
    disabled: autoCompleteDisabled,
    clearable,
    onClearField,
    freeSolo,
    loading,
    onlyNumbers,
    inputFormatFunction,
    showInputValue,
    showDropdownIcon,
    getValueOnChange,
    fetchOptions,
    showOptionValue,
    handleSelect,
  } = autoCompleteProps;
  const classes = useStyles({
    disabled: input.disabled || disabled || autoCompleteDisabled,
  });
  const [autoCompleteKey, setAutocompleteKey] = React.useState(new Date().toISOString());
  React.useEffect(() => {
    if (input.value === '') {
      setAutocompleteKey(new Date().toISOString());
    }
  }, [input.value]);
  type Value =
    | {
        [key: string]: any;
      }
    | any;
  return (
    <div id={`input-${input.name}`}>
      <Autocomplete
        data-testid={input.name}
        autoComplete={false}
        key={autoCompleteKey}
        id={input.name}
        popupIcon={showDropdownIcon && <ArrowDropDownIcon />}
        disableClearable
        loadingText='Carregando...'
        noOptionsText='Sem opções'
        onChange={(event, value: Value) => {
          if (getValueOnChange && typeof value === 'object' && value?.value) {
            input.onChange(value?.value);
          } else {
            input.onChange(value);
          }
        }}
        disabled={disabled || autoCompleteDisabled}
        options={options}
        onBlur={() => input.onBlur()}
        getOptionLabel={(option: { label: string; value: any }) => {
          return option?.label;
        }}
        renderInput={(params: any) => {
          const showPlaceholder =
            !meta.active &&
            typeof input.value !== 'object' &&
            (input.value === undefined || input.value === '') &&
            (params.inputProps.value === undefined ||
              params.inputProps.value === '' ||
              (input.value === '' && params.inputProps.value === '') ||
              (!showInputValue && !freeSolo && input.value === ''));
          const optionFromValue = find(options, (option: { label: string; value: any }) => {
            if (typeof input.value === 'number') {
              return option.value === input.value.toString();
            } else if (typeof input.value === 'object') {
              return option.value === input.value.value;
            } else {
              return option.value === input.value;
            }
          });
          const displayValue =
            (typeof input.value === 'object' &&
              ((valueKey && input.value[valueKey]) ||
                input?.value?.label ||
                input?.value?.value ||
                input?.value?.name)) ||
            (showOptionValue && optionFromValue?.value) ||
            optionFromValue?.label ||
            input.value ||
            (input.value && params.inputProps.value) ||
            params.inputProps.value;
          return (
            <div className={classes.inputArea}>
              <div className='wrapper'>
                <div className='inputArea'>
                  <label htmlFor={input.name}> {label} </label>
                  {showPlaceholder ? <span className='placeholder'>{placeholder} </span> : null}
                  <TextField
                    id={input.name}
                    className='select'
                    {...params}
                    ref={inputRef}
                    variant='outlined'
                    autoComplete='off'
                    inputProps={{
                      ...params.inputProps,
                      disabled: disabled || autoCompleteDisabled,
                      autoComplete: 'off',
                      value: displayValue,
                      style: {
                        color: colors.darkBlue,
                        fontFamily: 'Hauora',
                      },
                      onChange: (e: { target: HTMLInputElement }) => {
                        if (onlyNumbers) {
                          e.target.value = e.target.value?.replace(/[^0-9]/g, '');
                        }
                        params?.inputProps?.onChange(e);
                      },
                    }}
                    disabled={disabled || autoCompleteDisabled}
                    onFocus={input.onFocus}
                  />
                </div>
                {loading ? (
                  <CircularProgress disableShrink size='1rem' />
                ) : clearable || onClearField ? (
                  <IconButton
                    onClick={() => {
                      onClearField?.();
                      inputRef?.current?.value === '';
                      setAutocompleteKey(new Date().toISOString());
                    }}
                  >
                    <CloseIcon className='clear' />
                  </IconButton>
                ) : null}
              </div>
              <span className='error'>{meta && meta.touched && meta.error && meta.error}</span>
            </div>
          );
        }}
        onInputChange={(_, value, reason) => {
          let parsedValue = value;
          if (freeSolo) {
            if (onlyNumbers) {
              parsedValue = value.replace(/[^0-9]/g, '');
            }
            if (inputFormatFunction) {
              parsedValue = inputFormatFunction(parsedValue);
            }
            input.onChange(parsedValue);
          }
          if (fetchOptions && reason === 'input') {
            fetchOptions(parsedValue);
          }
          if (reason === 'reset' && handleSelect) {
            handleSelect(parsedValue);
          }
        }}
        {...autoCompleteProps}
      />
    </div>
  );
};

export default AutoCompleteComponent;
