/* eslint-disable camelcase */
import React from 'react'

import { IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material'
import Paper from '@mui/material/Paper'
import { Theme } from '@mui/system'
import { colors, ProductFamilyAttributes, PaginationType, Paths } from '../../../utils/constants'
import TablePaginationActions from '../../shared/TablePaginationActions'
import { useHistory } from 'react-router-dom'
import PenIcon from '../../icon/PenIcon'
import InputComponent from '../../input/form/input'

import { css, useTheme } from '@emotion/react';

export const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    tableTitle: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      background: ${colors.green};
      padding: 1rem 4rem;
      border: none;
    `,
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
    table: css`
      background: ${colors.lightBlue};
      padding: 2rem;
      border-radius: 4px;
      display: grid;

      & .subtitle {
        text-align: start;
        margin-bottom: 1rem;
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};


const columns = ['Nome', 'Descrição', 'Código', '']

const ProductFamiliesView = ({
  productFamilies,
  pagination,
  handleChangePageSize,
  handlePageChange,
  descriptionFilterValue,
  setDescriptionFilterValue,
  nameFilterValue,
  setNameFilterValue,
  fetchProductFamiliesMethod
}:{
  productFamilies: ProductFamilyAttributes[]
  pagination: PaginationType
  handlePageChange: (_: any, newPage: number) => void
  handleChangePageSize: (e: any) => void
  descriptionFilterValue: string
  setDescriptionFilterValue: React.Dispatch<React.SetStateAction<string>>
  nameFilterValue: string
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>
  fetchProductFamiliesMethod:  ({ newPagination }: {
    newPagination?: PaginationType | undefined;
  }) => Promise<void>
}) => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <div css={classes.view}>
      <div css={classes.tableTitle}>
        <span className='title'>Famílias de produtos</span>
        <button className='green small' onClick={() => history.push(Paths.PRODUCT_FAMILY_FORM)}>
          <span>Criar família de produto</span>
        </button>
      </div>
      <div css={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div
          css={classes.filters}
        >
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchProductFamiliesMethod({})
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value)
              }
            }}
          />
          <InputComponent
            placeholder={`Buscar por descrição`}
            search
            small
            onSearch={() => {
              fetchProductFamiliesMethod({})
            }}
            input={{
              value: descriptionFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setDescriptionFilterValue(e.target.value)
              }
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                productFamilies.map(product_family => {
                  return(
                    <TableRow key={product_family.id}>
                      <TableCell>
                        {product_family.name}
                      </TableCell>
                      <TableCell>
                        {product_family.description}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            history.push(Paths.PRODUCT_FAMILY_FORM, {
                              product_family_id: product_family.id,
                            })
                          }}
                        >
                          <PenIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default ProductFamiliesView