/* eslint-disable camelcase */
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { Paths, validation } from '../../utils/constants'
import InputComponent from '../input/form/input'
import TextAreaComponent from '../input/form/textarea'
import { css } from '@emotion/react';

const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding: 2rem 10%;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #FDFEFF;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

const CoursesForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
}) => {
  const { handleSubmit, onSubmit } = props
  return(
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar curso</span>
      <form css={useStyles.form}>
        <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between'}}>
          <Field
            name='name'
            component={InputComponent}
            label={'Nome'}
            placeholder={'Insira o nome do curso'}
            validate={[validation.required]}
          />
          <Field
            name='code'
            component={InputComponent}
            label={'Código'}
            placeholder={'Insira o código do curso'}
            validate={[validation.required]}
          />
        </div>
        <Field
          name='description'
          component={TextAreaComponent}
          label={'Descrição'}
          placeholder={'Insira a descrição do curso'}
        />
        <div css={useStyles.buttons}>
          <Link to={Paths.COURSES_LIST} className='red small'>
            <span> Cancelar </span>
          </Link>
          <button onClick={handleSubmit(onSubmit)} className='blue small'>
              <span> Salvar curso </span>
          </button>
        </div>
      </form>
    </div>
  )
}

export default compose<any>(
  reduxForm({
      form: 'courseForm',
  }),
)(CoursesForm);
