/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  colors,
  SubjectPeriodAttributes,
  ExamPlaceableType,
  KtwelveSubjectAttributes,
  FormulableType,
  CompositionPeriodAttributes,
  onConfirm,
} from '../../utils/constants';
import { format, parseISO } from 'date-fns';
import { concat, find, isNil, map, orderBy } from 'lodash';
import TooltipButton from '../shared/TooltipButton';
import { Article, Functions } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import ExamPlacementsTable from './ExamPlacementsTable';
import { css } from '@emotion/react';
import FormulasTable from './FormulasTable';
import { useDispatch } from 'react-redux';
import { error } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import { FETCH_KTWELVE_SUBJECT } from '../../store/ktwelve_subjects';
import { SubjectPeriodJson, UPDATE_SUBJECT_PERIOD } from '../../store/subject_periods';
import SelectComponent from '../input/form/select';
import PenIcon from '../icon/PenIcon';
import { IconModal } from '../modal/Modal';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const SubjectPeriodsTable = ({
  ktwelve_subject,
  composition_periods,
  ktwelve_subject_ids,
}: {
  ktwelve_subject: KtwelveSubjectAttributes;
  composition_periods: CompositionPeriodAttributes[];
  ktwelve_subject_ids: string[]
}) => {
  const [expandedExams, setExpandedExams] = React.useState<number[]>([]);
  const [expandedFormulas, setExpandedFormulas] = React.useState<number[]>([]);
  const [subject_periods, setSubjectPeriods] = React.useState<SubjectPeriodAttributes[]>([]);
  const [loading, setLoading] = React.useState(true);

  const dispatch = useDispatch();

  const fetchSubjectPeriods = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        FETCH_KTWELVE_SUBJECT.request({
          id: ktwelve_subject.id,
          params: {
            filters: {
              include: ['subject_periods', 'ktwelve_curriculum.composition.composition_periods'].join(','),
            },
          },
        }),
      );
      const {
        data: { included = [] },
      } = response;
      const subject_periods_attributes = map(
        included.filter((incl) => incl.type === 'subject_periods') as SubjectPeriodJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      setSubjectPeriods(subject_periods_attributes);
      setLoading(false);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar periodos',
        }),
      );
      setLoading(false);
    }
  }, [ktwelve_subject]);

  const updateSubjectPeriod = React.useCallback(
    async (props: { subject_period_id: string; update_data: Partial<SubjectPeriodAttributes> }) => {
      try {
        const { subject_period_id, update_data } = props;
        const response = await dispatch(
          UPDATE_SUBJECT_PERIOD.request({
            id: subject_period_id,
            data: { ...update_data },
          }),
        );
        const {
          data: { data },
        } = response;
        setSubjectPeriods((current) =>
          current.map((sp) => {
            if (sp.id === subject_period_id) {
              return {
                ...sp,
                ...data.attributes,
              };
            }
            return sp;
          }),
        );
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao atualizar disciplina do período',
          }),
        );
      }
    },
    [setSubjectPeriods, subject_periods],
  );

  const init = async () => {
    await fetchSubjectPeriods();
  };
  React.useEffect(() => {
    init();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Table css={TableCss} size='small' aria-label='subject-periods'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Ordem</TableCell>
          <TableCell align='center'>Nome</TableCell>
          <TableCell align='center'>Início</TableCell>
          <TableCell align='center'>Fim</TableCell>
          <TableCell align='center'>Lançamento de notas: início</TableCell>
          <TableCell align='center'>Lançamento de notas: fim</TableCell>
          <TableCell align='center'>Qtd Notas</TableCell>
          <TableCell align='center'>Qtd Médias</TableCell>
          <TableCell>{''}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderBy(subject_periods, 'order').map((subject_period) => {
          const composition_period = find(
            composition_periods,
            (cp) => subject_period.composition_period_id === ~~cp.id,
          );
          const onConfirm: onConfirm = async (props) => {
            const { setLoading, handleClose, setLoadingMessage } = props;
            try {
              setLoading(true);
              setLoadingMessage('Salvando disciplina do período');

              const exam_count = document.querySelector(
                `#input-exam_count-${subject_period?.id} input`,
              ) as HTMLInputElement;
              const formula_count = document.querySelector(
                `#input-formula_count-${subject_period?.id} input`,
              ) as HTMLInputElement;

              await updateSubjectPeriod({
                subject_period_id: subject_period.id,
                update_data: { exam_count: exam_count.value === 'null' ? null : exam_count.value, formula_count: formula_count.value === 'null' ? null : formula_count.value },
              });
              setLoading(false);
              handleClose();
            } catch (err) {
              dispatch(
                error({
                  message: 'Erro ao salvar disciplina do período',
                }),
              );
              setLoading(false);
              handleClose();
            }
          };
          const exam_placement_options = concat(
            [{ label: 'Padrão', value: 'null' }, {label: '0', value: '0'}],
            subject_period.all_exam_placement_options.map((_, index) => ({
              label: (index+1).toString(),
              value: (index+1).toString(),
            })),
          );
          const formula_options = concat(
            [{ label: 'Padrão', value: 'null' }, {label: '0', value: '0'}],
            subject_period.all_formulas_options.map((_, index) => ({
              label: (index+1).toString(),
              value: (index+1).toString(),
            })),
          );
          const ExamFormulaCountCss = css`min-width: 50%`;

          return (
            <React.Fragment key={subject_period.id}>
              <TableRow>
                <TableCell align='center'>{subject_period.order}</TableCell>
                <TableCell align='center'>{subject_period.composition_period_name}</TableCell>
                <TableCell align='center'>{format(parseISO(subject_period.starts_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='center'>{format(parseISO(subject_period.ends_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='center'>{format(parseISO(subject_period.insert_starts_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='center'>{format(parseISO(subject_period.ends_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='center'>
                  {subject_period.exam_count === null
                    ? `Padrão (${subject_period.all_exam_placement_options.length})`
                    : subject_period.exam_count}
                </TableCell>
                <TableCell align='center'>
                  {' '}
                  {subject_period.formula_count === null
                    ? `Padrão (${subject_period.all_formulas_options.length})`
                    : subject_period.formula_count}
                </TableCell>
                <TableCell align='center'>
                  <IconModal
                    icon={PenIcon}
                    onConfirm={onConfirm}
                    title={`Ajustar Número de Avaliações e/ou Médias para ${subject_period.subject_name} - ${subject_period.composition_period_name}`}
                    tooltipText={'Ajustar Número de Avaliações e/ou Médias'}
                    confirmButtonText='Salvar'
                  >
                    <div css={ExamFormulaCountCss}>
                      <SelectComponent
                        placeholder='Selecionar Número de Provas'
                        label='Número de Provas'
                        small
                        input={{
                          placeholder: 'Selecionar Número de Provas',
                          name: `exam_count-${subject_period?.id}`,
                          defaultValue: isNil(subject_period.exam_count) ? 'null' : subject_period.exam_count.toString(),
                        }}
                        options={exam_placement_options}
                      />
                      <SelectComponent
                        placeholder='Selecionar Número de Médias'
                        label='Número de Médias'
                        small
                        input={{
                          placeholder: 'Selecionar Número de Médias',
                          name: `formula_count-${subject_period?.id}`,
                          defaultValue: isNil(subject_period.formula_count) ? 'null' : subject_period.formula_count.toString(),
                        }}
                        options={formula_options}
                      />
                    </div>
                  </IconModal>
                  <TooltipButton
                    tooltipProps={{
                      title: expandedExams.includes(~~subject_period.id) ? 'Ocultar avaliações' : 'Ver avaliações',
                    }}
                    Icon={Article}
                    iconButtonProps={{
                      onClick: () =>
                        setExpandedExams((current) => {
                          if (current.includes(~~subject_period.id)) {
                            return current.filter((item) => item !== ~~subject_period.id);
                          } else {
                            return current.concat(~~subject_period.id);
                          }
                        }),
                    }}
                  />{' '}
                  <TooltipButton
                    tooltipProps={{
                      title: expandedFormulas.includes(~~subject_period.id) ? 'Ocultar fórmulas' : 'Ver fórmulas',
                    }}
                    Icon={Functions}
                    iconButtonProps={{
                      onClick: () =>
                        setExpandedFormulas((current) => {
                          if (current.includes(~~subject_period.id)) {
                            return current.filter((item) => item !== ~~subject_period.id);
                          } else {
                            return current.concat(~~subject_period.id);
                          }
                        }),
                    }}
                  />{' '}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                  <Collapse in={expandedExams.includes(~~subject_period.id)} timeout='auto' unmountOnExit>
                  <span className='subtitle-two'>Avaliações {subject_period.subject_name}-{subject_period.composition_period_name}</span>
                    <ExamPlacementsTable
                      exam_placeable_name={[
                        subject_period.ktwelve_name,
                        subject_period.subject_name,
                        subject_period.composition_period_name,
                      ].join(' - ')}
                      exam_placeable_id={~~subject_period.id}
                      exam_placeable_type={ExamPlaceableType.SUBJECT_PERIOD}
                      composition_period={composition_period as CompositionPeriodAttributes}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                  <Collapse in={expandedFormulas.includes(~~subject_period.id)} timeout='auto' unmountOnExit>
                    <span className='subtitle-two'>Fórmulas / Médias {subject_period.subject_name}-{subject_period.composition_period_name}</span>
                    <FormulasTable
                      composition_period_kind={subject_period.kind}
                      formulable_name={[
                        subject_period.subject_name,
                        subject_period.ktwelve_name,
                        subject_period.composition_period_name,
                      ].join(' - ')}
                      subject_period_id={subject_period.id}
                      composition_period_id={subject_period.composition_period_id.toString()}
                      formulable_id={subject_period.id}
                      formulable_type={FormulableType.SUBJECT_PERIOD}
                      composition_periods={composition_periods}
                      subject_period_ids={subject_periods.map((item) => ~~item.id)}
                      ktwelve_subject_ids={ktwelve_subject_ids}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SubjectPeriodsTable;
