import React from 'react';
import {
  colors,
  CompanyAttributes,
  CompositionAttributes,
  CompositionPeriodAttributes,
  ExamPlaceableType,
  ExamPlacementAttributes,
  FormulaAttributes,
  FormulableType,
  KtwelveSubjectAttributes,
  Role,
  RoomAttributes,
  TeacherAttributes,
} from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { filter, find, head, map } from 'lodash';
import { FETCH_TEACHER } from '../../store/teachers';
import { FETCH_KTWELVE_CURRICULUM } from '../../store/ktwelve_curriculums';
import { KtwelveSubjectJson } from '../../store/ktwelve_subjects';
import { CompositionJson } from '../../store/compositions';
import { CompositionPeriodJson } from '../../store/composition_periods';
import { FormulaJson } from '../../store/formulas';
import { ExamPlacementJson } from '../../store/exam_placements';
import { error } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import { Accordion, AccordionDetails, AccordionSummary, css, Tab, Tabs } from '@mui/material';
import TabPanel from '../shared/TabPanel';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { ExpandMore } from '@mui/icons-material';
import SubjectPeriodRegistrationTable from './SubjectPeriodRegistrationTable';

interface StateCompanies extends CompanyAttributes {
  teachers: TeacherAttributes[];
}

export interface CompositionPeriodData extends CompositionPeriodAttributes {
  formulas: FormulaAttributes[];
  exam_placements: ExamPlacementAttributes[];
}

export interface CompositionData extends CompositionAttributes {
  composition_periods: CompositionPeriodData[];
}

const TabCss = css`
  & .Mui-selected {
    background: #fdfeff;
    border-radius: 1rem 1rem 0 0;
  }
`;

const AccordionCss = css`
  background: inherit;
  box-shadow: none;
  border-bottom: 1px solid ${colors.grayBlue};
`;
const AccordionSummaryCss = css`
  display: flex;
  align-items: center;
  & .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    width: inherit;
  }
`;

const KtwelveSubjectResults = (props: {
  ktwelve_subject: KtwelveSubjectAttributes;
  composition: CompositionData;
  room: RoomAttributes;
}) => {
  const { composition, ktwelve_subject, room } = props;
  let initial_current_composition_period = find(composition.composition_periods, (cp) => {
    const is_same_day_as_start_day = isSameDay(new Date(), new Date(cp.starts_at));
    const is_after_starts_at_day = isAfter(new Date(), new Date(cp.starts_at));
    const is_same_day_as_end_day = isSameDay(new Date(), new Date(cp.ends_at));
    const is_before_end_day = isBefore(new Date(), new Date(cp.ends_at));
    return is_same_day_as_start_day || (is_after_starts_at_day && is_same_day_as_end_day) || is_before_end_day;
  });
  if (!initial_current_composition_period) {
    initial_current_composition_period = head(composition.composition_periods) as CompositionPeriodData;
  }

  return (
    <>
      {map(composition.composition_periods, (cp) => {
        return (
          <Accordion
            defaultExpanded={initial_current_composition_period?.id === cp?.id}
            TransitionProps={{ unmountOnExit: true }}
            key={cp.id}
            css={AccordionCss}
          >
            <AccordionSummary css={AccordionSummaryCss}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <ExpandMore />
                <span>{cp.name}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <SubjectPeriodRegistrationTable
                ktwelve_subject={ktwelve_subject}
                room={room}
                initial_current_composition_period={cp}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

const StudentResultsTable = (props: { room: RoomAttributes }) => {
  const { room } = props;
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
    account: { companies },
  } = state;

  const [composition, setComposition] = React.useState<CompositionData | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [ktwelveSubjects, setKtwelveSubjects] = React.useState<KtwelveSubjectAttributes[]>([]);
  const [tab, setTab] = React.useState('');
  const handleTabChange = (_: any, newValue: string) => {
    setTab(newValue);
  };
  const fetchKtwelveCurriculumData = React.useCallback(async () => {
    try {
      setLoading(true);
      const current_teacher = head(
        find(companies as StateCompanies[], (co: StateCompanies) => co.id === company)?.teachers,
      );
      const evaluate_teacher = profile.role === Role.TEACHER && current_teacher;
      let teacher_ktwelve_subject_ids = [] as string[];
      if (evaluate_teacher) {
        const teacher_ktwelve_subjects_response = await dispatch(
          FETCH_TEACHER.request({
            id: current_teacher.id,
            params: {
              filters: {
                include: 'ktwelve_subjects',
              },
            },
          }),
        );
        const {
          data: { included },
        } = teacher_ktwelve_subjects_response;
        teacher_ktwelve_subject_ids = map(
          filter(included, (incl) => incl.type === 'ktwelve_subjects'),
          (incl) => incl.id,
        );
      }

      const ktwelve_curriculum = await dispatch(
        FETCH_KTWELVE_CURRICULUM.request({
          id: room.ktwelve_curriculum_id,
          params: {
            filters: {
              include: [
                'composition',
                'composition_periods.exam_placements',
                'composition_periods.formulas',
                'ktwelve_subjects',
              ].join(','),
            },
          },
        }),
      );
      const {
        data: { included },
      } = ktwelve_curriculum;
      let ktwelve_subjects = map(
        filter(included, (incl) => incl.type === 'ktwelve_subjects') as KtwelveSubjectJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      if (evaluate_teacher) {
        ktwelve_subjects = filter(ktwelve_subjects, (ks) => teacher_ktwelve_subject_ids.includes(ks.id));
      }
      const included_composition = find(included, (incl) => incl.type === 'compositions') as CompositionJson;
      const composition = {
        id: included_composition.id,
        ...included_composition.attributes,
        composition_periods: map(
          filter(included, (incl) => incl.type === 'composition_periods') as CompositionPeriodJson[],
          (cp) => {
            const formulas = map(
              filter(
                included,
                (incl) =>
                  incl.type === 'formulas' &&
                  incl.attributes.formulable_type === FormulableType.COMPOSITION_PERIOD &&
                  incl.attributes.formulable_id === ~~cp.id,
              ) as FormulaJson[],
              (formula) => ({
                id: formula.id,
                ...formula.attributes,
              }),
            ) as FormulaAttributes[];

            const exam_placements = map(
              filter(
                included,
                (incl) =>
                  incl.type === 'exam_placements' &&
                  incl.attributes.exam_placeable_type === ExamPlaceableType.COMPOSITION_PERIOD &&
                  incl.attributes.exam_placeable_id === ~~cp.id,
              ) as ExamPlacementJson[],
              (formula) => ({
                id: formula.id,
                ...formula.attributes,
              }),
            ) as ExamPlacementAttributes[];
            return {
              id: cp.id,
              ...cp.attributes,
              formulas,
              exam_placements,
            };
          },
        ),
      };
      setComposition(composition);
      setKtwelveSubjects(ktwelve_subjects);
      setTab(head(ktwelve_subjects)?.id as string);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(
        error({
          message: 'Erro ao carregar dados da matriz curricular',
        }),
      );
    }
  }, []);

  const init = React.useCallback(async () => {
    await fetchKtwelveCurriculumData();
  }, []);

  React.useEffect(() => {
    init();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Tabs variant='fullWidth' css={TabCss} value={tab} onChange={handleTabChange}>
        {ktwelveSubjects.map((item) => {
          return <Tab key={item.subject_name} label={item.subject_name} value={item.id} />;
        })}
      </Tabs>
      {ktwelveSubjects.map((item) => {
        return (
          <TabPanel key={item.subject_name} index={item.id} value={tab}>
            <KtwelveSubjectResults ktwelve_subject={item} room={room} composition={composition as CompositionData} />
          </TabPanel>
        );
      })}
    </div>
  );
};

export default StudentResultsTable;
