/* eslint-disable camelcase */
import React from 'react'
import { css } from '@emotion/react'
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip } from '@mui/material'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import { head, isEmpty, isNil } from 'lodash'
import {change, Field, FieldArray, FormSection, WrappedFieldArrayProps} from 'redux-form'
import { colors, discountKindOptions, PaymentCollectionNestedAttributes, PaymentCollectionKindTypes, validation, paymentOptionDateKindOptions, PaymentOptionsNestedAttributes, semiAnualPortionsConfig, ProductPeriodsAttributes, ProductPeriodsNestedAttributes, paymentOptionPortionsOptions, PaymentOptionPortionsEnum } from '../../utils/constants'
import { dateRange, getMonthInteger, getPropsAndParser, leftFillNum } from '../../utils/functions'
import CheckboxComponent from '../input/form/checkbox'
import InputComponent from '../input/form/input'
import MaskedInputComponent from '../input/form/masked'
import SelectComponent from '../input/form/select'
import AddButton from '../shared/AddButton'
import { ExpandMore } from '@mui/icons-material';
import DeleteButton from '../shared/DeleteButton';
import PaymentCollectionForm from './PaymentCollectionForm';
import { useDispatch } from 'react-redux';
import { parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import DatePickerComponent from '../input/form/datepicker';

const useStyles = {
  accordion: css`
    background: inherit;
    box-shadow: none;
    border-bottom: 1px solid ${colors.grayBlue};
  `,
  accordionSummary: css`
    display: flex;
    align-items: center;

    & .MuiAccordionSummary-content {
      align-items: center;
      justify-content: space-between;
      width: inherit;
    }
  `
};

const paymentOptionsWrapperCss = css`
  display: grid;
  row-gap: 1rem;
`

const PaymentCollectionAccordion = ({
  currentPaymentCollection,
  paymentCollectionAttr,
  deletePaymentCollectionMethod,
  index,
  adjustNumber,
}:{
  currentPaymentCollection: PaymentCollectionNestedAttributes
  paymentCollectionAttr: string
  deletePaymentCollectionMethod: (e: React.MouseEvent<HTMLButtonElement>) => void
  index: number
  adjustNumber: (kind: PaymentCollectionKindTypes, paymentCollectionAttr: string) => void
}) => {
  const wasDestroyed = currentPaymentCollection._destroy
  if(wasDestroyed){
    return null
  }


  const kind = currentPaymentCollection.kind
  const name = currentPaymentCollection.description || `Parcela ${index + 1}`
  React.useEffect(() => {
    adjustNumber(kind, paymentCollectionAttr)
  }, [kind])
  return(
    <Accordion key={paymentCollectionAttr} css={useStyles.accordion} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary css={useStyles.accordionSummary}>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <ExpandMore />
        <span>{name}</span>
      </div>
      <DeleteButton onClick={deletePaymentCollectionMethod} tooltip='Excluir configuração de pagamento' />
      </AccordionSummary>
      <AccordionDetails>
        <FormSection name={paymentCollectionAttr}>
          <PaymentCollectionForm
            kind={kind}
          />
        </FormSection>
      </AccordionDetails>
    </Accordion>
  )}
  const calculatePaymentCollectionMonths = ({
    range,
    portions
  }:{
    range: string[],
    portions: number | PaymentOptionPortionsEnum
  }) => {
    if((typeof portions === 'number' && portions > 2) || portions === PaymentOptionPortionsEnum.MONTHLY ){
      return range
    } else if ((typeof portions === 'number' && portions === 2)|| portions === PaymentOptionPortionsEnum.SEMI_ANUALY){
      return semiAnualPortionsConfig.map(({months}) => {
        const firstMonth = range.find(item => {
          return months.includes(getMonthInteger(item))
        }) as string
        return firstMonth
      })
    } else if ((typeof portions === 'number' && portions === 1)|| portions === PaymentOptionPortionsEnum.YEARLY) {
      return [range[0]]
    }
    return []
  }
  
  
  const generatePaymentCollection = ({
    periods_attributes,
    payment_option,
    registration_fee
  }:{
    periods_attributes: ProductPeriodsAttributes[]
    payment_option: PaymentOptionsNestedAttributes
    registration_fee: number
  }) => {
    const { expiration_day, punctuality_expiration_day, punctuality_discount_value, portion_discount_value, advance_discount_value, portions } = payment_option
    const period = head(periods_attributes) as ProductPeriodsAttributes
    const range = dateRange(period.starts_at, period.ends_at)
    const months = calculatePaymentCollectionMonths({range, portions})
    const payment_collections = months.map((item, index) => {
      const month = getMonthInteger(item)
      const expirationDateWithDay = `${leftFillNum(expiration_day)}/${item}`
      const expiration_date = parse(expirationDateWithDay, 'dd/LLL/yyyy', new Date(), { locale: ptBR })
      const punctualityExpirationDateWithDay = `${leftFillNum(punctuality_expiration_day)}/${item}`
      const punctuality_expiration_date = parse(punctualityExpirationDateWithDay, 'dd/LLL/yyyy', new Date(), { locale: ptBR })
      return({
        description: `Parcela ${index+1}`,
        kind: 'portion',
        number: month,
        expiration_date,
        ...((punctuality_discount_value || portion_discount_value) && {punctuality_expiration_date})
      })
    })
    if(registration_fee){
      const firstPaymentCollectionExpirationDate = payment_collections[0]['expiration_date']
      const registration_fee_item = {
        expiration_date: firstPaymentCollectionExpirationDate,
        kind: 'advance',
        description: 'Entrada',
        number: 0,
        ...((advance_discount_value) && {punctuality_expiration_date: firstPaymentCollectionExpirationDate})
      }
      payment_collections.unshift(registration_fee_item)
    }
    return payment_collections
  }
  
interface CustomPaymentCollectionFieldProps extends WrappedFieldArrayProps {
  paymentOptionPrefix: string
  currentPaymentOption: PaymentOptionsNestedAttributes
  periodsAttributes: ProductPeriodsNestedAttributes[]
  registrationFee: string
}
const renderPaymentCollection = (props: CustomPaymentCollectionFieldProps) => {
  const dispatch = useDispatch()
  const { fields, paymentOptionPrefix, currentPaymentOption, periodsAttributes, registrationFee } = props
  const addPaymentCollection = React.useCallback(() => {
    fields.push({})
  }, [fields])
  const adjustNumber = React.useCallback((kind: PaymentCollectionKindTypes, paymentCollectionAttr: string) => {
    if(kind === 'advance'){
      dispatch(
        change(
          'productForm', `${paymentOptionPrefix}.${paymentCollectionAttr}.number`, 0
        )
      )
    }
  }, [fields])
  const deletePaymentCollection = React.useCallback((currentPaymentCollection: PaymentCollectionNestedAttributes, paymentCollectionAttr: string, index: number ) => {
    currentPaymentCollection?.id ? dispatch(
      change(
        'productForm',`${paymentOptionPrefix}.${paymentCollectionAttr}._destroy`, true
      )
    ) : fields.remove(index)
  }, [fields])


  const handleGeneratePaymentCollection = React.useCallback(() => {
    const payment_collections = generatePaymentCollection({periods_attributes: periodsAttributes, payment_option: currentPaymentOption, registration_fee: ~~registrationFee})
    const initialPaymentCollections = currentPaymentOption.payment_collections_attributes
    const remainingPaymentCollections: PaymentCollectionNestedAttributes[] = []
    if(!isEmpty(initialPaymentCollections)){
      initialPaymentCollections?.forEach((payment_collection, index) => {
        if(payment_collection.id){
          remainingPaymentCollections.push({...payment_collection, _destroy: true})
        } else {
          fields.remove(index)
        }
      })
    }
    dispatch(
      change(
        'productForm',`${paymentOptionPrefix}.payment_collections_attributes`, [...remainingPaymentCollections,...payment_collections]
      )
    )
  },[periodsAttributes, currentPaymentOption, registrationFee, paymentOptionPrefix])


  return(
    <div>
      <div
        css={css `
        display: flex;
        align-items: center;
        gap: 1rem;
      `}
      >
        <span>Adicionar configuração de fatura</span>
        <AddButton
          tooltip='Adicionar configuração de fatura'
          onClick={addPaymentCollection}
        />
        <Tooltip title='Gerar configurações de fatura'>
          <IconButton
            style={{ marginBottom: '1rem' }}
            onClick={handleGeneratePaymentCollection}
            disabled={currentPaymentOption.date_kind!=='fixed_date'}
          >
            <FormatListNumberedIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </Tooltip>
      </div>
      {
        fields.map((paymentCollection, index, fields) => {
          const currentPaymentCollection: PaymentCollectionNestedAttributes = fields.get(index)
          const deletePaymentCollectionMethod = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            deletePaymentCollection(currentPaymentCollection, paymentCollection, index)
          }
          return(
            <PaymentCollectionAccordion
              adjustNumber={adjustNumber}
              index={index}
              key={paymentCollection}
              paymentCollectionAttr={paymentCollection}
              currentPaymentCollection={currentPaymentCollection}
              deletePaymentCollectionMethod={deletePaymentCollectionMethod}
            />
          )
        })
      }
    </div>
  )
}

const PaymentOptionsForm = ({
  disablePortions,
  paymentOptionPrefix,
  currentPaymentOption,
  periodsAttributes,
  registrationFee
} : {
  disablePortions: boolean
  paymentOptionPrefix: string
  currentPaymentOption: PaymentOptionsNestedAttributes
  periodsAttributes: ProductPeriodsNestedAttributes[]
  registrationFee: string
}) => {
  const {
    advance_discount_kind,
    portion_discount_kind,
    expiration_day,
    punctuality_discount_kind,
  } = currentPaymentOption
  return (
    <div css={paymentOptionsWrapperCss}>
      <div style={{ display: 'grid', gridTemplateColumns: '32% 32% 32%', justifyContent: 'space-between' }}>
        <Field
          name='name'
          component={InputComponent}
          label={'Nome'}
          placeholder={'Insira o nome da opção de pagamento'}
          validate={[validation.required]}  
        />
        <Field
          name='description'
          component={InputComponent}
          label={'Descrição'}
          placeholder={'Insira a descrição da opção de pagamento'}
          validate={[validation.required]}               
        />
        <Field
          name='portions'
          component={SelectComponent}
          label={'Parcelas'}
          options={paymentOptionPortionsOptions}
          disabled={disablePortions}
          onlyNumbers
          placeholder={'Número de parcelas'}
          validate={[validation.required]}    
        />
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between' }}>
        <Field
          name={`active_starts_at`}
          label={'Selecionável a partir de '}
          placeholder={'01/01/2021'}
          component={DatePickerComponent}
          datePickerProps={{
              ...(currentPaymentOption.active_ends_at ? {maxDate:currentPaymentOption.active_ends_at } : {} ),
              showDropdownIcon: true,
          }}
        />
        <Field
          name={`active_ends_at`}
          label={'Selecionável até '}
          placeholder={'01/01/2021'}
          component={DatePickerComponent}
          datePickerProps={{
            ...(currentPaymentOption.active_starts_at ? {minDate:currentPaymentOption.active_starts_at } : {} ),
            showDropdownIcon: true,
          }}
        />
        <Field
          name='advance_discount_kind'
          component={SelectComponent}
          options={discountKindOptions}
          label={'Desconto Entrada - Tipo'}
          placeholder={'Selecione o tipo do desconto de entrada'}          
        />
        <Field
          name='advance_discount_value'
          component={MaskedInputComponent}
          label={'Desconto Entrada - Valor'}
          disabled={isNil(advance_discount_kind) || advance_discount_kind === 'none'}
          parse={getPropsAndParser(advance_discount_kind).parser}
          maskProps={getPropsAndParser(advance_discount_kind).props}  
          placeholder={'Insira o valor do desconto de entrada'}       
          validate={advance_discount_kind !== 'none' ? [validation.required] : []}   
        />
        <Field
          name='portion_discount_kind'
          component={SelectComponent}
          options={discountKindOptions}
          label={'Desconto Parcela - Tipo'}
          placeholder={'Selecione o tipo do desconto de parcela'}          
        />
        <Field
          name='portion_discount_value'
          component={MaskedInputComponent}
          label={'Desconto Parcela - Valor'}
          disabled={isNil(portion_discount_kind) || portion_discount_kind === 'none'}
          parse={getPropsAndParser(portion_discount_kind).parser}
          maskProps={getPropsAndParser(portion_discount_kind).props}  
          placeholder={'Insira o valor do desconto de parcela'}
          validate={portion_discount_kind !== 'none' ? [validation.required] : []}
        />
        <Field
          name='punctuality_discount_kind'
          component={SelectComponent}
          options={discountKindOptions}
          label={'Desconto Pontualidade - Tipo'}
          placeholder={'Selecione o tipo do desconto de pontualidade'}          
        />
        <Field
          name='punctuality_discount_value'
          component={MaskedInputComponent}
          disabled={isNil(punctuality_discount_kind) || punctuality_discount_kind === 'none'}
          parse={getPropsAndParser(punctuality_discount_kind).parser}
          maskProps={getPropsAndParser(punctuality_discount_kind).props}  
          label={'Desconto Pontualidade - Valor'}
          placeholder={'Insira o valor do desconto de pontualidade'}
          validate={punctuality_discount_kind !== 'none' ? [validation.required] : []}    
        />
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between' }}>
        <Field
          name='expiration_day'
          component={InputComponent}
          label={'Dia do vencimento'}
          onlyNumbers
          placeholder={'Insira o dia do vencimento'}
          validate={[validation.required]}
        />
        <Field
          name='punctuality_expiration_day'
          component={InputComponent}
          label={'Dia limite do desconto condicional'}
          onlyNumbers
          placeholder={'Dia limite do desconto condicional'}
          validate={[validation.required]}
        />
        <Field
          name='days_after_billing'
          component={InputComponent}
          label={'Vencimento em dias após fatura'}
          onlyNumbers
          placeholder={'Vencimento em dias após fatura'}
          validate={[validation.required]}
        />
        <Field
          name='date_kind'
          component={SelectComponent}
          disabled={isNil(expiration_day)}
          options={paymentOptionDateKindOptions}
          label={'Regra geral - fatura'}
          placeholder={'Selecione a regra geral'}
          validate={[validation.required]}   
        />
        <Field
          name='fees'
          component={MaskedInputComponent}
          label={'Juros'}
          parse={getPropsAndParser('percentage').parser}
          maskProps={getPropsAndParser('percentage').props}  
          placeholder={'Juros'}
        />
        <Field
          name='penalty'
          component={MaskedInputComponent}
          label={'Multa'}
          parse={getPropsAndParser('percentage').parser}
          maskProps={getPropsAndParser('percentage').props}  
          placeholder={'Multa'}
        />
      </div>
      <Field
          name='consolidate_payments_same_expiration_date'
          component={CheckboxComponent}
          placeholder={'Consolidar Entrada na parcela com mesmo vencimento?'}
        />
      <FieldArray
        paymentOptionPrefix={paymentOptionPrefix}
        name={`payment_collections_attributes`}
        component={renderPaymentCollection}
        currentPaymentOption={currentPaymentOption}
        periodsAttributes={periodsAttributes}
        registrationFee={registrationFee}
      />
    </div>
  )
}

export default PaymentOptionsForm