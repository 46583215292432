/* eslint-disable camelcase */
import React from 'react';
import { compose } from 'redux';
import {
  change,
  Field,
  GenericField,
  reduxForm,
} from 'redux-form';
import {
  colors,
  DefaultOptionType,
  validation,
  KtwelveCurriculumAttributes,
} from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { error } from 'react-notification-system-redux';
import AutoCompleteComponent from '../input/form/autocomplete';
import { compact, find, reduce } from 'lodash';
import { FETCH_KTWELVES } from '../../store/ktwelves';
import SelectComponent from '../input/form/select';
import { FETCH_CURRICULUM } from '../../store/curriculums';
import Loading from '../loading/Loading';
import InputComponent from '../input/form/input';
import CheckboxComponent from '../input/form/checkbox';
import { css } from '@emotion/react';

interface KtwelveDegreeFieldProps {
  label: string;
  placeholder: string;
  autoCompleteProps: object
  options: DefaultOptionType[]
}

const FieldCustom = Field as new () => GenericField<KtwelveDegreeFieldProps>;

const useStyles = {
  accordion: css`
    background: inherit;
    box-shadow: none;
    border-bottom: 1px solid ${colors.grayBlue};
  `,
  accordionSummary: css`
    display: flex;
    align-items: center;

    & .MuiAccordionSummary-content {
      align-items: center;
      justify-content: space-between;
      width: inherit;
    }
  `,
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #fdfeff;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `
};

const form_name = 'ktwelveCurriculumsForm';

const KtwelveCurriculumsForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  initialValues: KtwelveCurriculumAttributes;
  close_form: () => void
  all_ktwelve_curriculums: KtwelveCurriculumAttributes[]
  curriculum_id: string
  course_id: string
}) => {
  const { handleSubmit, onSubmit, initialValues, close_form, all_ktwelve_curriculums, curriculum_id, course_id } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [loadingKtwelves, setLoadingKtwelves] = React.useState(false)
  const [ktwelveOptions, setKtwelveOptions] = React.useState<DefaultOptionType[]>([]);
  const [compositionOptions, setCompositionOptions] = React.useState<DefaultOptionType[]>([])

  const fetchKtwelveDegreesMethod = React.useCallback(async (value: string) => {
    try {
      setLoadingKtwelves(true);
      const used_ktwelve_ids = compact(
        all_ktwelve_curriculums.map((item) => item.ktwelve_degree_id),
      );
      const ktwelves = await dispatch(
        FETCH_KTWELVES.request({
          params: {
            filters: {
              'q[name_cont]': value,
              'q[id_not_in]': used_ktwelve_ids,
              'q[course_id_eq]': course_id
            },
          },
        }),
      );
      if (ktwelves) {
        const {
          data: { data },
        } = ktwelves;
        const formattedData = data.map(({ attributes, id }) => {
          return {
            label: attributes.name,
            value: id,
          };
        });
        setKtwelveOptions(formattedData);
        setLoadingKtwelves(false);
      }
    } catch (e) {
      dispatch(
        error({
          message: 'Erro ao carregar opções de série',
        }),
      );
    }
  }, []);

  const fetchCompositions = React.useCallback(async () => {
    try {
      const response = await dispatch(
        FETCH_CURRICULUM.request({
          id: curriculum_id,
          params: {
            filters: {
              include: 'compositions',
            },
          },
        }),
      );
      const {
        data: { included },
      } = response;
      const compositions_attributes = reduce(
        included,
        (acc, incl) => {
          if (incl.type === 'compositions') {
            return acc.concat({ value: incl.id, label: incl.attributes.name });
          }
          return acc;
        },
        [] as DefaultOptionType[],
      );
      setCompositionOptions(compositions_attributes);
    } catch (er) {
      dispatch(
        error({
          message: 'Erro ao carregar e matriz curricular',
          autoDismiss: 3,
        }),
      );
    }
  }, [curriculum_id]);

  const init = async () => {
    setLoading(true);
    await fetchCompositions();
    if (initialValues.id) {
      setKtwelveOptions([
        {
          value: initialValues.ktwelve_degree_id.toString(),
          label: initialValues.ktwelve_degree_name,
        },
      ]);
    }
    setLoading(false);
  };
  const onClearField = () => {
    dispatch(change(form_name, 'ktwelve_degree_id', null));
  }

  const onKtwelveDegreeChange = (value: any) => {
    const ktwelve_degree_option = find(ktwelveOptions, opt => opt.value.toString() === value)
    if(ktwelve_degree_option){
      dispatch(change(form_name, 'label', ktwelve_degree_option.label))
    }
  }

  React.useEffect(() => {
    init()
  }, []);

  if(loading){
    return <Loading />
  }
  return (
    <div css={useStyles.view}>
      <form css={useStyles.form}>
        <>
          <div style={{ width: '100%' }}>
            <FieldCustom
              name='ktwelve_degree_id'
              label={'Série'}
              placeholder={'Série'}
              component={AutoCompleteComponent}
              autoCompleteProps={{
                filterOptions: (x: any) => x,
                loading: loadingKtwelves,
                getValueOnChange: true,
                fetchOptions: fetchKtwelveDegreesMethod,
                onClearField: onClearField,
              }}
              onChange={onKtwelveDegreeChange}
              options={ktwelveOptions}
              validate={[validation.required]}
            />
            <Field
              label='Rótulo da Série'
              component={InputComponent}
              name='label'
              placeholder={'Rótulo da Série'}
            />
            <Field
              label={'Composição'}
              placeholder={'Selecionar composição'}
              name='composition_id'
              component={SelectComponent}
              options={compositionOptions}
              validate={[validation.required]}
            />
            <Field
              label='Tempo do intervalo (minutos)'
              component={InputComponent}
              name='interval_length'
              onlyNumbers
              placeholder={'Insira o tempo do intervalo'}
              validate={[validation.required]}
            />
          </div>
          <Field
            name='multiple_rooms'
            component={CheckboxComponent}
            placeholder={'Permitir ensalamento múltiplo'}
          />
          <div css={useStyles.buttons}>
            <button
              onClick={(e) => {
              e.preventDefault()
              close_form()
              }} 
              className='red small'
            >
              <span> Cancelar </span>
            </button>
            <button onClick={handleSubmit(onSubmit)} className='blue small'>
              <span> Salvar Série </span>
            </button>
          </div>
        </>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(KtwelveCurriculumsForm);
