import React from 'react'
import { useDispatch } from "react-redux"
import { change, Field, untouch } from "redux-form"
import { DefaultOptionType, productTypeOptions } from "../../utils/constants"
import { resetFields } from "../../utils/functions"
import DatePickerComponent from '../input/form/datepicker'
import SelectComponent from '../input/form/select'
import { css } from '@emotion/react';

const useStyles = {
  filters: css`
    display: grid;
    row-gap: 1rem;

    & > div > div {
      width: auto;
    }

    & .subtitle-one {
      justify-self: start;
    }
  `,
};

const InsertProductFilters = ({
  coursesOptions,
  classroomOptions,
  loadingDynamicOptions,
  periodOptions
  } : {
    coursesOptions: DefaultOptionType[]
    classroomOptions: DefaultOptionType[]
    loadingDynamicOptions: string[]
    periodOptions: DefaultOptionType[]
  }
) => {
  const dispatch = useDispatch()
  const clearField = React.useCallback((fields: string[]) => {
    resetFields(
      'registration-productstep',
      fields,
      dispatch,
      change,
      untouch
    )
  }, [])
  return (
    <div css={useStyles.filters}>
      <span className='subtitle-one'>Filtros</span>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 19.9%)',
          justifyContent: 'space-between'
        }}
      >
        <Field
          label='Tipo'
          placeholder='Tipo do produto'
          name='type'
          component={SelectComponent}
          options={productTypeOptions}
          clearable
          clearFieldFunction={() => clearField(['type'])}
        />
        <Field
          label='Período letivo'
          placeholder='2023'
          name='from_year'
          component={DatePickerComponent}
          datePickerProps={{
            views: ['year'],
            inputFormat: 'yyyy',
            allowSameDateSelection: true
          }}
        />
        <Field
          label='Curso'
          name='course'
          placeholder='Ensino Fundamental'
          component={SelectComponent}
          options={coursesOptions}
          clearable
          clearFieldFunction={() => clearField(['course', 'classroom'])}

        />
        <Field
          label='Série'
          placeholder='1º ano'
          name='classroom'
          component={SelectComponent}
          options={classroomOptions}
          loading={loadingDynamicOptions.includes('classrooms')}
          clearable
          clearFieldFunction={() => clearField(['classroom'])}
        />
        <Field
          label='Período'
          placeholder='Manhã'
          name='period'
          component={SelectComponent}
          options={periodOptions}
          clearFieldFunction={() => clearField(['period'])}
        />
      </div>
    </div>
  )
}

export default InsertProductFilters