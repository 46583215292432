import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  ContractStatusEnum,
  InvoiceAttributes,
  InvoiceChargeStatusEnum,
  InvoiceStatusEnum,
  RepresentativesInvoiceViewGraphData,
  colors,
  contractStatusOptions,
} from '../../utils/constants';
import { getPortionMonth } from '../../utils/functions';
import LoadingWithLabel from '../shared/LoadingWithLabel'
import { orderBy } from 'lodash';
import { css } from '@emotion/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { RESEND_CONTRACT } from '../../store/contracts';
import { error, success } from 'react-notification-system-redux';

const useStyles = {
  table: css`
    & tbody > tr {
      background-color: ${colors.lightBlue} !important;
    }

    & td {
      color: ${colors.darkBlue};
    }

    & th {
      color: ${colors.darkBlue};
      font-weight: bold;
      font-size: 1vmax;
    }

    & .include {
      display: flex;
      margin-left: auto;
      margin-bottom: 1rem;
    }

    & tfoot {
      background: white;

      & td {
        font-weight: bold;
        font-size: 1rem;
      }

      & td:last-of-type {
        color: ${colors.blue};
      }
    }

    & .MuiTableContainer-root {
      background: inherit;
      box-shadow: none;

      & .MuiTableCell-root {
        border-bottom: 5px solid ${colors.lightBlue};
      }

      & th {
        color: ${colors.darkGrayBlue};
      }

      & td {
        color: ${colors.darkBlue};

        & svg {
          color: ${colors.darkGrayBlue};
          cursor: pointer;
        }
      }

      & td.MuiTableCell-footer {
        border-bottom: none;
      }
    }
  `,
};

const RepresentativeInvoiceGraphTableBody = ({ contracts }: { contracts: RepresentativesInvoiceViewGraphData[] }) => {
  const [loadingContracts, setLoadingContracts] = React.useState<{ id: string; message: string }[]>([]);
  const dispatch = useDispatch();
  const resendContract = React.useCallback(
    async ({ contract_id }: { contract_id: string }) => {
      try {
        setLoadingContracts((current) => current.concat({ id: contract_id, message: 'Reenviando' }));
        await dispatch(
          RESEND_CONTRACT.request({
            id: contract_id,
          }),
        );
        dispatch(
          success({
            title: 'Reenvio de contrato',
            message: 'Contrato reenviado com sucesso!',
            autoDismiss: 3,
          }),
        );
        setLoadingContracts((current) => current.filter((item) => item.id !== contract_id));
      } catch (err) {
        setLoadingContracts((current) => current.filter((item) => item.id !== contract_id));
        dispatch(
          error({
            message: 'Erro no reenvio do contrato',
          }),
        );
      }
    },
    [contracts, loadingContracts, setLoadingContracts],
  );
  return (
    <TableBody>
      {contracts.map((contract) => {
        const contract_status = contract.contract_status;
        const statusInfo = contractStatusOptions?.find((item) => item.value === contract_status)?.label;
        const isLoading = loadingContracts.find((item) => item.id === contract.contract_id);
        return (
          <TableRow key={contract.contract}>
            <TableCell align='left'>{contract.user}</TableCell>
            <TableCell align='center'>{contract.product}</TableCell>
            <TableCell align='center'>{contract.contract}</TableCell>
            <TableCell align='center'>
              <div>
                <span>{statusInfo}</span>
                {contract_status === ContractStatusEnum.PENDING_SIGNATURE && (
                  <>
                    {isLoading ? (
                      <span>
                        <LoadingWithLabel message={isLoading.message} />
                      </span>

                    ) : (
                      <span
                        onClick={() => !isLoading && resendContract({ contract_id: contract.contract_id })}
                        css={css`
                          color: ${colors.cyanBlue};
                          cursor: pointer;
                          display: block;
                          font-weight: bold;
                          &:hover {
                            text-decoration: underline;
                          }
                        `}
                      >
                        Reenviar
                      </span>
                    )}
                  </>
                )}
              </div>
            </TableCell>
            <TableCell align='center' colSpan={3}>
              <div style={{ display: 'flex', minWidth: '40vw', gap: '2px' }}>
                {orderBy(contract.billings, ['service_month'], ['asc']).map((cb, index, self) => {
                  const service_month = cb.service_month;
                  const month_name = service_month === 0 ? 'Entrada' : getPortionMonth(service_month);
                  const cb_invoice_items = contract.invoice_items.reduce(
                    (acc, invoice_item) => {
                      const invoice = contract.invoices.find(
                        (invoice) => ~~invoice.id === invoice_item.invoice_id,
                      ) as InvoiceAttributes;
                      if (!invoice || !invoice_item.active || invoice_item.billing_id !== ~~cb.id) {
                        return acc;
                      }
                      const is_pending =
                        [InvoiceStatusEnum.TO_PAYMENT_SERVICE, InvoiceStatusEnum.ACTIVE].includes(
                          invoice.status as InvoiceStatusEnum,
                        ) && invoice.charge_status !== InvoiceChargeStatusEnum.EXPIRED;
                      if (is_pending) {
                        return {
                          ...acc,
                          pending: acc.pending + invoice_item.base_values.contract_full_billing_percentage_base,
                        };
                      }
                      const is_paid =
                        [InvoiceStatusEnum.FINISHED].includes(invoice.status as InvoiceStatusEnum) &&
                        [InvoiceChargeStatusEnum.RECEIVED, InvoiceChargeStatusEnum.CONFIRMED].includes(
                          invoice.charge_status as InvoiceChargeStatusEnum,
                        );
                      if (is_paid) {
                        return {
                          ...acc,
                          paid: acc.paid + invoice_item.base_values.contract_full_billing_percentage_base,
                        };
                      }
                      const is_expired =
                        invoice.status === InvoiceStatusEnum.ACTIVE &&
                        invoice.charge_status === InvoiceChargeStatusEnum.EXPIRED;
                      if (is_expired) {
                        return {
                          ...acc,
                          expired: acc.expired + invoice_item.base_values.contract_full_billing_percentage_base,
                        };
                      }
                      return acc;
                    },
                    { pending: 0, paid: 0, expired: 0 },
                  );
                  const is_first = index === 0;
                  const is_last = index + 1 === self.length;
                  const borderStyle = {
                    ...(!is_first && !is_last
                      ? {}
                      : is_first && !is_last
                      ? { borderRadius: '5px 0 0 5px' }
                      : !is_first && is_last
                      ? { borderRadius: '0 5px 5px 0' }
                      : { borderRadius: '5px' }),
                  };

                  return (
                    <div
                      key={cb.id}
                      style={{
                        display: 'grid',
                        width: `${100 / contract.billings.length}%`,
                        textAlign: 'center',
                      }}
                    >
                      <span>{month_name}</span>
                      <div
                        style={{ width: '100%', height: '50px', display: 'flex', overflow: 'hidden', ...borderStyle }}
                      >
                        <div
                          style={{
                            height: '100%',
                            width: `${cb_invoice_items.pending * 100}%`,
                            backgroundColor: colors.lightPurple,
                          }}
                        />
                        <div
                          style={{
                            height: '100%',
                            width: `${cb_invoice_items.paid * 100}%`,
                            backgroundColor: colors.lightGreen,
                          }}
                        />
                        <div
                          style={{
                            height: '100%',
                            width: `${cb_invoice_items.expired * 100}%`,
                            backgroundColor: colors.lightRed,
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

const RepresentativeInvoiceGraphTable = (props: { contracts: RepresentativesInvoiceViewGraphData[] }) => {
  return (
    <div>
      <Table css={useStyles.table} size='small' aria-label='Visualização dos pagamentos dos contratos'>
        <TableHead>
          <TableRow>
            <TableCell align='left'>Aluno</TableCell>
            <TableCell align='center'>Produto</TableCell>
            <TableCell align='center'>Contrato</TableCell>
            <TableCell align='center'>Status</TableCell>
            <TableCell align='center' colSpan={3}>
              Parcelas
            </TableCell>
          </TableRow>
        </TableHead>
        <RepresentativeInvoiceGraphTableBody {...props} />
      </Table>
      <div style={{ display: 'flex', marginTop: '1rem' }}>
        <div style={{ marginLeft: 'auto', display: 'grid', gap: '5px' }}>
          <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
            <span>Em Aberto</span>
            <div style={{ width: '50px', height: '25px', backgroundColor: colors.lightPurple, borderRadius: '5px' }} />
          </div>
          <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
            <span>Pago</span>
            <div style={{ width: '50px', height: '25px', backgroundColor: colors.lightGreen, borderRadius: '5px' }} />
          </div>
          <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
            <span>Vencido</span>
            <div style={{ width: '50px', height: '25px', backgroundColor: colors.lightRed, borderRadius: '5px' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepresentativeInvoiceGraphTable;
