import React from 'react'
import { css } from '@emotion/react';

const useStyles = {
  tabPanel: css`
    display: grid;
    background: transparent;
    padding: 1rem;
    border-radius: 0 1rem 1rem 1rem;
  `,
};

export default function TabPanel(props: any) {
  const { children, value, index, style, ...other } = props;
  return (
    <div
        role='tabpanel'
        style={{ display: value !== index && 'none', ...style }}
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        css={useStyles.tabPanel}
        {...other}
    >
        {value === index && <>{children}</>}
    </div>
  );
}