import React from 'react'
import Button from '@mui/material/Button'

import { colors, ssoUrl, somaAppUrl } from '../utils/constants'
import { encodeQueryData } from '../utils/functions'
import somaLogo from '../assets/logo.svg'
import camada from '../assets/camada.svg'
import { Theme } from '@mui/system'
import { css, useTheme } from '@emotion/react';

const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    container: css`
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      width: 100%;
    `,
    childContainer: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background: transparent;
      padding-left: 10vw;
      flex-basis: 30%;
      height: 100%;
      position: relative;
      z-index: 5;

      ${theme.breakpoints?.down(768)} {
        flex-basis: unset;
      }

      & > span {
        color: ${colors.darkBlue};
        text-align: justify;
        align-self: flex-start;
        font-size: 2.5vw;
        margin-bottom: 3vh;
        font-weight: 300;
      }

      & > span:nth-of-type(1) {
        font-weight: 700;
        font-size: 3.5vw;
        color: #3b79bd;
      }

      & > span:nth-of-type(2) {
        font-size: 24px;
        line-height: 33px;
        text-align: start;
      }

      & span:last-of-type {
        font-size: 16px;
        line-height: 22px;
      }
    `,
    logo: css`
      width: 100px;
      margin-bottom: 30px;
    `,
    loginButton: css`
      align-self: flex-start;

      & > a {
        background: ${colors.blue};
        font-size: 1rem;
        padding: 1rem 5rem;

        ${theme.breakpoints?.down('md')} {
          padding: 1rem 3rem;
        }

        & > span {
          color: white;
        }
      }

      & .MuiButton-contained:hover {
        background: #31314f;
      }
    `,
    image: css`
      width: 40vw;
      height: auto;
      position: absolute;
      top: 5%;
      right: 15%;
      z-index: 3;

      ${theme.breakpoints?.down(768)} {
        top: 1%;
        right: 1%;
        width: 160px;
      }

      ${theme.breakpoints?.down(400)} {
        display: none;
      }

      & img {
        width: 100%;
        height: 100%;
      }
    `,
    layer: css`
      background: ${colors.lightBlue};
      position: absolute;
      height: 100vh;
      z-index: 2;
      width: 100vw;
      transform: rotate(110deg);
      right: -35%;
      top: 0;

      ${theme.breakpoints?.down(768)} {
        right: -15%;
      }
    `,
  };
};

const SomaPage = () => {
  const {
    REACT_APP_SERVER_ENV = 'local',
    REACT_APP_API_ENV = REACT_APP_SERVER_ENV,
    REACT_APP_SSO_ENV = REACT_APP_SERVER_ENV,
    REACT_APP_URL_STAGING
  } = process.env


  const urlRedirect = `${REACT_APP_URL_STAGING || somaAppUrl[REACT_APP_API_ENV]}/login`
  const data = {
    app_id: 44,
    url_redirect: urlRedirect
  }
  const urlString = encodeQueryData(data)
  const url = `${ssoUrl[REACT_APP_SSO_ENV]}?${urlString}`
  const classes = useStyles()
  return (
    <>
    <div css={classes.container}>
      <section css={classes.childContainer}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '2vh 0',
            marginRight: 'auto'
          }}
        >
          <img src={somaLogo} alt='Soma' />
        </div>
        <span>
                    Bem-vindo <br /> ao Soma
        </span>
        <span>
                    A primeira plataforma de gestão educacional pensada exclusivamente para as necessidades das escolas.{' '}
        </span>
        <span>
                    Simples e intuitivo, o Soma é um software que atende desde a gestão financeira até a gestão
                    pedagógica da sua instituição. <br /> <br /> Com o Soma você agiliza o processo de matrículas, gera
                    relatórios completos, personaliza o software por completo e muitos outros recursos.{' '}
        </span>
        <div css={classes.loginButton}>
          <Button variant='contained' component={'a'} href={url}>
                        Fazer Login
          </Button>
        </div>
      </section>
    </div>
    <div css={classes.image}>
        <img alt='soma' src={camada} />
      </div>
      <div css={classes.layer}/>
    </>
  )
}

export default SomaPage
