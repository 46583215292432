/* eslint-disable camelcase */
import { css } from '@emotion/react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import {
  change,
  Field,
  FieldArray,
  FormSection,
  reduxForm,
  WrappedFieldArrayProps,
  blur,
  formValueSelector,
} from 'redux-form';
import {
  colors,
  ContractSettingFormAttributes,
  contractSettingServiceOptions,
  DefaultOptionType,
  FormFileType,
  NestedContractSettingSignersAttributes,
  Paths,
  Role,
  validation,
} from '../../utils/constants';
import EditorComponent from '../input/form/editor';
import InputComponent from '../input/form/input';
import RadioComponent from '../input/form/radio';
import TextAreaComponent from '../input/form/textarea';
import AddButton from '../shared/AddButton';
import DeleteButton from '../shared/DeleteButton';
import TabPanel from '../shared/TabPanel';
import ContractSettingSignerForm from './ContractSettingSignerForm';
import { evaluate_permissions, getCompanyFilterOptions, removeSpecialSymbols } from '../../utils/functions';
import { FETCH_USERS } from '../../store/users';
import { error } from 'react-notification-system-redux';
import { RootState } from '../../store/configureStore';
import SelectComponent from '../input/form/select';

const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding: 2rem 10%;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #fdfeff;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
  tabStyle: css`
    & .Mui-selected {
      background: #fdfeff;
      border-radius: 1rem 1rem 0 0;
    }
  `,
  accordion: css`
    background: inherit;
    box-shadow: none;
    border-bottom: 1px solid ${colors.grayBlue};
  `,
  accordionSummary: css`
    display: flex;
    align-items: center;

    & .MuiAccordionSummary-content {
      align-items: center;
      justify-content: space-between;
      width: inherit;
    }
  `,
};

const renderSignatureFields = (props: WrappedFieldArrayProps) => {
  const { fields } = props;
  const addSignature = () => {
    fields.push('');
  };
  const removeSignature = (index: number) => {
    fields.remove(index);
  };
  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar Assinatura</span>
        <AddButton onClick={addSignature} />
      </div>
      {fields.map((signature, index) => {
        return (
          <div
            key={signature}
            style={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            <Field name={signature} component={InputComponent} label='Assinatura' placeholder='Inserir assinatura' />
            <DeleteButton onClick={() => removeSignature(index)} />
          </div>
        );
      })}
    </div>
  );
};

const ContractSettingServiceFields = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {contractSettingServiceOptions.map((option) => {
        return (
          <div style={{ marginBottom: '14px' }} key={option.label}>
            <Field
              name='service'
              component={RadioComponent}
              props={{ value: option.value }}
              validate={[validation.required]}
            />
            <span style={{ whiteSpace: 'nowrap' }}> {option.label} </span>
          </div>
        );
      })}
    </div>
  );
};

const ContractSettingSignerFormAccordion = ({
  contract_setting_signer,
  deleteContractSettingSigner,
  name,
  fileValue,
  clearFileField,
  currentContractSettingSigner,
}: {
  contract_setting_signer: string;
  deleteContractSettingSigner: (e: React.MouseEvent<HTMLButtonElement>) => void;
  clearFileField: (e: React.MouseEvent<HTMLButtonElement>) => void;
  currentContractSettingSigner: NestedContractSettingSignersAttributes;
  name: string;
  fileValue: FormFileType;
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [userOptions, setUserOptions] = React.useState<DefaultOptionType[]>([]);
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company: companyId },
  } = state;
  const fetchUserOptionsMethod = React.useCallback(async (id: string) => {
    try {
      setLoading(true);
      const isNumber = /^\d+$/.test(removeSpecialSymbols(id));
      const param = isNumber ? 'q[document_number_cont]' : 'q[name_cont]';
      const users = await dispatch(
        FETCH_USERS.request({
          params: {
            filters: {
              [param]: isNumber ? removeSpecialSymbols(id) : id,
              'q[companies_id_eq]': companyId,
              'q[profiles_role_in]': [Role.SCHOOL_ADMIN, Role.SCHOOL_MANAGER],
            },
          },
        }),
      );
      if (users) {
        const {
          data: { data },
        } = users;
        const formattedData = data.map(({ attributes, id }) => {
          return {
            label: attributes.name,
            value: id,
          };
        });
        setUserOptions(formattedData);
        setLoading(false);
      }
    } catch (e) {
      dispatch(
        error({
          message: 'Erro ao carregar opções de usuário',
        }),
      );
    }
  }, []);

  React.useEffect(() => {
    if (currentContractSettingSigner.id) {
      setLoading(true);
      setUserOptions([
        { value: currentContractSettingSigner.user_id.toString(), label: currentContractSettingSigner.name as string },
      ]);
      setLoading(false);
    }
  }, []);

  return (
    <Accordion css={useStyles.accordion} key={contract_setting_signer}>
      <AccordionSummary css={useStyles.accordionSummary}>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <ExpandMore />
          <span>{name}</span>
        </div>
        <DeleteButton onClick={deleteContractSettingSigner} />
      </AccordionSummary>
      <AccordionDetails>
        <FormSection name={contract_setting_signer}>
          <ContractSettingSignerForm
            fileValue={fileValue}
            clearFileField={clearFileField}
            loading={loading}
            userOptions={userOptions}
            fetchUserOptions={fetchUserOptionsMethod}
          />
        </FormSection>
      </AccordionDetails>
    </Accordion>
  );
};

const renderContractSettingSigners = (props: WrappedFieldArrayProps) => {
  const { fields } = props;
  const dispatch = useDispatch();
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(async () => updateState({}), []);

  const clearFileFieldMethod = React.useCallback(
    async (contract_setting_signer: string) => {
      await dispatch(change('contractSettingForm', `${contract_setting_signer}.file`, null));
      await dispatch(blur('contractSettingForm', `${contract_setting_signer}.file`, null));
    },
    [fields],
  );

  const addContractSettingSigner = React.useCallback(() => {
    fields.push({});
  }, [fields]);

  const deleteContractSettingSignerMethod = React.useCallback(
    async (
      currentContractSettingSigner: NestedContractSettingSignersAttributes,
      contract_setting_signer: string,
      index: number,
    ) => {
      if (currentContractSettingSigner.id) {
        await dispatch(change('contractSettingForm', `${contract_setting_signer}._destroy`, true));
      } else {
        await fields.remove(index);
      }
    },
    [fields],
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar signatário</span>
        <AddButton onClick={addContractSettingSigner} />
      </div>
      {fields.map((contract_setting_signer, index, fields) => {
        const currentContractSettingSigner: NestedContractSettingSignersAttributes = fields.get(index);
        const wasDestroyed = currentContractSettingSigner._destroy;
        if (wasDestroyed) {
          return null;
        }
        const name = currentContractSettingSigner.name || 'Novo signatário';
        const fileValue = currentContractSettingSigner.file as FormFileType;
        const deleteContractSettingSigner = async (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          e.preventDefault();
          await deleteContractSettingSignerMethod(currentContractSettingSigner, contract_setting_signer, index);
          await forceUpdate();
        };

        const clearFileField = async (e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          await clearFileFieldMethod(contract_setting_signer);
          await forceUpdate();
        };

        return (
          <ContractSettingSignerFormAccordion
            key={contract_setting_signer}
            contract_setting_signer={contract_setting_signer}
            name={name}
            fileValue={fileValue}
            deleteContractSettingSigner={deleteContractSettingSigner}
            clearFileField={clearFileField}
            currentContractSettingSigner={currentContractSettingSigner}
          />
        );
      })}
    </div>
  );
};

const ContractSettingsForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  initialValues: ContractSettingFormAttributes;
}) => {
  const { handleSubmit, onSubmit, initialValues } = props;
  const [tab, setTab] = React.useState('igree');

  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const formValues = formValueSelector('contractSettingForm');
  const companyIdValue = formValues(state, 'company_id') as string;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);

  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const mustSelectCompanyId = is_above_school_director && !initialValues?.id;

  const handleTabChange = (_: any, newValue: string) => {
    setTab(newValue);
  };
  return (
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar configuração de contrato</span>
      <form css={useStyles.form}>
        {mustSelectCompanyId && (
          <>
            <span style={{ justifySelf: 'self-start' }} className='subtitle-one'>
              {' '}
              Unidade{' '}
            </span>
            <Field
              label={'Unidade'}
              placeholder={'Selecionar unidade'}
              name='company_id'
              component={SelectComponent}
              options={companies_options as DefaultOptionType[]}
              validate={[...(mustSelectCompanyId ? [validation.required] : [])]}
            />
          </>
        )}
        {companyIdValue && (
          <>
            <Field
              name='description'
              component={TextAreaComponent}
              label={'Descrição'}
              placeholder={'Insira a descrição da configuração'}
              validate={[validation.required]}
            />
            <Field name='clauses' component={EditorComponent} validate={[validation.required]} />
            <ContractSettingServiceFields />
            <Tabs css={useStyles.tabStyle} value={tab} onChange={handleTabChange}>
              <Tab label='Configuração Igree' value={'igree'} />
              <Tab disabled label='Configuração Zap Sign' value={'zap_sign'} />
            </Tabs>
            <TabPanel style={{ display: 'inherit' }} value={tab} index={'igree'}>
              <div style={{ display: 'grid', gridTemplateColumns: '32% 32% 32%', justifyContent: 'space-between' }}>
                <Field
                  name='params.process_id'
                  component={InputComponent}
                  label={'Id do processo'}
                  placeholder={'Id do processo'}
                />
                <Field
                  name='params.contractor_type_id'
                  component={InputComponent}
                  label={'Id do contratante'}
                  placeholder={'Id do contratante'}
                />
                <Field
                  label='Testemunhas'
                  component={InputComponent}
                  name='witnesses'
                  onlyNumbers
                  placeholder={'Insira o numero de testemunhas'}
                />
              </div>
              <FieldArray name='signatures' component={renderSignatureFields} />
            </TabPanel>
            <TabPanel style={{ display: 'inherit' }} value={tab} index={'zap_sign'}>
              <span>Adicionar arquivos de configuração</span>
              <FieldArray name='contract_setting_signers_attributes' component={renderContractSettingSigners} />
            </TabPanel>
            <div css={useStyles.buttons}>
              <Link to={Paths.CONTRACT_SETTINGS_LIST} className='red small'>
                <span> Cancelar </span>
              </Link>
              <button onClick={handleSubmit(onSubmit)} className='blue small'>
                <span> Salvar configuração </span>
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: 'contractSettingForm',
  }),
)(ContractSettingsForm);
