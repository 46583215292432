/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CiTimer } from 'react-icons/ci';
import {
  colors,
  RoomAttributes,
  RoomScheduleAttributes,
  scheduleStatusOptions,
  DefaultOptionType,
  ScheduleStatusEnum,
  roomScheduleWeekDayOptions,
} from '../../utils/constants';
import { format, parseISO } from 'date-fns';
import { map, toInteger } from 'lodash';
import TooltipButton from '../shared/TooltipButton';
import { AddToPhotos, Circle } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { error, success } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import { DELETE_ROOM_SCHEDULE, RoomScheduleJson } from '../../store/room_schedules';
import { FETCH_ROOM } from '../../store/rooms';
import PenIcon from '../icon/PenIcon';
import RoomSchedulesFormContainer from '../../containers/RoomSchedulesFormContainer';
import ClassTimesTable from './ClassTimesTable';
import { IconModal } from '../modal/Modal';
import TrashIcon from '../icon/TrashIcon';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const RoomSchedulesTable = ({ room }: { room: RoomAttributes }) => {
  const [editRoomSchedule, setEditingRoomSchedule] = React.useState<number[]>([]);
  const [copyRoomSchedule, setCopyingRoomSchedule] = React.useState<number[]>([]);

  const [create, setCreate] = React.useState(false);
  const [room_schedules, setRoomSchedules] = React.useState<RoomScheduleAttributes[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [expandedClassTimes, setExpandedClassTimes] = React.useState<number[]>([]);

  const dispatch = useDispatch();

  const fetchRoomSchedules = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        FETCH_ROOM.request({
          id: room.id,
          params: {
            filters: {
              include: 'room_schedules',
            },
          },
        }),
      );
      const {
        data: { included = [] },
      } = response;
      const room_schedules_attributes = map(
        included.filter((incl) => incl.type === 'room_schedules') as RoomScheduleJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      setRoomSchedules(room_schedules_attributes);
      setLoading(false);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar agendamentos',
        }),
      );
      setLoading(false);
    }
  }, [room]);

  const deleteRoomSchedule = React.useCallback(
    async (
      id: string,
      {
        setLoading,
        handleClose,
      }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; handleClose: () => void },
    ) => {
      try {
        setLoading(true);
        await dispatch(
          DELETE_ROOM_SCHEDULE.request({
            id,
          }),
        );
        setLoading(false);
        dispatch(
          success({
            message: 'Lançamento removido com sucesso',
          }),
        );
        fetchRoomSchedules();
        handleClose();
      } catch (e) {
        handleClose();
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao remover lançamento',
          }),
        );
      }
    },
    [],
  );

  const init = async () => {
    await fetchRoomSchedules();
  };
  React.useEffect(() => {
    init();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Table css={TableCss} size='small' aria-label='subject-periods'>
      <TableHead>
        <TableRow>
          <TableCell>Status</TableCell>
          <TableCell align='center'>Dia da Semana</TableCell>
          <TableCell align='center'>Data de Agendamento</TableCell>
          <TableCell align='center'>Início</TableCell>
          <TableCell align='center'>Fim</TableCell>
          <TableCell align='right'>
            {' '}
            <button disabled={editRoomSchedule.length > 0} onClick={() => setCreate(!create)} className='green small'>
              <span> Adicionar agendamento</span>
            </button>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} align='right'>
            <Collapse in={create} timeout='auto' unmountOnExit>
              <RoomSchedulesFormContainer
                onSave={async () => {
                  await fetchRoomSchedules();
                  setCreate(false);
                }}
                close_form={() => setCreate(false)}
                room={room}
              />
            </Collapse>
          </TableCell>
        </TableRow>
        {room_schedules.map((room_schedule) => {
          const weekday = roomScheduleWeekDayOptions.find((opt) => opt.value === room_schedule.weekday);
          const status = scheduleStatusOptions.find(
            (option) => option.value === room_schedule.status,
          ) as DefaultOptionType;
          const statusLabel = status?.label;
          const statusColorStyle = {
            color:
              status.value === ScheduleStatusEnum.PENDING
                ? '#f5c542'
                : status.value === ScheduleStatusEnum.SUCCESSFUL
                ? colors.green
                : colors.lightRed,
          };
          const close_editing_form = () => {
            setEditingRoomSchedule((current) => current.filter((item) => item !== ~~room_schedule.id));
            setCopyingRoomSchedule((current) => current.filter((item) => item !== ~~room_schedule.id));
          };
          return (
            <React.Fragment key={room_schedule.id}>
              <TableRow>
                <TableCell>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Circle style={statusColorStyle} />
                    <span style={statusColorStyle}>{statusLabel}</span>
                  </div>
                </TableCell>
                <TableCell align='center'>{weekday?.label}</TableCell>
                <TableCell align='center'>{format(parseISO(room_schedule.schedule_date), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='center'>{format(parseISO(room_schedule.starts_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='center'>{format(parseISO(room_schedule.ends_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='right'>
                  {room_schedule.status === ScheduleStatusEnum.PENDING && (
                    <>
                      <TooltipButton
                        tooltipProps={{
                          title: editRoomSchedule.includes(~~room_schedule.id)
                            ? 'Cancelar edição'
                            : 'Editar Agendamento',
                        }}
                        Icon={PenIcon}
                        iconButtonProps={{
                          disabled:
                            create || (editRoomSchedule.length > 0 && !editRoomSchedule.includes(~~room_schedule.id)),
                          onClick: () =>
                            setEditingRoomSchedule((current) => {
                              if (current.includes(~~room_schedule.id)) {
                                return current.filter((item) => item !== ~~room_schedule.id);
                              } else {
                                return current.concat(~~room_schedule.id);
                              }
                            }),
                        }}
                      />
                      <IconModal
                        icon={TrashIcon}
                        title={'Excluir lançamento'}
                        tooltipText='Excluir lançamento'
                        onConfirm={(props) => deleteRoomSchedule(room_schedule.id, props)}
                        message='Tem certeza que deseja remover o evento do calendário?'
                        iconProps={{ style: { color: 'red' } }}
                      />
                    </>
                  )}
                  <TooltipButton
                    tooltipProps={{
                      title: copyRoomSchedule.includes(~~room_schedule.id) ? 'Cancelar cópia' : 'Copiar Agendamento',
                    }}
                    Icon={AddToPhotos}
                    iconButtonProps={{
                      disabled:
                        create || (copyRoomSchedule.length > 0 && !copyRoomSchedule.includes(~~room_schedule.id)),
                      onClick: () =>
                        setCopyingRoomSchedule((current) => {
                          if (current.includes(~~room_schedule.id)) {
                            return current.filter((item) => item !== ~~room_schedule.id);
                          } else {
                            return current.concat(~~room_schedule.id);
                          }
                        }),
                    }}
                  />
                  <TooltipButton
                    tooltipProps={{
                      title: expandedClassTimes.includes(~~room_schedule.id)
                        ? 'Ocultar horários do agendamento'
                        : 'Ver horários do agendamento',
                    }}
                    Icon={CiTimer}
                    iconButtonProps={{
                      onClick: () =>
                        setExpandedClassTimes((current) => {
                          if (current.includes(~~room_schedule.id)) {
                            return current.filter((item) => item !== ~~room_schedule.id);
                          } else {
                            return current.concat(~~room_schedule.id);
                          }
                        }),
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                  <Collapse in={expandedClassTimes.includes(~~room_schedule.id)} timeout='auto' unmountOnExit>
                    <ClassTimesTable room={room} room_schedule={room_schedule} />
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                  <Collapse in={editRoomSchedule.includes(~~room_schedule.id)} timeout='auto' unmountOnExit>
                    <RoomSchedulesFormContainer
                      onSave={fetchRoomSchedules}
                      close_form={close_editing_form}
                      room={room}
                      room_schedule_id={~~room_schedule.id}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                  <Collapse in={copyRoomSchedule.includes(~~room_schedule.id)} timeout='auto' unmountOnExit>
                    <RoomSchedulesFormContainer
                      onSave={fetchRoomSchedules}
                      close_form={close_editing_form}
                      room={room}
                      room_schedule_id={toInteger(room_schedule.id)}
                      is_copy
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default RoomSchedulesTable;
