/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import React from 'react';
import {
  reduxForm,
  Field,
  FieldArray,
  change,
  WrappedFieldArrayProps,
  FormSection,
} from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { css } from '@emotion/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { find, isEmpty, isNil } from 'lodash';

import withRaces from '../hoc/racesHoc';

import {
  validation,
  colors,
  Paths,
  CompanyOption,
  profiles,
  DefaultOptionType,
  AccountFormAttributes,
  ProfilesAttributes,
  companyTypes,
  UserFormFields,
} from '../../utils/constants';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import AddButton from '../shared/AddButton';
import { ensure } from '../../utils/functions';
import TabPanel from '../shared/TabPanel';
import { ExpandMore } from '@mui/icons-material';
import { RootState } from '../../store/configureStore';
import DeleteButton from '../shared/DeleteButton';
import UserForm from './UserForm';

const UserFormCss = css`
  display: flex;
  row-gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  &> div {
    flex-basis: 100%;
  }
`;

const useStyles = {
  form: css`
    display: grid;
    padding-bottom: 5rem;

    & span {
      justify-self: flex-start;
    }

    & .zipcode {
      display: flex;
      max-width: 100%;
      width: 50rem;
      gap: 1rem;

      & > .search {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        cursor: pointer;
        margin-bottom: 1rem;
        border: none;
        gap: 1rem;
        width: fit-content;
        background: inherit;

        & > svg {
          font-size: 3rem;
          color: ${colors.darkBlue};

          &:hover {
            color: #5a5a72;
          }
        }
      }
    }

    & #input-name {
      flex-basis: 49.5%;
    }

    & div[id$="social_name"] {
      flex-basis: 49.5%;
    }

    & #input-marital_status {
      flex-basis: 24.25%;
    }

    & #input-gender {
      flex-basis: 24.25%;
    }

    & #input-birthdate {
      flex-basis: 24.25%;
    }

    & #input-race {
      flex-basis: 24.25%;
    }

    & #input-email {
      flex-basis: ${(100 - 2) / 3}%;
    }

    & div[id$="personal_email"] {
      flex-basis: ${(100 - 2) / 3}%;
    }

    & #input-phone {
      flex-basis: ${(100 - 2) / 3}%;
    }

    & .nationality-span {
      flex-basis: 100%;
      text-align: start;
    }

    & #input-nationality_country {
      flex-basis: ${(100 - 2) / 3}%;
    }

    & #input-nationality_state {
      flex-basis: ${(100 - 2) / 3}%;
    }

    & #input-nationality_city {
      flex-basis: ${(100 - 2) / 3}%;
    }

    & .document-span {
      flex-basis: 100%;
      text-align: start;
    }

    & #input-document_cpf {
      flex-basis: 49.5%;
    }

    & #input-document_cnpj {
      flex-basis: 49.5%;
    }

    & div[id$="street"] {
      flex-basis: 65%;
    }

    & div[id$="number"] {
      flex-basis: 34%;
    }

    & div[id$="complement"] {
      flex-basis: 49.5%;
    }

    & div[id$="neighbourhood"] {
      flex-basis: 49.5%;
    }

    & #input-address_form > div[id$="country_id"] {
      flex-basis: ${(100 - 2) / 3}%;
    }

    & #input-address_form > div[id$="state_id"] {
      flex-basis: ${(100 - 2) / 3}%;
    }

    & #input-address_form > div[id$="city_id"] {
      flex-basis: ${(100 - 2) / 3}%;
    }
  `,
  personal: css`
    display: grid;
    row-gap: 1rem;
    padding: 0 1rem;
  `,
  profiles: css`
    display: grid;
    row-gap: 1rem;
    padding: 0 1rem;

    & .inputRow {
      display: grid;
      grid-template-columns: 40% 40% 10%;
      justify-content: space-between;
    }
  `,
  buttons: css`
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
    font-size: 1.25rem;
    padding: 0 1rem;
    text-align: center;
  `,
  tabStyle: css`
    & .Mui-selected {
      background: #fdfeff;
      border-radius: 1rem 1rem 0 0;
    }
  `,
  accordion: css`
    background: inherit;
    box-shadow: none;
    border-bottom: 1px solid ${colors.darkGrayBlue};
  `,
  accordionSummary: css`
    display: flex;
    align-items: center;
    background: ${colors.grayBlue};

    & .MuiAccordionSummary-content {
      align-items: center;
      justify-content: space-between;
      width: inherit;
    }
  `,
};

interface ProfileAttributesFormProps extends WrappedFieldArrayProps {
  account_preffix: string
  current_account_attribute: AccountFormAttributes;
  accounts_attributes: AccountFormAttributes[];
  initial_company_options: CompanyOption[];
}

const renderProfileAttributesForm = (props: ProfileAttributesFormProps) => {
  const { fields, initial_company_options, current_account_attribute, accounts_attributes, account_preffix } = props;
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state);
  const {
    auth: { profile },
  } = state;
  const [profileOptions, setProfileOptions] = React.useState<DefaultOptionType[]>([]);

  const setProfileOptionsBasedOnCompany = () => {
    if (current_account_attribute.company_id) {
      const company = initial_company_options.find(
        (item) => ~~item.value === ~~current_account_attribute.company_id,
      ) as CompanyOption;
      const companyKind = company.kind;
      let newProfileOptions = companyTypes
        .find((item) => item.value === companyKind)
        ?.profileOptions.filter((item) => {
          const currentProfile = profile.role;
          const profileInstance = profiles.find((x) => x.name === currentProfile);
          return profileInstance?.canCreateProfile.includes(item);
        })
        .map((item) => ({ value: item, label: ensure(profiles.find((x) => x.name === item)?.label) }));
      if (current_account_attribute) {
        const accountForCompany = accounts_attributes.find(
          (accountAttribute) => accountAttribute.company_id === company.value && !accountAttribute._destroy,
        );
        if (accountForCompany && accountForCompany.profiles_attributes) {
          const accountProfiles = accountForCompany.profiles_attributes.map((profile) => ({
            role: profile.role,
            _destroy: profile._destroy,
          }));
          newProfileOptions = newProfileOptions?.filter((profileOption) => {
            const relativeRole = find(accountProfiles, (accPrf) => accPrf.role === profileOption.value);
            const roleDestroyed = relativeRole?._destroy;
            return isNil(relativeRole) || roleDestroyed;
          });
        }
      }
      newProfileOptions && setProfileOptions(newProfileOptions);
    }
  };

  const addProfile = () => {
    fields.push({});
  };

  const deleteProfileMethod = React.useCallback((current_profile_attribute: ProfilesAttributes, profile_attribute: string, index: number ) => {
    current_profile_attribute?.id ? dispatch(
      change(
        'accountForm',`${account_preffix}${profile_attribute}._destroy`, true
      )
    ) : fields.remove(index)

  }, [fields])

  React.useEffect(() => {
    setProfileOptionsBasedOnCompany();
  }, [current_account_attribute.company_id]);
  return (
    <div>
      <div
        css={css`
          display:flex;
          flex-direction: column;
          margin-bottom: 1rem;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 1rem;
          `}
        >
          <span>Adicionar Perfil</span>
          <AddButton
            tooltip={current_account_attribute.company_id ? 'Adicionar perfil' : 'Adicionar unidade antes de adicionar perfil'}
            onClick={addProfile}
            disabled={!current_account_attribute || profileOptions.length === 0}
          />
        </div>
        <span css={css`margin-right: auto;`}>Perfis do usuário na unidade</span>
      </div>
      {fields.map((profile_attribute, index) => {
        const current_profile_attribute = fields.get(index) as ProfilesAttributes
        if (current_profile_attribute._destroy){
          return null
        }
        const formattedProfileOptions = !current_profile_attribute.role ? profileOptions : profileOptions.filter(item => item.value !==  current_profile_attribute.role).concat({value: current_profile_attribute.role, label: profiles.find(item => item.name === current_profile_attribute.role)?.label as string})
        const deleteProfile = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          deleteProfileMethod(current_profile_attribute, profile_attribute, index)
        }
        
        return (
          <FormSection key={profile_attribute} name={profile_attribute}>
            <form>
              <div style={{ display: 'flex' }}>
                <div style={{ flexBasis: '80%' }}>
                  <Field
                    name='role'
                    component={SelectComponent}
                    options={formattedProfileOptions}
                    label={'Perfil'}
                    placeholder={'Perfil'}
                    validate={[validation.required]}
                  />
                </div>
                <div style={{ flexBasis: '20%' }}>
                  <DeleteButton tooltip='Apagar perfil' onClick={deleteProfile}/>
                </div>
              </div>
            </form>
          </FormSection>
        );
      })}
    </div>
  );
};

interface CustomAccountAttributes extends WrappedFieldArrayProps {
  initial_company_options: CompanyOption[];
}

const renderAccountAttributesForm = (props: CustomAccountAttributes) => {
  const { fields, initial_company_options } = props;
  const dispatch = useDispatch();
  const accountsAttributesValue: AccountFormAttributes[] = fields.getAll();

  const deleteAccountMethod = React.useCallback((current_account_attribute: AccountFormAttributes, account_attribute: string, index: number ) => {
    current_account_attribute?.id ? dispatch(
      change(
        'accountForm',`${account_attribute}._destroy`, true
      )
    ) : fields.remove(index)

  }, [fields])

  const addAccount = () => {
    fields.push({
      profiles_attributes: []
    });
  };
  const remaining_company_options = accountsAttributesValue ? initial_company_options.filter(item => !accountsAttributesValue.filter(item => !item._destroy).map(acc => acc.company_id).includes(~~item.value)) : initial_company_options
  return (
    <>
      <div
        data-testid='accounts_attributes'
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar conta em unidade</span>
        <AddButton
          onClick={addAccount}
          disabled={remaining_company_options.length === 0}
        />
      </div>
      {!isEmpty(accountsAttributesValue) && <span>Unidades em que o usuário tem conta</span>}
      {fields.map((account_attribute, index) => {
        const current_account_attribute = fields.get(index) as AccountFormAttributes;
        if(current_account_attribute._destroy){
          return null
        }
        const company_name = initial_company_options.find(
          (item) => ~~item.value === ~~current_account_attribute.company_id,
        )?.label;
        const deleteAccount = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          deleteAccountMethod(current_account_attribute, account_attribute, index)
        }
        const initial = initial_company_options.find(item => ~~item.value === ~~current_account_attribute.company_id) as CompanyOption
        const formatted_remaining_company_options = current_account_attribute.company_id && initial ? remaining_company_options.concat(initial) : remaining_company_options
        return (
          <Accordion key={account_attribute} css={useStyles.accordion}>
            <AccordionSummary css={useStyles.accordionSummary}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <ExpandMore />
                <span>{company_name || 'Selecionar unidade'}</span>
              </div>
              <DeleteButton tooltip='Deletar conta em unidade' onClick={deleteAccount}/>
            </AccordionSummary>
            <AccordionDetails>
              <FormSection name={account_attribute}>
                <form>
                  <Field
                    name='company_id'
                    component={SelectComponent}
                    options={formatted_remaining_company_options}
                    label={'Unidade'}
                    placeholder={'Unidade'}
                    disabled={current_account_attribute.company_id}
                    validate={[validation.required]}
                  />
                  <FieldArray
                    name='profiles_attributes'
                    component={renderProfileAttributesForm}
                    initial_company_options={initial_company_options}
                    current_account_attribute={current_account_attribute}
                    accounts_attributes={accountsAttributesValue}
                    account_preffix={account_attribute}
                    validate={[validation.validProfileAttributes]}
                  />
                </form>
              </FormSection>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

const AccountForm = ({
  onSubmit,
  handleSubmit,
  companyOptions,
  isFormValid,
}: {
  onSubmit: any;
  handleSubmit: (event: React.MouseEvent<HTMLElement>) => any;
  companyOptions: CompanyOption[];
  isFormValid: boolean;
}) => {
  const [tab, setTab] = React.useState('0');
  const handleTabChange = (_: any, newValue: string) => {
    setTab(newValue);
  };
  const userFormData = [
    'avatar',
    'name',
    'additional_data.social_name',
    'marital_status',
    'gender',
    'birthdate',
    'race',
    'email',
    'additional_data.personal_email',
    'phone',
    'nationality_country',
    'nationality_state',
    'nationality_city',
    'document_cpf',
    'document_cnpj',
  ] as UserFormFields[]

  return (
    <form css={useStyles.form}>
      <Tabs css={useStyles.tabStyle} value={tab} onChange={handleTabChange}>
        <Tab label='Geral' value={'0'} />
        <Tab label='Integração' value={'1'} />
      </Tabs>
      <TabPanel style={{ display: 'inherit', rowGap: '1rem', background: 'white' }} value={tab} index={'0'}>
        <div css={UserFormCss}>
          <UserForm
            form_name='accountForm'
            data={userFormData}
          />
        </div>
        <FieldArray
          name='accounts_attributes'
          component={renderAccountAttributesForm}
          initial_company_options={companyOptions}
          validate={[validation.validAccountsAttributes]}
        />
      </TabPanel>
      <TabPanel style={{ display: 'inherit', rowGap: '1rem', background: 'white' }} value={tab} index='1'>
        <Field
          name='zap_sign_user_token'
          label={'Token de usuário - Zap Sign'}
          placeholder={'Token de usuário - Zap Sign'}
          component={InputComponent}
        />
      </TabPanel>

      <div css={useStyles.buttons}>
        <Link to={Paths.USERS_LIST} className='red'>
          <span> Cancelar </span>
        </Link>
        <button disabled={!isFormValid} onClick={handleSubmit(onSubmit)} className='blue'>
          <span> Salvar usuário </span>
        </button>
      </div>
    </form>
  );
};

export default compose<any>(
  reduxForm({
    form: 'accountForm',
  }),
  withRaces,
)(AccountForm);
