import { compose } from 'redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import {
  ClosureReasonEnum,
  closureReasonOptions,
  ClosureStatusEnum,
  closureStatusOptions,
  DefaultOptionType,
  monetaryValueMaskProps,
  RoomStudentRoomStatusEnum,
  roomStudentRoomStatusOptions,
  validation,
} from '../../utils/constants';
import DatePickerComponent from '../input/form/datepicker';
import SelectComponent from '../input/form/select';
import TextAreaComponent from '../input/form/textarea';
import MaskedInputComponent from '../input/form/masked';
import { parseMonetaryValue } from '../../utils/functions';
import CheckboxComponent from '../input/form/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { concat, filter, find, isEmpty, map } from 'lodash';
import React from 'react';

const form_name = 'registrationClosureForm';
const RegistrationClosureForm = ({
  disable_display_refund_registration_fee,
  closureDatePickerProps,
}: {
  disable_display_refund_registration_fee: boolean;
  closureDatePickerProps: {
    max?: Date | undefined;
  };
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const formValues = formValueSelector(form_name);
  const reason = formValues(state, 'reason') as ClosureReasonEnum;
  const status = formValues(state, 'status') as ClosureStatusEnum;
  const room_status = formValues(state, 'room_status') as RoomStudentRoomStatusEnum;
  const current_status_option = find(closureStatusOptions, (cso) => cso.value === status);
  const current_reason_option = find(closureReasonOptions, (cso) => cso.value === reason);
  let reason_options = closureReasonOptions;
  let room_status_options: DefaultOptionType[] = roomStudentRoomStatusOptions;
  if (current_status_option && !isEmpty(current_status_option)) {
    reason_options = filter(closureReasonOptions, (ro) => ro.allowed_for.includes(current_status_option.value));
  }
  if (current_reason_option && !isEmpty(current_reason_option)) {
    room_status_options = filter(roomStudentRoomStatusOptions, (rso) =>
      current_reason_option.allowed_room_statuses?.includes(rso.value),
    );
    if (
      [
        ClosureReasonEnum.RESPONSIBILITY_CHANGE,
        ClosureReasonEnum.PROVISION_MONTHS_CHANGE,
        ClosureReasonEnum.COST_CHANGE,
      ].includes(reason)
    ) {
      room_status_options = concat(room_status_options, [{ label: 'Manter Status', value: 'null' }]);
    }
  }
  const handleStatusChange = (_: React.ChangeEvent<any> | undefined, value: any) => {
    if (value && current_reason_option) {
      if (!current_reason_option.allowed_for.includes(value)) {
        dispatch(change(form_name, 'reason', null));
        dispatch(change(form_name, 'room_status', null));
      }
    }
  };

  const handleReasonChange = (_: React.ChangeEvent<any> | undefined, value: any) => {
    const current_reason_option = find(closureReasonOptions, (cso) => cso.value === value);
    if (
      current_reason_option &&
      [
        ClosureReasonEnum.RESPONSIBILITY_CHANGE,
        ClosureReasonEnum.PROVISION_MONTHS_CHANGE,
        ClosureReasonEnum.COST_CHANGE,
      ].includes(value)
    ) {
      dispatch(change(form_name, 'room_status', 'null'));
      dispatch(change(form_name, 'last_date', null));
    } else if (
      current_reason_option &&
      room_status &&
      !current_reason_option.allowed_room_statuses.includes(room_status)
    ) {
      dispatch(change(form_name, 'room_status', null));
    }
  };
  const formatted_closure_status_options = map(closureStatusOptions, (cso) => {
    if (cso.value === ClosureStatusEnum.FINISHED) {
      return {
        ...cso,
        disabled: true,
      };
    }
    return cso;
  });
  return (
    <form
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <span>Selecione o motivo</span>
      <Field
        name='status'
        small
        options={formatted_closure_status_options}
        onChange={handleStatusChange}
        clearable
        component={SelectComponent}
        required
        validate={[validation.required]}
      />
      <span>Selecione a razão</span>
      <Field
        name='reason'
        small
        options={reason_options}
        clearable
        onChange={handleReasonChange}
        component={SelectComponent}
        required
        disabled={isEmpty(status)}
        validate={[validation.required]}
      />
      <span>Status do Estudante na Sala</span>
      <Field
        name='room_status'
        small
        options={room_status_options}
        clearable
        component={SelectComponent}
        required
        disabled={
          isEmpty(reason) ||
          [
            ClosureReasonEnum.RESPONSIBILITY_CHANGE,
            ClosureReasonEnum.PROVISION_MONTHS_CHANGE,
            ClosureReasonEnum.COST_CHANGE,
          ].includes(reason)
        }
        validate={[validation.required]}
      />
      <label htmlFor='closure_date'>Último Mês de Cobrança</label>
      <Field
        name='closure_date'
        id='closure_date'
        small
        component={DatePickerComponent}
        placeholder={'11/11/2021'}
        required
        validate={[validation.dateRequired]}
        datePickerProps={{
          ...closureDatePickerProps,
          views: ['month', 'year'],
          shouldDisableYear: (year_date: Date) => {
            const year = year_date.getFullYear();
            return year < 2020 || year > new Date().getFullYear() + 2;
          },
        }}
      />
      <label htmlFor='last_date'>Ultimo Dia de Aula</label>
      <Field
        name='last_date'
        id='last_date'
        small
        component={DatePickerComponent}
        placeholder={'11/11/2021'}
        required
        validate={
          ![
            ClosureReasonEnum.RESPONSIBILITY_CHANGE,
            ClosureReasonEnum.PROVISION_MONTHS_CHANGE,
            ClosureReasonEnum.COST_CHANGE,
          ].includes(reason)
            ? []
            : [validation.dateRequired]
        }
        datePickerProps={{
          showDropdownIcon: true,
          shouldDisableYear: (year_date: Date) => {
            const year = year_date.getFullYear();
            return year < 2020 || year > new Date().getFullYear() + 2;
          },
        }}
      />
      <label htmlFor='last_date'>Data da solicitação</label>
      <Field
        name='request_date'
        id='request_date'
        small
        component={DatePickerComponent}
        placeholder={'11/11/2021'}
        required
        validate={[validation.dateRequired]}
        datePickerProps={{
          showDropdownIcon: true,
          shouldDisableYear: (year_date: Date) => {
            const year = year_date.getFullYear();
            return year < 2020 || year > new Date().getFullYear() + 2;
          },
        }}
      />
      <label htmlFor='penalty'>Valor da multa</label>
      <Field
        name='penalty'
        component={MaskedInputComponent}
        label={'Valor'}
        parse={parseMonetaryValue}
        maskProps={monetaryValueMaskProps}
        placeholder={'Insira o valor da multa'}
        validate={[validation.closurePenaltyValidaiton]}
      />
      <label htmlFor='closure_description'>Detalhes</label>
      <Field
        name='details'
        component={TextAreaComponent}
        placeholder='Detalhes do cancelamento'
        id='details'
        validate={[validation.required]}
      />
      <Field
        name='refund_registration_fee'
        disabled={disable_display_refund_registration_fee}
        component={CheckboxComponent}
        placeholder={'Estornar Entrada'}
      />
    </form>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(RegistrationClosureForm);
