/* eslint-disable camelcase */
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { validation } from '../../utils/constants'
import DatePickerComponent from '../input/form/datepicker'
import InputComponent from '../input/form/input'
import { css } from '@emotion/react';

export const useStyles = {
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #fdfefe;
    padding: 1rem;
  `,
};

const RegistrationProductForm = () => {
  return(
      <form css={useStyles.form}>
        <Field
          label='Data de Início'
          name='starts_at'
          id='starts_at'
          small
          component={DatePickerComponent}
          placeholder={'11/11/2021'}
          required
          validate={[validation.dateRequired]}
        />
        <Field
          label='Email extra para notificação de faturas'
          name='notification_email'
          small
          component={InputComponent}
          placeholder='Email extra para notificação de faturas'
          validate={[validation.validEmail]}
        />
      </form>
  )
}

export default compose<any>(
  reduxForm({
      form: 'registrationProductForm',
  }),
)(RegistrationProductForm);
