/* eslint-disable camelcase */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { compose } from 'redux';
import {
  Field,
  FieldArray,
  FormSection,
  WrappedFieldArrayProps,
  change,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import {
  colors,
  SubjectAttributes,
  DefaultOptionType,
  Role,
  validation,
  NestedCurriculumSubjectAttributes,
  LocationState,
} from '../../utils/constants';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import { useDispatch, useSelector } from 'react-redux';
import { evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';
import { RootState } from '../../store/configureStore';
import { css } from '@emotion/react';
import AddButton from '../shared/AddButton';
import { FETCH_SUBJECTS } from '../../store/subjects';
import { error } from 'react-notification-system-redux';
import DeleteButton from '../shared/DeleteButton';
import AutoCompleteComponent from '../input/form/autocomplete';
import { compact, isEmpty } from 'lodash';
import CheckboxComponent from '../input/form/checkbox';

const useStyles = {
  accordion: css`
    background: inherit;
    box-shadow: none;
    border-bottom: 1px solid ${colors.grayBlue};
  `,
  accordionSummary: css`
    display: flex;
    align-items: center;

    & .MuiAccordionSummary-content {
      align-items: center;
      justify-content: space-between;
      width: inherit;
    }
  `,
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #FDFEFF;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

const form_name = 'curriculumForm';

interface CustomCurriculumSubjectProps {
  currentCurriculumSubjectName: string;
  all_curriculum_subjects: NestedCurriculumSubjectAttributes[];
  currentCurriculumSubject: NestedCurriculumSubjectAttributes;
  disabled: boolean
}

const CurriculumSubjectsFormContainer = (props: CustomCurriculumSubjectProps) => {
  const { currentCurriculumSubjectName, all_curriculum_subjects, currentCurriculumSubject, disabled } = props;
  const dispatch = useDispatch();
  const location = useLocation<LocationState>();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company: companyId, profile },
  } = state;
  const formValues = formValueSelector(form_name);
  const [loading, setLoading] = React.useState(false);
  const [subjectOptions, setSubjectOptions] = React.useState<DefaultOptionType[]>([]);
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const companyIdValue = formValues(state, 'company_id') as string;
  const company_id_to_use = location?.state?.company_id
    ? location?.state?.company_id
    : is_above_school_director
    ? companyIdValue
    : companyId;

  React.useEffect(() => {
    if (currentCurriculumSubject.id) {
      setLoading(true);
      setSubjectOptions([
        {
          value: currentCurriculumSubject.subject_id.toString(),
          label: currentCurriculumSubject.subject_name,
        },
      ]);
      setLoading(false);
    }
  }, []);
  const fetchSubjectOptionsMethod = React.useCallback(async (value: string) => {
    try {
      setLoading(true);
      const used_subject_ids = compact(
        all_curriculum_subjects.filter((item) => !item._destroy).map((item) => item.subject_id),
      );
      const subjects = await dispatch(
        FETCH_SUBJECTS.request({
          params: {
            filters: {
              'q[name_cont]': value,
              'q[company_id_eq]': company_id_to_use,
              'q[id_not_in]': used_subject_ids,
            },
          },
        }),
      );
      if (subjects) {
        const {
          data: { data },
        } = subjects;
        const formattedData = data.map(({ attributes, id }) => {
          return {
            label: attributes.name,
            value: id,
          };
        });
        setSubjectOptions(formattedData);
        setLoading(false);
      }
    } catch (e) {
      dispatch(
        error({
          message: 'Erro ao carregar opções de disciplina',
        }),
      );
    }
  }, []);

  return (
    <FormSection name={currentCurriculumSubjectName}>
      <div style={{ width: '100%' }}>
        <Field
          name='subject_id'
          label={'Disciplina'}
          placeholder={'Disciplina'}
          component={AutoCompleteComponent}
          autoCompleteProps={{
            disabled: !isEmpty(currentCurriculumSubject.id) || disabled,
            filterOptions: (x: any) => x,
            loading,
            getValueOnChange: true,
            fetchOptions: fetchSubjectOptionsMethod,
          }}
          options={subjectOptions}
          validate={[validation.required]}
        />
      </div>
    </FormSection>
  );
};

interface CustomRenderCurriculumSubjectProps extends WrappedFieldArrayProps {
  disabled: boolean
}

const renderCurriculumSubjects = (props: CustomRenderCurriculumSubjectProps) => {
  const dispatch = useDispatch();
  const { fields, disabled} = props;
  const all_curriculum_subjects = fields.getAll() as NestedCurriculumSubjectAttributes[];
  const addCurriculumSubject = React.useCallback(() => {
    fields.push({});
  }, [fields]);

  const deleteCurriculumSubject = React.useCallback(
    (currentCurriculumSubject: NestedCurriculumSubjectAttributes, curriculum_subject: string, index: number) => {
      currentCurriculumSubject.id
        ? dispatch(change(form_name, `${curriculum_subject}._destroy`, true))
        : fields.remove(index);
    },
    [fields],
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
          & div[id$='subject_id'] {
            width: 100%;
          }
        `}
      >
        <span>Adicionar discipĺina</span>
        <AddButton disabled={disabled} onClick={addCurriculumSubject} tooltip='Adicionar disciplina' />
      </div>
      {fields.map((curriculum_subject, index) => {
        const currentCurriculumSubject = fields.get(index) as NestedCurriculumSubjectAttributes;
        if (currentCurriculumSubject._destroy) {
          return null;
        }
        return (
          <div key={curriculum_subject} style={{ display: 'flex' }}>
            <CurriculumSubjectsFormContainer
              disabled={disabled}
              all_curriculum_subjects={all_curriculum_subjects}
              currentCurriculumSubject={currentCurriculumSubject}
              currentCurriculumSubjectName={curriculum_subject}
            />
            <DeleteButton
              disabled={disabled}
              tooltip='Remover disciplina'
              onClick={() => deleteCurriculumSubject(currentCurriculumSubject, curriculum_subject, index)}
            />
          </div>
        );
      })}
    </div>
  );
};

const CurriculumsForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  initialValues: SubjectAttributes;
  course_options: DefaultOptionType[];
  setEdit: React.Dispatch<React.SetStateAction<boolean>>
  edit: boolean
}) => {
  const { handleSubmit, onSubmit, initialValues, course_options, setEdit, edit } = props;
  const state = useSelector((state: RootState) => state)
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const formValues = formValueSelector(form_name);
  const companyIdValue = formValues(state, 'company_id') as string;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const mustSelectCompanyId = is_above_school_director && !initialValues?.id;

  return (
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar matriz curricular</span>
      <form css={useStyles.form}>
        {mustSelectCompanyId && (
          <>
            <span style={{ justifySelf: 'self-start' }} className='subtitle-one'>
              {' '}
              Unidade{' '}
            </span>
            <Field
              label={'Unidade'}
              placeholder={'Selecionar unidade'}
              name='company_id'
              component={SelectComponent}
              options={companies_options as DefaultOptionType[]}
              validate={[...(mustSelectCompanyId ? [validation.required] : [])]}
            />
          </>
        )}

        {companyIdValue && (
          <>
            <div style={{ display: 'grid', gridTemplateColumns: '49.5% 49.5%', justifyContent: 'space-between' }}>
              <Field
                name='name'
                component={InputComponent}
                label={'Nome'}
                placeholder={'Insira o nome da matriz curricular'}
                validate={[validation.required]}
                disabled={!edit}
              />
              <Field
                name='code'
                component={InputComponent}
                label={'Código'}
                placeholder={'Insira o código da matriz curricular'}
                validate={[validation.required]}
                disabled={!edit}
              />
              <Field
                label='Ano letivo'
                component={InputComponent}
                name='year'
                onlyNumbers
                placeholder={'Insira o ano letivo da matriz'}
                validate={[validation.required]}
                disabled={!edit}
              />
              <Field
                name='course_id'
                component={SelectComponent}
                options={course_options}
                label={'Curso'}
                placeholder={'Selecione o curso'}
                validate={[validation.required]}
                disabled={!edit}
              />
              <Field
                label='Tempo Hora Aula (minutos)'
                component={InputComponent}
                name='class_time_length'
                onlyNumbers
                placeholder={'Insira o tempo da hora/aula'}
                validate={[validation.required]}
                disabled={!edit}
              />
            </div>
            <Field name='reprovable' component={CheckboxComponent} placeholder={'Reprovável'} />
            <FieldArray disabled={!edit} name='curriculum_subjects_attributes' component={renderCurriculumSubjects} />
            <div css={useStyles.buttons}>
              {edit ? (
                <>
                  <button onClick={(e) => {
                    e.preventDefault()
                    setEdit(false)}
                  } className='red small'>
                    <span> Cancelar </span>
                  </button>
                  <button onClick={handleSubmit(onSubmit)} className='blue small'>
                    <span> Salvar Matriz Curricular </span>
                  </button>
                </>
              ) : (
                <button className='green small' onClick={(e) => {
                  e.preventDefault()
                  setEdit(!edit)}
                }>
                  <span>Editar</span>
                </button>
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(CurriculumsForm);
