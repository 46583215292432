import React from 'react';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  colors,
  DefaultOptionType,
  UpdateRegistrationProductFormAttributes,
  validation,
} from '../../utils/constants';
import { Theme } from '@mui/system';
import { useDispatch } from 'react-redux';
import { Field, change } from 'redux-form';
import { formatToCurrency } from '../../utils/functions';
import { css, useTheme } from '@emotion/react';

const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    table: css`
      background: #fdfeff;
      padding: 1rem;
      border-radius: 1rem;

      & .delete {
        color: ${colors.lightRed};
        cursor: pointer;
        &:hover {
          color: #cc8080;
        }
      }

      & th {
        color: ${colors.darkGrayBlue};
        border-bottom: none;
      }

      & .MuiPaper-root {
        flex-grow: 1;
      }

      & span.empty {
        color: ${colors.lightRed};
      }

      & span.empty:empty:before {
        content: '\\200b';
      }

      & tbody {
        position: relative;
      }

      & td {
        padding: 2px;
        color: ${colors.darkBlue};
        font-size: 1rem;
        border-bottom: 1px solid ${colors.grayBlue};

        ${theme.breakpoints?.down('lg')} {
          font-size: 0.75rem;
        }
      }

      & .contract {
        background: #fdfeff;

        & td:last-of-type,
        th:last-of-type {
          border-left: 1px solid ${colors.grayBlue};
          border-right: 1px solid ${colors.grayBlue};
        }

        & th:last-of-type {
          border-top: 1px solid ${colors.grayBlue};
        }

        & tfoot {
          & td:last-of-type {
            color: ${colors.green};
            padding-right: 0.5rem;
          }

          & td:first-of-type {
            padding-left: 0.5rem;
          }

          & > tr {
            border: 1px solid ${colors.grayBlue};
            border-radius: 0px 0px 8px 8px;
            background: white;

            & > td {
              padding: 0.5rem 0;
              font-weight: bold;
            }
          }
        }
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & td {
          color: ${colors.darkBlue};

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};

const ContractDataTableBody = ({
  serviceRange,
  getValuesForMonths
}: {
  serviceRange: DefaultOptionType[];
  getValuesForMonths: (months: number[]) => {
    final: number;
    financial: number;
    final_financial: number;
    initial: number;
    subsidy: number;
  }
}) => {
  
  return (
    <>
      <Field
        name={`provision_months`}
        validate={[validation.required]}
        component={({ input } : any) => {
          return (
            <TableBody>
              {serviceRange.map((item) => {
                const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                  const arr = [...input.value];
                  if (event.target.checked) {
                    arr.push(item.value);
                  }
                  else {
                    arr.splice(arr.indexOf(item.value), 1);
                  }
                  arr.sort((a,b) => a-b)
                  input.onBlur(arr);
                  return input.onChange(arr);
                };
                
                const selected = input.value.includes(item.value)
                const { final, subsidy, initial } = getValuesForMonths([item.value as number]);
                return (
                  <TableRow key={item.value}>
                    <TableCell
                      align='left'
                      style={{
                        color: !selected ? colors.grayBlue : undefined,
                      }}
                    >
                      {item.label}
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        color: !selected ? colors.grayBlue : undefined,
                      }}
                    >
                      {formatToCurrency(initial)}
                    </TableCell>
                    <TableCell align='center'>
                      <Checkbox
                        name={`${input.name}[${item.value}]`}
                        onChange={handleChange}
                        checked={input.value.includes(item.value)}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        color: !selected ? '#bfe5f7' : colors.blue,
                      }}
                    >
                      {formatToCurrency(subsidy)}
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        color: !selected ? colors.grayBlue : undefined,
                      }}
                    >
                      {formatToCurrency(final)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          );
        }}
      />
    </>
  );
};

const ContractDataTable = (props: {
  serviceRange: DefaultOptionType[];
  current_registration_product: UpdateRegistrationProductFormAttributes
  getValuesForMonths: (months: number[]) => {
    final: number;
    financial: number;
    final_financial: number;
    initial: number;
    subsidy: number;
  }
}) => {
  const { serviceRange,current_registration_product, getValuesForMonths} = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const allMonthsChecked = serviceRange.every((item) => current_registration_product.provision_months.includes(item.value as number));
  const checkAllMonths = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const new_provision_months = e.target.checked ? serviceRange.map(item => item.value) : []
      dispatch(change('registration-productstep','provision_months', new_provision_months))
    },
    [current_registration_product],
  );

  const totals = getValuesForMonths(current_registration_product.provision_months)

  return (
    <>
      <span className='subtitle-one'>Gerenciar Parcelas</span>
      <TableContainer component={Paper}>
        <div css={classes.table}>
          <Table className='contract'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Nome</TableCell>
                <TableCell align='center'>Valor Tabela</TableCell>
                <TableCell align='center'>
                  <span>Prestação de Serviços</span>
                  <Checkbox checked={allMonthsChecked} onChange={checkAllMonths} />
                </TableCell>
                <TableCell align='center'>Desconto Comercial</TableCell>
                <TableCell align='center'>Valor Pagamento</TableCell>
              </TableRow>
            </TableHead>
            <ContractDataTableBody
              serviceRange={serviceRange}
              getValuesForMonths={getValuesForMonths}
            />
            <TableFooter>
              <TableRow>
                <TableCell>Valor Total:</TableCell>
                <TableCell align='center'>{formatToCurrency(totals.initial)}</TableCell>
                <TableCell />
                <TableCell align='center' style={{ color: colors.blue }}>
                  {formatToCurrency(totals.subsidy)}
                </TableCell>
                <TableCell align='center'>{formatToCurrency(totals.final)}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </TableContainer>
    </>
  );
};

export default ContractDataTable;
