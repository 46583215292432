import React from 'react'
import Loading from '../components/loading/Loading'
import Logo from '../assets/logo.svg'
import { css } from '@emotion/react';

export const useStyles = {
  container: css`
    box-sizing: border-box;
    background: #e8e8e8;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
  `,
  childContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    padding: 60px 80px;
    height: 100%;
    position: relative;
    z-index: 5;
  `,
  logo: css`
    width: 300px;
    margin-bottom: 30px;
  `,
};

const SomaLogin = () => {
  return (
    <div css={useStyles.container}>
      <section css={useStyles.childContainer}>
        <img src={Logo} css={useStyles.logo} alt='Logo do versar' />
        <Loading />
      </section>
    </div>
  )
}

export default SomaLogin
