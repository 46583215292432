import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import { SIGN_IN as signIn } from '../store/auth'

import SomaLogin from '../containers/SomaLogin'

import { css } from '@emotion/react';

const useStyles = {
  container: css`
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box;
    background-repeat: repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
    background: #E8E8E8;
  `,
};


export const SomaLoginPage = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const handleDispatch = (value: string) => {
    dispatch(signIn.request({ token: value, history }))
  }
  React.useEffect(() => {
    const queryString = 'token'
    const queryparams = new URLSearchParams(location.search)
    if (queryparams.has(queryString)) {
      const value = queryparams.get(queryString)
      if(value !== null){
        handleDispatch(value)
      }
    }
  }, [])
  return (
    <div css={useStyles.container}>
      <SomaLogin />
    </div>
  )
}

export default SomaLoginPage
