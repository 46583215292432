import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { InvoiceAttributes, InvoiceItemAttributes, NestedWalletCreditAttributes, WalletCreditAttributes, colors } from '../../utils/constants';
import { formatToCurrency, invoiceItemValues, parseText } from '../../utils/functions';
import { Collapse, IconButton, Tooltip } from '@mui/material';
import { Circle, Visibility } from '@mui/icons-material';
import InvoiceItemSummary from '../shared/InvoiceItemSummary';
import { css } from '@emotion/react';

const useStyles = {
  table: css`
    & tbody > tr:nth-child(even) {
      background-color: ${colors.darkGrayBlue} !important;
    }

    & tbody > tr:nth-child(odd) {
      background-color: ${colors.grayBlue} !important;
    }

    & .include {
      display: flex;
      margin-left: auto;
      margin-bottom: 1rem;
    }

    & tfoot {
      background: white;

      & td {
        font-weight: bold;
        font-size: 1rem;
      }

      & td:last-of-type {
        color: ${colors.blue};
      }
    }

    & .MuiTableContainer-root {
      background: inherit;
      box-shadow: none;

      & .MuiTableCell-root {
        border-bottom: 5px solid ${colors.lightBlue};
      }

      & th {
        color: ${colors.darkGrayBlue};
      }

      & td {
        color: ${colors.darkBlue};

        & svg {
          color: ${colors.darkGrayBlue};
          cursor: pointer;
        }
      }

      & td.MuiTableCell-footer {
        border-bottom: none;
      }
    }
  `,
};

const InvoiceItemActions = ({
  invoice_item,
  setExpandedInvoiceItemDescription
}: {
  invoice_item: InvoiceItemAttributes
  setExpandedInvoiceItemDescription: React.Dispatch<React.SetStateAction<number[]>>
}) => {
  return (
    <>
      <Tooltip title='Ver resumo do item da fatura'>
        <IconButton onClick={() => setExpandedInvoiceItemDescription(current => {
              if(current.includes(~~invoice_item.id)){
                return current.filter(item => item !== ~~invoice_item.id)
              } else {
                return current.concat(~~invoice_item.id)
              }
            })}>
          <Visibility />
        </IconButton>
      </Tooltip>
    </>
  );
};

const InvoiceItemsTableBody = ({
  invoices,
  invoice_items,
  wallet_credits
}: {
  invoice_items: InvoiceItemAttributes[];
  invoices: InvoiceAttributes[];
  wallet_credits: (WalletCreditAttributes | NestedWalletCreditAttributes)[]
}) => {
  const [expandedInvoiceItemDescription, setExpandedInvoiceItemDescription] = React.useState<number[]>([])
  return (
    <TableBody>
      {invoice_items.map((invoice_item, index) => {
        const isLast = index === invoice_items.length - 1 
        const isActive = invoice_item.active;
        const invoice = invoices.find((invoice) => ~~invoice.id === invoice_item.invoice_id) as InvoiceAttributes;
        const invoice_invoice_items = invoice_items.filter(invoice_item => invoice_item.invoice_id === ~~invoice.id) as InvoiceItemAttributes[]
        const portionName = invoice_item.portion === 0 ? 'Entrada' : `Parcela ${invoice_item.portion}`;
        const isActiveLabel = isActive ? 'Ativa' : 'Inativa';
        const activeColorStyle = { color: isActive ? colors.green : colors.lightRed };
        const {
          current_charge,
          current_conditional_charge,
          contract_full_billing_percentage,
          contract_conditional_discount_billing_percentage,
        } = invoiceItemValues({invoice_item, invoice, invoice_items, wallet_credits});
        return (
          <React.Fragment key={invoice_item.code}>
            <TableRow>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Circle style={activeColorStyle} />
                  <span style={activeColorStyle}>{isActiveLabel}</span>
                </div>
              </TableCell>
              <TableCell align='center'>
                <Tooltip
                  title={`Este item representa ${contract_full_billing_percentage}% do valor da parcela de contrato`}
                  onClick={() => navigator.clipboard.writeText(contract_full_billing_percentage.toString())}
                >
                  <span>{contract_full_billing_percentage.toFixed(4)}%</span>
                </Tooltip>
              </TableCell>
              <TableCell align='center'>
                <Tooltip
                  title={`Este item representa ${contract_conditional_discount_billing_percentage}% do valor de pontualidade da parcela de contrato`}
                  onClick={() => navigator.clipboard.writeText(contract_conditional_discount_billing_percentage.toString())}
                >
                  <span>{contract_conditional_discount_billing_percentage.toFixed(4)}%</span>
                </Tooltip>
              </TableCell>
              <TableCell align='center'>{invoice_item.code}</TableCell>
              <TableCell align='center'>{portionName}</TableCell>
              <TableCell align='center'>{formatToCurrency(current_charge)}</TableCell>
              <TableCell align='center'>{formatToCurrency(current_conditional_charge)}</TableCell>
              <TableCell align='center'>
                <Tooltip title={invoice_item.description}>
                  <span>{parseText(invoice_item.description, 15)}</span>
                </Tooltip>
              </TableCell>
              <TableCell align='center'>
                <InvoiceItemActions
                  invoice_item={invoice_item}
                  setExpandedInvoiceItemDescription={setExpandedInvoiceItemDescription}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0, ...(isLast && { borderBottom: 0 }) }} colSpan={10}>
                <Collapse in={expandedInvoiceItemDescription.includes(~~invoice_item.id)} timeout='auto' unmountOnExit>
                  <InvoiceItemSummary
                    wallet_credits={wallet_credits}
                    invoice={invoice}
                    invoice_item={invoice_item}
                    invoice_items={invoice_invoice_items}
                  />
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })}
    </TableBody>
  );
};

const InvoiceItemsTable = ({
  invoices,
  invoice_items,
  wallet_credits
}: {
  invoice_items: InvoiceItemAttributes[];
  invoices: InvoiceAttributes[];
  wallet_credits: (WalletCreditAttributes | NestedWalletCreditAttributes)[]
}) => {
  return (
    <Table css={useStyles.table} size='small' aria-label='payment-simulation'>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell align='center'>% Valor parcela-contrato</TableCell>
          <TableCell align='center'>% Pontualidade parcela-contrato</TableCell>
          <TableCell align='center'>Código item da fatura</TableCell>
          <TableCell align='center'>Parcela</TableCell>
          <TableCell align='center'>Cobrança</TableCell>
          <TableCell align='center'>Desconto pontualidade</TableCell>
          <TableCell align='center'>Descrição</TableCell>
          <TableCell align='center'>Ações</TableCell>
        </TableRow>
      </TableHead>
      <InvoiceItemsTableBody
        wallet_credits={wallet_credits}
        invoice_items={invoice_items}
        invoices={invoices}
      />
    </Table>
  );
};

export default InvoiceItemsTable;