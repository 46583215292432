/* eslint-disable camelcase */
import React from 'react';
import { Theme } from '@mui/system';
import { map } from 'lodash';
import {
  colors,
  DefaultOptionType,
  NestedRegistrationProductSubsidyAttributes,
  SubsidiesAttributes,
  UpdateRegistrationProductFormAttributes,
  validation,
} from '../../utils/constants';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { Field, change } from 'redux-form';
import { calculateDiscount, formatToCurrency } from '../../utils/functions';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';

const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    table: css`
      background: #fdfefe;
      padding: 1rem;
      border-radius: 1rem;

      & .delete {
        color: ${colors.lightRed};
        cursor: pointer;
        &:hover {
          color: #cc8080;
        }
      }

      & th {
        color: ${colors.darkGrayBlue};
        border-bottom: none;
      }

      & .MuiPaper-root {
        flex-grow: 1;
      }

      & span.empty {
        color: ${colors.lightRed};
      }

      & span.empty:empty:before {
        content: '\\200b';
      }

      & tbody {
        position: relative;
      }

      & td {
        padding: 2px;
        color: ${colors.darkBlue};
        font-size: 1rem;
        border-bottom: 1px solid ${colors.grayBlue};

        ${theme.breakpoints?.down('lg')} {
          font-size: 0.75rem;
        }
      }

      & tfoot {
        & td:first-of-type {
          padding-left: 0.5rem;
        }

        & > tr {
          border: 1px solid ${colors.grayBlue};
          border-radius: 0px 0px 8px 8px;
          background: white;

          & > td {
            padding: 0.5rem 0;
            font-weight: bold;
          }
        }
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & td {
          color: ${colors.darkBlue};

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};

const RegistrationProductSubsidyFormTableBody = ({
  current_registration_product_subsidy,
  service_range,
  getValuesForMonths,
  subsidy,
  current_registration_product,
}: {
  service_range: DefaultOptionType[];
  current_registration_product_subsidy: NestedRegistrationProductSubsidyAttributes;
  getValuesForMonths: (months: number[], exclude_subsidies?: number[]) => {
    final: number;
    financial: number;
    final_financial: number;
    initial: number;
    subsidy: number;
  }  
  subsidy: SubsidiesAttributes;
  current_registration_product: UpdateRegistrationProductFormAttributes;
}) => {
  return (
    <Field
      name={'portions'}
      validate={[validation.required]}
      component={({ input }: any) => {
        return (
          <TableBody>
            {map(service_range, (item) => {
              const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                const arr = [...input.value];
                if (event.target.checked) {
                  arr.push(item.value);
                } else {
                  arr.splice(arr.indexOf(item.value), 1);
                }
                arr.sort((a, b) => a - b);
                input.onBlur(arr);
                return input.onChange(arr);
              };
              const selected = input.value.includes(item.value);
              const { initial, final: new_final } = getValuesForMonths([item.value as number], [~~subsidy.id]);
              const calculated_subsidy_value = calculateDiscount(
                subsidy.kind,
                current_registration_product_subsidy.discount,
                initial,
              );
              const final = new_final - calculated_subsidy_value
              const disabled = !current_registration_product.provision_months.includes(item.value as number) || (item.value === 0
                  ? !subsidy.include_registration
                  : !subsidy.portions.includes(item.value as number));
              return (
                <TableRow key={item.value}>
                  <TableCell align='left'>{item.label}</TableCell>
                  <TableCell
                    align='center'
                    style={{
                      color: !selected ? '#bfe5f7' : colors.blue,
                    }}
                  >
                    {formatToCurrency(calculated_subsidy_value)}
                  </TableCell>
                  <TableCell align='center'>
                    <Checkbox
                      name={`${input.name}[${item.value}]`}
                      checked={selected}
                      onChange={handleChange}
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      color: !selected ? colors.grayBlue : undefined,
                    }}
                    align='center'
                  >
                    {formatToCurrency(final)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        );
      }}
    />
  );
};

const RegistrationProductSubsidyFormTable = ({
  current_registration_product_subsidy,
  getValuesForMonths,
  subsidy,
  service_range,
  current_registration_product,
  registration_product_subsidy_attribute,
}: {
  current_registration_product_subsidy: NestedRegistrationProductSubsidyAttributes;
  service_range: DefaultOptionType[];
  current_registration_product: UpdateRegistrationProductFormAttributes;
  subsidy: SubsidiesAttributes;
  getValuesForMonths: (months: number[], exclude_subsidies?: number[]) => {
    final: number;
    financial: number;
    final_financial: number;
    initial: number;
    subsidy: number;
  }  
  registration_product_subsidy_attribute: string;
}) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const months_that_can_be_checked = current_registration_product.provision_months.filter((provision_month) => {
    if(!subsidy){
      return false
    }
    if (provision_month === 0) {
      return subsidy.include_registration && subsidy.portions.includes(0);
    }
    return subsidy.portions.includes(provision_month);
  });
  const allMonthsChecked = months_that_can_be_checked.every((item) =>
    current_registration_product_subsidy.portions.includes(item),
  );

  const onCheckingAllMonths = (e: React.ChangeEvent<HTMLInputElement>) => {
    const new_portions = e.target.checked ? months_that_can_be_checked : [];
    dispatch(change('registration-productstep', `${registration_product_subsidy_attribute}.portions`, new_portions));
  };

  const totals = current_registration_product.provision_months.reduce(
    (acc, portion) => {
      const { initial, final: new_final } = getValuesForMonths([portion], [...(subsidy ? [~~subsidy.id] : [])]);
      let calculated_subsidy = 0
      if(current_registration_product_subsidy.portions.includes(portion)){
        calculated_subsidy = calculateDiscount(
          subsidy.kind,
          current_registration_product_subsidy.discount,
          initial,
        );
      }
      const final = new_final - calculated_subsidy;
      return { subsidy: calculated_subsidy + acc.subsidy, final: final + acc.final };
    },
    { subsidy: 0, final: 0 },
  );
  if(!subsidy){
    return null
  }
  return (
    <div css={classes.table}>
      <TableContainer component={Paper}>
        <Table className='preview'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Parcelas</TableCell>
              <TableCell align='center'>Novo Desconto</TableCell>
              <TableCell align='center'>
                <span>Meses com desconto</span>
                <Checkbox checked={allMonthsChecked} onChange={onCheckingAllMonths} />
              </TableCell>
              <TableCell align='center'>Novo valor do contrato</TableCell>
            </TableRow>
          </TableHead>
            <RegistrationProductSubsidyFormTableBody
              current_registration_product={current_registration_product}
              getValuesForMonths={getValuesForMonths}
              subsidy={subsidy}
              service_range={service_range}
              current_registration_product_subsidy={current_registration_product_subsidy}
            />
          <TableFooter>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell align='center' style={{ color: colors.blue }}>
                {formatToCurrency(totals.subsidy)}
              </TableCell>
              <TableCell />
              <TableCell align='center'>{formatToCurrency(totals.final)}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RegistrationProductSubsidyFormTable;
