/* eslint-disable camelcase */
import React from 'react';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';
import {
  colors,
  PaginationType,
  Paths,
  Role,
  defaultPagination,
  SubjectAttributes,
  subjectFieldOptions,
  SubjectFieldEnum,
  subjectKindOptions,
} from '../../../utils/constants';
import TablePaginationActions from '../../shared/TablePaginationActions';
import { useHistory } from 'react-router-dom';
import PenIcon from '../../icon/PenIcon';
import InputComponent from '../../input/form/input';
import SelectComponent from '../../input/form/select';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { IconModal } from '../../modal/Modal';
import TrashIcon from '../../icon/TrashIcon';
import { css, useTheme } from '@emotion/react';

export const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    tableTitle: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      background: ${colors.green};
      padding: 1rem 4rem;
      border: none;
    `,
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
    table: css`
      background: ${colors.lightBlue};
      padding: 2rem;
      border-radius: 4px;
      display: grid;

      & .subtitle {
        text-align: start;
        margin-bottom: 1rem;
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};


const SubjectsView = ({
  subjects,
  pagination,
  handleChangePageSize,
  handlePageChange,
  nameFilterValue,
  setNameFilterValue,
  fetchSubjectsMethod,
  fieldFilterValue,
  setFieldFilterValue,
  companyFilterValue,
  setCompanyFilterValue,
  deleteSubject
}: {
  subjects: SubjectAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fieldFilterValue: SubjectFieldEnum | string;
  setFieldFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fetchSubjectsMethod: ({ newPagination, company_filter, field_filter }: {
    newPagination?: PaginationType;
    company_filter?: string;
    field_filter?: SubjectFieldEnum | string;
}) => Promise<void>
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
  deleteSubject: (id: string, { setLoading, handleClose }: {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleClose: () => void;
  }) => Promise<void>

}) => {
  const classes = useStyles();
  const history = useHistory();

  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const columns = [...(is_above_school_director ? ['Unidade'] : []), 'Nome', 'Área de conhecimento', 'Tipo', ''];

  return (
    <div css={classes.view}>
      <div css={classes.tableTitle}>
        <span className='title'>Disciplinas</span>
        <button className='green small' onClick={() => history.push(Paths.SUBJECTS_FORM)}>
          <span>Criar Disciplina</span>
        </button>
      </div>
      <div css={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div css={classes.filters}>
          {is_above_school_director && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchSubjectsMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchSubjectsMethod({});
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value);
              },
            }}
          />
          <SelectComponent
            placeholder='Área de conhecimento'
            small
            options={subjectFieldOptions}
            input={{
              value: fieldFilterValue,
              onChange: (e: any) => {
                setFieldFilterValue(e.target.value);
                fetchSubjectsMethod({
                  field_filter: e.target.value,
                });
              },
            }}
            clearable
            clearFieldFunction={() => {
              setFieldFilterValue('');
              fetchSubjectsMethod({
                field_filter: '',
              });
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {subjects.map((subject) => {
                const field = subjectFieldOptions.find((item) => item.value === subject.field)?.label;
                const kind = subjectKindOptions.find((item) => item.value === subject.kind)?.label
                return (
                  <TableRow key={subject.id}>
                    {is_above_school_director && <TableCell>{subject.subject_company_name}</TableCell>}
                    <TableCell>{subject.name}</TableCell>
                    <TableCell>{field}</TableCell>
                    <TableCell>{kind}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          history.push(Paths.SUBJECTS_FORM, {
                            subject_id: subject.id,
                            company_id: subject.company_id
                          });
                        }}
                      >
                        <PenIcon />
                      </IconButton>
                      <IconModal
                        icon={TrashIcon}
                        title={'Excluir disciplina'}
                        tooltipText='Excluir disciplina'
                        onConfirm={(props) => deleteSubject(subject.id, props)}
                        message='Tem certeza que deseja remover o disciplina?'
                        iconProps={{style:{color: 'red'}}}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default SubjectsView;
