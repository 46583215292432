/* eslint-disable camelcase */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Theme } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { LoadingBackdrop } from '../../modal/Modal';
import {
  PaginationType,
  colors,
  DefaultOptionType,
  FormattedRegistrationViewRegData,
  Paths,
} from '../../../utils/constants';
import RegistrationsTable from '../../table/RegistrationsTable';
import RegistrationViewFilters from '../../filters/RegistrationViewFilters';
import TabPanel from '../../shared/TabPanel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { evaluate_permissions } from '../../../utils/functions';
import SchoolExportListsComponent from '../../shared/SchoolExportListsComponent';
import { css, useTheme } from '@emotion/react';

export const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    tabStyle: css`
      & .Mui-selected {
        background: #fdfeff;
        border-radius: 1rem 1rem 0 0;
      }
    `,
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    tableHeader: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      background: ${colors.green};
      padding: 1rem 4rem;
      border: none;
    `,
    tableContainer: css`
      background: ${colors.lightBlue};
      padding: 1rem;
      border-radius: 4px;
      display: grid;
    `,
  };
};



const RegistrationsView = ({
  defaultPagination,
  deleteRegistrationMethod,
  fetchRegistrationsMethod,
  filterOption,
  handleChangePageSize,
  handlePageChange,
  handleTabChange,
  pagination,
  registrations,
  renderReady,
  searchForOptions,
  setCompanyFilterValue,
  setFilterOption,
  setStartsAtFilter,
  setStudentFilter,
  startsAtFilter,
  studentFilter,
  tabValue,
  companyFilterValue,
}: {
  companyFilterValue: string;
  defaultPagination: PaginationType;
  deleteRegistrationMethod: (id: string) => Promise<void>;
  fetchRegistrationsMethod: ({
    newPagination,
    status,
    closed,
  }: {
    newPagination?: PaginationType;
    status?: string;
    closed?: boolean;
  }) => Promise<void>;
  filterOption: string;
  handleChangePageSize: (e: any) => void;
  handlePageChange: (_: any, newPage: number) => void;
  handleTabChange: (_: any, newValue: number) => void;
  pagination: PaginationType;
  registrations: FormattedRegistrationViewRegData[];
  renderReady: boolean;
  searchForOptions: DefaultOptionType[];
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setFilterOption: React.Dispatch<React.SetStateAction<string>>;
  setStartsAtFilter: React.Dispatch<React.SetStateAction<Date | null>>;
  setStudentFilter: React.Dispatch<React.SetStateAction<string>>;
  startsAtFilter: Date | null;
  studentFilter: string;
  tabValue: number;
}) => {
  const state = useSelector((state: RootState) => state);
  const {
    auth: { profile, company },
  } = state;

  const classes = useStyles();
  const history = useHistory();
  return (
    <div css={classes.view}>
      <LoadingBackdrop open={!renderReady} />
      <div css={classes.tableHeader}>
        <span className='title'>Lista de Alunos</span>
        <button
          onClick={() =>
            history.push(Paths.REGISTRATIONS_FORM, {
              company_id: evaluate_permissions.is_above_school_director(profile.role)
                ? null
                : evaluate_permissions.is_school_admin(profile.role)
                ? company
                : null,
            })
          }
          className='green small'
        >
          <span>Inserir aluno</span>
        </button>
      </div>
      <Tabs css={classes.tabStyle} value={tabValue} onChange={handleTabChange}>
        <Tab label='Alunos ativos' value={0} />
        <Tab label='Alunos inativos' value={1} />
      </Tabs>
      <div css={classes.tableContainer} data-testid='registrations-table'>
        {[0, 1].map((item) => {
          return (
            <TabPanel style={{ padding: '0.5rem' }} key={item} value={tabValue} index={item}>
              <RegistrationViewFilters
                companyFilterValue={companyFilterValue}
                searchForOptions={searchForOptions}
                filterOption={filterOption}
                setFilterOption={setFilterOption}
                fetchRegistrationsMethod={fetchRegistrationsMethod}
                pagination={pagination}
                defaultPagination={defaultPagination}
                studentFilter={studentFilter}
                setStudentFilter={setStudentFilter}
                startsAtFilter={startsAtFilter}
                setStartsAtFilter={setStartsAtFilter}
                setCompanyFilterValue={setCompanyFilterValue}
              />
              <RegistrationsTable
                registrations={registrations}
                handleChangePageSize={handleChangePageSize}
                handlePageChange={handlePageChange}
                pagination={pagination}
                deleteRegistrationMethod={deleteRegistrationMethod}
              />
            </TabPanel>
          );
        })}
      </div>
      <SchoolExportListsComponent is_registrations_view is_invoices_view={false}/>
    </div>
  );
};

export default RegistrationsView;
