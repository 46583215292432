/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaHistory, FaUserCircle } from 'react-icons/fa';
import { GrDocumentDownload } from 'react-icons/gr';
import { TbFileSettings, TbReplaceFilled } from 'react-icons/tb';
import { colors, RoomStudentAttributes, RoomAttributes, Role, RoomStudentRoomStatusEnum, roomStudentRoomStatusOptions } from '../../utils/constants';
import { format, parseISO } from 'date-fns';
import { filter, find, includes, isEmpty, map, orderBy } from 'lodash';
import TooltipButton from '../shared/TooltipButton';
import { Circle } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { error, success } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import { RELOCATE_ROOM_STUDENT, RelocateRoomStudentFormData, RoomStudentJson } from '../../store/room_students';
import { FETCH_ROOM } from '../../store/rooms';
import PenIcon from '../icon/PenIcon';
import RoomStudentsFormContainer from '../../containers/RoomStudentsFormContainer';
import RoomStudentLogTable from './RoomStudentLogTable';
import LoadingWithLabel from '../shared/LoadingWithLabel';
import { GENERATE_KTWELVE_CURRICULUM_REGISTRATION_REPORT } from '../../store/ktwelve_curriculum_registrations';
import SelectMultipleComponent from '../input/form/selectmultiple';
import RelocateStudentForm from '../form/RelocateStudentForm';
import InsertRoomStudentTable from './InsertRoomStudentTable';
import { RootState } from '../../store/configureStore';
import { DefaultModalInterface } from '../modal/Modal';


const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const AvatarCss = css`
  height: 40px;
  border-radius: 50%;
  color: ${colors.darkBlue};
`

const AvatarElement = (props: {
  avatar_url: string
  student_name: string
}) => {
  const {student_name, avatar_url} = props
  const [imageloaded, setImageLoaded] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  return (
    <>
      <FaUserCircle css={AvatarCss} style={{ display: imageloaded ? 'none' : 'initial', width: '40px' }} />
      {!isEmpty(avatar_url) && (
        <>
        <img css={AvatarCss} onClick={() => setOpenModal(true)} style={{ visibility: imageloaded ? 'initial' : 'hidden', cursor: imageloaded ? 'pointer' : 'default' }} alt={student_name} onLoad={() => setImageLoaded(true)} src={avatar_url} />
        <DefaultModalInterface singleButton onConfirm={() => null} open={openModal} handleClose={() => setOpenModal(false)}>
          <img alt={student_name} src={avatar_url} />
        </DefaultModalInterface>
        </>
      )}
    </>
  )
}


const RelocateStudentComponent = (props: {
  room_student: RoomStudentAttributes;
  setExpandedStudentRelocation: React.Dispatch<React.SetStateAction<number[]>>;
  fetchRoomStudents: (props: { room_student_status_filter?: string[] }) => Promise<void>;
}) => {
  const { room_student, setExpandedStudentRelocation, fetchRoomStudents } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const close_form = () => {
    setExpandedStudentRelocation((current) => current.filter((item) => item !== ~~room_student.id));
  };
  const onSubmit = React.useCallback(
    async (data: RelocateRoomStudentFormData) => {
      try {
        setLoading(true);
        await dispatch(
          RELOCATE_ROOM_STUDENT.request({
            id: room_student?.id as string,
            data,
          }),
        );
        dispatch(
          success({
            title: 'Remanejamento',
            message: 'Aluno remanejado com sucesso.',
          }),
        );
        setLoading(false);
        close_form();
        await fetchRoomStudents({});
      } catch (er) {
        setLoading(false);
        close_form();
        dispatch(
          error({
            message: 'Erro ao remanejar aluno.',
          }),
        );
      }
    },
    [room_student],
  );

  if (loading) {
    return <LoadingWithLabel message='Carregando remanejamento' />;
  }

  return (
    <div>
      <span className='title-one'>Remanejamento de Aluno</span>
      <RelocateStudentForm close_form={close_form} onSubmit={onSubmit} room_student={room_student} />
    </div>
  );
};

const RoomStudentsTable = ({ room }: { room: RoomAttributes }) => {
  const [editRoomStudent, setEditingRoomStudent] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);
  const [room_students, setRoomStudents] = React.useState<RoomStudentAttributes[]>([]);
  const [loading_room_students, setLoadingRoomStudents] = React.useState<
    {
      id: string;
      message: string;
    }[]
  >([]);
  const [expandedStudentLog, setExpandedStudentLog] = React.useState<number[]>([]);
  const [expandedStudentRelocation, setExpandedStudentRelocation] = React.useState<number[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [roomStudentStatusFilter, setRoomStudentStatusFilter] = React.useState<RoomStudentRoomStatusEnum[]>([]);
  const state = useSelector((state: RootState) => state);
  const {
    auth: { profile },
  } = state;
  const dispatch = useDispatch();

  const fetchRoomStudents = React.useCallback(
    async (props: { room_student_status_filter?: string[] }) => {
      const { room_student_status_filter = roomStudentStatusFilter } = props;
      setLoading(true);
      try {
        const response = await dispatch(
          FETCH_ROOM.request({
            id: room.id,
            params: {
              filters: {
                include: 'room_students',
              },
            },
          }),
        );
        const {
          data: { included = [] },
        } = response;
        let room_students_attributes = map(
          included.filter((incl) => incl.type === 'room_students') as RoomStudentJson[],
          (item) => ({
            id: item.id,
            ...item.attributes,
          }),
        );
        if (!isEmpty(room_student_status_filter)) {
          room_students_attributes = room_students_attributes.filter((item) =>{
            return includes(room_student_status_filter, item.room_status)
          });
        }
        setRoomStudents(room_students_attributes);
        setLoading(false);
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao carregar periodos',
          }),
        );
        setLoading(false);
      }
    },
    [room, roomStudentStatusFilter],
  );
  const generateKtwelveCurriculumRegistrationReport = React.useCallback(
    async ({ room_student }: { room_student: RoomStudentAttributes }) => {
      try {
        setLoadingRoomStudents((current) => current.concat({ id: room_student.id, message: 'Atualizando' }));
        const response = await dispatch(
          GENERATE_KTWELVE_CURRICULUM_REGISTRATION_REPORT.request({
            id: room_student.id,
          }),
        );
        const {
          data: { data },
        } = response;
        const new_report_url = data.attributes.report_url;
        setRoomStudents((current) =>
          current.map((item) => {
            if (item.id !== room_student.ktwelve_curriculum_registration_id.toString()) {
              return item;
            }
            return {
              ...room_student,
              report_url: new_report_url,
            };
          }),
        );
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
        link.href = new_report_url;
        link.setAttribute('download', `Boletim-${room_student.student_name}-${new Date().getTime()}.pdf`);
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
        dispatch(
          success({
            title: 'Geração de boletim',
            message: 'Boletim gerado com sucesso!',
            autoDismiss: 3,
          }),
        );
        setLoadingRoomStudents((current) => current.filter((item) => item.id !== room_student.id));
      } catch (err) {
        setLoadingRoomStudents((current) => current.filter((item) => item.id !== room_student.id));
        dispatch(
          error({
            message: 'Erro na geração do boletimm',
          }),
        );
      }
    },
    [setLoadingRoomStudents],
  );

  const init = async () => {
    const allowed_statuses =  [RoomStudentRoomStatusEnum.ENROLLED, RoomStudentRoomStatusEnum.PRE_ENROLLED]
    setRoomStudentStatusFilter(allowed_statuses);
    await fetchRoomStudents({ room_student_status_filter: allowed_statuses });
  };
  const can_add_student = [Role.SUPERUSER, Role.PEDAGOGICAL_ADMIN, Role.SCHOOL_MANAGER, Role.COORDINATOR].includes(profile.role)
  React.useEffect(() => {
    init();
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div style={{ width: '50%', marginBottom: '1rem' }}>
        <SelectMultipleComponent
          placeholder={`Status do Estudante`}
          label={'Status do Estudante'}
          allow_select_all
          options={filter(roomStudentRoomStatusOptions, opt => [RoomStudentRoomStatusEnum.ENROLLED, RoomStudentRoomStatusEnum.PRE_ENROLLED].includes(opt.value))}
          input={{
            value: roomStudentStatusFilter,
            onChange: async (event: any) => {
              const value = event?.target?.value;
              setRoomStudentStatusFilter(value);
              if (!isEmpty(value)) {
                await fetchRoomStudents({ room_student_status_filter: value });
              }
            },
          }}
        />
      </div>
      <Table css={TableCss} size='small' aria-label='subject-periods'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell align='center'>RA</TableCell>
            <TableCell align='center'>Nome</TableCell>
            <TableCell align='center'>Sala</TableCell>
            <TableCell align='center'>Status</TableCell>
            <TableCell align='center'>Início</TableCell>
            <TableCell align='center'>Fim</TableCell>
            <TableCell align='right'>
              {' '}
              <button disabled={editRoomStudent.length > 0 || !can_add_student} onClick={() => setCreate(!create)} className='green small'>
                <span> Adicionar aluno</span>
              </button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9} align='right'>
              <Collapse in={create} timeout='auto' unmountOnExit>
                <InsertRoomStudentTable
                  room={room}
                  onSave={() => fetchRoomStudents({})}
                  close_form={() => setCreate(false)}
                />
              </Collapse>
            </TableCell>
          </TableRow>
          {orderBy(room_students, 'student_name').map((room_student, index) => {
            const loading_item = loading_room_students.find((item) => item.id === room_student.id);
            const isActive = room_student.active;
            const isActiveLabel = isActive ? 'Ativo' : 'Inativo';
            const activeColorStyle = { color: isActive ? colors.green : colors.lightRed };
            const close_editing_form = () => {
              setEditingRoomStudent((current) => current.filter((item) => item !== ~~room_student.id));
            };
            const room_status_label = find(roomStudentRoomStatusOptions, opt => opt.value === room_student.room_status)?.label
            return (
              <React.Fragment key={room_student.id}>
                <TableRow>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Circle style={activeColorStyle} />
                      <span style={activeColorStyle}>{isActiveLabel}</span>
                    </div>
                  </TableCell>
                  <TableCell align='center'><AvatarElement avatar_url={room_student.avatar_url} student_name={room_student.student_name}/></TableCell>
                  <TableCell align='center'><span>{room_student.student_code}</span></TableCell>
                  <TableCell align='center'><span>{room_student.student_name}</span></TableCell>
                  <TableCell align='center'><span>{room_student.room_name}</span></TableCell>
                  <TableCell align='center'><span>{room_status_label}</span></TableCell>
                  <TableCell align='center'><span>{format(parseISO(room_student.starts_at), 'dd-MM-yyyy')}</span></TableCell>
                  <TableCell align='center'><span>{format(parseISO(room_student.ends_at), 'dd-MM-yyyy')}</span></TableCell>
                  <TableCell align='right'>
                    {loading_item ? (
                      <LoadingWithLabel message={loading_item.message} />
                    ) : (
                      <>
                        {room_student.report_url && (
                          <TooltipButton
                            tooltipProps={{
                              title: 'Ver Boletim',
                            }}
                            Icon={GrDocumentDownload}
                            iconButtonProps={{ href: room_student.report_url, target: '_blank', rel: 'noreferrer' }}
                          />
                        )}
                        <TooltipButton
                          tooltipProps={{
                            title: 'Gerar Boletim',
                          }}
                          Icon={TbFileSettings}
                          iconButtonProps={{
                            onClick: () => generateKtwelveCurriculumRegistrationReport({ room_student }),
                          }}
                        />
                        <TooltipButton
                          tooltipProps={{
                            title: expandedStudentLog.includes(~~room_student.id)
                              ? 'Ocultar log de alterações no registro do aluno'
                              : 'Ver log de alterações no registro do aluno',
                          }}
                          Icon={FaHistory}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedStudentLog((current) => {
                                if (current.includes(~~room_student.id)) {
                                  return current.filter((item) => item !== ~~room_student.id);
                                } else {
                                  return current.concat(~~room_student.id);
                                }
                              }),
                          }}
                        />
                        <TooltipButton
                          tooltipProps={{
                            title: editRoomStudent.includes(~~room_student.id) ? 'Cancelar edição' : 'Editar aluno',
                            style: {
                              display: [
                                Role.SUPERUSER,
                                Role.PEDAGOGICAL_ADMIN,
                                Role.SCHOOL_MANAGER,
                                Role.COORDINATOR,
                              ].includes(profile.role)
                                ? 'initial'
                                : 'none',
                            },
                          }}
                          Icon={PenIcon}
                          iconButtonProps={{
                            disabled:
                              create || (editRoomStudent.length > 0 && !editRoomStudent.includes(~~room_student.id)),
                            onClick: () =>
                              setEditingRoomStudent((current) => {
                                if (current.includes(~~room_student.id)) {
                                  return current.filter((item) => item !== ~~room_student.id);
                                } else {
                                  return current.concat(~~room_student.id);
                                }
                              }),
                          }}
                        />{' '}
                        <TooltipButton
                          tooltipProps={{
                            title: expandedStudentRelocation.includes(~~room_student.id)
                              ? 'Ocultar remanejamento do aluno'
                              : 'Remanejar aluno',
                            style: {
                              display: [
                                Role.SUPERUSER,
                                Role.PEDAGOGICAL_ADMIN,
                                Role.SCHOOL_MANAGER,
                                Role.COORDINATOR,
                              ].includes(profile.role)
                                ? 'initial'
                                : 'none',
                            },
                          }}
                          Icon={TbReplaceFilled}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedStudentRelocation((current) => {
                                if (current.includes(~~room_student.id)) {
                                  return current.filter((item) => item !== ~~room_student.id);
                                } else {
                                  return current.concat(~~room_student.id);
                                }
                              }),
                          }}
                        />
                      </>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={editRoomStudent.includes(~~room_student.id)} timeout='auto' unmountOnExit>
                      <RoomStudentsFormContainer
                        room_student_id={~~room_student.id}
                        onSave={() => fetchRoomStudents({})}
                        close_form={close_editing_form}
                        room={room}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={expandedStudentLog.includes(~~room_student.id)} timeout='auto' unmountOnExit>
                      <RoomStudentLogTable room_student_id={room_student.id} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={expandedStudentRelocation.includes(~~room_student.id)} timeout='auto' unmountOnExit>
                      <RelocateStudentComponent
                        fetchRoomStudents={fetchRoomStudents}
                        setExpandedStudentRelocation={setExpandedStudentRelocation}
                        room_student={room_student}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default RoomStudentsTable;
