/* eslint-disable camelcase */
import  React from 'react'
import { compose } from 'redux';
import { Field, FieldArray, FormSection, reduxForm, submit, WrappedFieldArrayProps } from 'redux-form';
import { colors, NestedClassTimeAttributes, NestedClassTimeSubjectAttributes, validation } from '../../utils/constants';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { compact } from 'lodash';
import { format } from 'date-fns';
import EditorComponent from '../input/form/editor';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';

const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #fdfeff;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};


const AccordionCss = css`
  background: inherit;
  box-shadow: none;
  border-bottom: 1px solid ${colors.grayBlue}
`
const AccordionSummaryCss = css`
  display: flex;
  align-items: center;
  & .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    width: inherit;
  }
`

const form_name = 'classPlanForm';

const renderClassTimeSubjects = (props: WrappedFieldArrayProps) => {
  const { fields } = props;
  const dispatch = useDispatch()
  const allClassTimeSubjects = fields.getAll() as NestedClassTimeSubjectAttributes[]


  return (
    <div>
      {fields.map((class_time_subject, index) => {
        const currentClassTimeSubject = allClassTimeSubjects[index] as NestedClassTimeSubjectAttributes;
        if (currentClassTimeSubject._destroy) {
          return null;
        }
        return (
          <FormSection key={class_time_subject} name={class_time_subject}>
            <span className='subtitle-two'>{currentClassTimeSubject.subject_name}</span>
            <Field onBlur={() => {dispatch(submit(form_name))}} name='class_plan' component={EditorComponent} validate={[validation.required]} />
          </FormSection>
        )
      })}
    </div>
  );
};



const renderClassTimes = (props: WrappedFieldArrayProps) => {
  const { fields,  } = props;
  const allClassTimes = fields.getAll() as NestedClassTimeAttributes[]
  return (
    <div>
      {fields.map((class_time, index) => {
        const currentClassTime = allClassTimes[index] as NestedClassTimeAttributes;

        return (
          <Accordion TransitionProps={{ unmountOnExit: true }} key={class_time} css={AccordionCss}>
            <AccordionSummary css={AccordionSummaryCss}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <ExpandMore />
                <span>{`Plano de aula do horário: ${compact([currentClassTime.starts_at, currentClassTime.ends_at]).map(item => {
                  return format(new Date(item), 'H:mm')}).join('-')}`}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div key={class_time}>
                <FormSection name={class_time}>
                  <FieldArray
                    name='class_time_subjects_attributes'
                    component={renderClassTimeSubjects}
                  />
                </FormSection>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

const ClassPlanForm = () => {
  return (
    <div css={useStyles.view}>
      <form css={useStyles.form}>
        <FieldArray
          name='class_times_attributes'
          component={renderClassTimes}
        />
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(ClassPlanForm);
