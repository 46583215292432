/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PiStudent, PiExam } from 'react-icons/pi';
import { CiViewTable, CiBoxList } from 'react-icons/ci';
import { RiCalendarScheduleLine } from 'react-icons/ri';
import { Page, Text, Document, pdf, StyleSheet, Image, View } from '@react-pdf/renderer';
import {
  colors,
  RoomAttributes,
  Role,
  PaginationType,
  defaultPagination,
  ExamPlacementAttributes,
  FormulaAttributes,
  SubjectPeriodRegistrationAttributes,
  ExamAttributes,
  RegistrationResultAttributes,
  SubjectPeriodAttributes,
  CompanyAttributes,
} from '../../utils/constants';
import TooltipButton from '../shared/TooltipButton';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, TableFooter, TablePagination } from '@mui/material';
import RoomsFormContainer from '../../containers/RoomsFormContainer';
import PenIcon from '../icon/PenIcon';
import { evaluate_permissions, getCompanyFilterOptions, parseText } from '../../utils/functions';
import { RootState } from '../../store/configureStore';
import TablePaginationActions from '../shared/TablePaginationActions';
import SelectComponent from '../input/form/select';
import InputComponent from '../input/form/input';
import RoomStudentsTable from './RoomStudentsTable';
import ClassTimeTable from './ClassTimeDisplayTable';
import RoomSchedulesTable from './RoomSchedulesTable';
import StudentResultsTable from './StudentsResultTable';
import { FETCH_ROOM } from '../../store/rooms';
import { RoomStudentJson } from '../../store/room_students';
import { compact, filter, find, map } from 'lodash';
import { format } from 'date-fns';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const FilterCss = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  & > span {
    margin-bottom: 14px;
  }
  & .inputArea {
    width: 10rem !important;
    padding-right: 3rem;
  }
`;

const ViewCss = css`
  width: inherit;
  min-height: 100%;
  height: fit-content;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  & .MuiBackdrop-root {
    position: inherit;
  };
  & .MuiPaper-root {
    background: none;
    box-shadow: none;
    overflow: hidden;
  },
`;

export interface SubjectRegistrationPeriodData extends SubjectPeriodRegistrationAttributes {
  exams: ExamAttributes[];
  registration_results: RegistrationResultAttributes[];
}

export interface SubjectPeriodData extends SubjectPeriodAttributes {
  subject_period_registrations: SubjectRegistrationPeriodData[];
  formulas: FormulaAttributes[];
  exam_placements: ExamPlacementAttributes[];
}
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 16,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  title: {
    fontSize: '24px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  student_name: {
    fontSize: 8,
  },
  sub_header: {
    fontSize: 12,
  },
  bottom_line: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  footer_text: {
    borderTopColor: 'black',
    borderTopWidth: 1,
    fontSize: 10
  },
  list_headers: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    fontSize: 12
  },
});

const RoomsTable = ({
  rooms,
  pagination,
  handleChangePageSize,
  handlePageChange,
  fetchRoomsMethod,
  companyFilterValue,
  setCompanyFilterValue,
  nameFilterValue,
  setNameFilterValue,
  setProductFilterValue,
  productFilterValue,
}: {
  rooms: RoomAttributes[];
  pagination: PaginationType;
  handlePageChange?: (_: any, newPage: number) => void;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setProductFilterValue: React.Dispatch<React.SetStateAction<string>>;
  handleChangePageSize?: (e: any) => void;
  fetchRoomsMethod: ({
    newPagination,
    company_filter,
    product_filter,
  }: {
    newPagination?: PaginationType;
    company_filter?: string;
    product_filter?: string;
  }) => Promise<void>;
  companyFilterValue: string;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  productFilterValue: string;
}) => {
  const [expandedRoomAssets, setExpandedRoomStudents] = React.useState<number[]>([]);
  const [expandedClassTimes, setExpandedClassTimes] = React.useState<number[]>([]);
  const [expandedRoomSchedules, setExpandedRoomSchedules] = React.useState<number[]>([]);
  const [expandedStudentResults, setExpandedStudentResults] = React.useState<number[]>([]);

  const [editRoom, setEditingRoom] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);
  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });

  async function generateProtocolPdf(room_id: string) {
    try {
      const response = await dispatch(
        FETCH_ROOM.request({
          id: room_id,
          params: {
            filters: {
              include: 'room_students',
            },
          },
        }),
      );
      const {
        data: {
          included = [],
          data: {
            attributes: { company_id, name, ktwelve_degree_name, course_name, company_name, product_year },
          },
        },
      } = response;
      const current_company = find(
        companies,
        (co: CompanyAttributes) => co.id.toString() === company_id.toString(),
      ) as CompanyAttributes;
      const room_students_attributes = filter(
        map(included.filter((incl) => incl.type === 'room_students') as RoomStudentJson[], (item) => ({
          id: item.id,
          ...item.attributes,
        })),
        (rs) => rs.active,
      );
      const doc = (
        <Document>
          <Page size={'A4'} style={styles.page}>
            <View style={styles.header}>
              <Image src={current_company.logo_url} style={styles.logo} />
              <Text style={styles.title}>{company_name}</Text>
            </View>
            <Text style={styles.sub_header}>{compact(
              [
              ...(name ? [compact(["Sala",name]).join(': ')]:[]),
              ...(ktwelve_degree_name ? [compact(["Série",ktwelve_degree_name]).join(': ')]:[]),
            ]).join(' | ')}</Text>
            <Text style={{...styles.sub_header, ...styles.bottom_line}}>{compact(
              [
              ...(course_name ? [compact(["Curso",course_name]).join(': ')]:[]),
              ...(product_year ? [compact(["Ano",product_year]).join(': ')]:[]),
            ]).join(' | ')}</Text>
            <View style={styles.list_headers}>
              <Text style={{ width: '10%' }}>Diário</Text>
              <Text style={{ width: '15%', justifyContent: 'center', alignItems: 'center' }}>RA</Text>
              <Text style={{ width: '40%', justifyContent: 'center', alignItems: 'center' }}>Nome</Text>
              <Text style={{ width: '10%', justifyContent: 'center', alignItems: 'center' }}>Data</Text>
              <Text style={{ flexGrow: 1, justifyContent: 'flex-end', alignItems: 'flex-end', textAlign: 'right' }}>Assinatura</Text>
            </View>
            {room_students_attributes.map((item, index) => (
              <View style={{...styles.bottom_line, flexDirection: 'row', justifyContent: 'space-between', fontSize: 8}} key={item.id}>
                <Text style={{ width: '10%'}}>{index + 1}</Text>
                <Text style={{ width: '15%', justifyContent: 'center', alignItems: 'center' }}>{item.student_code}</Text>
                <Text style={{ width: '40%', justifyContent: 'center', alignItems: 'center' }}>{parseText(item.student_name, 35)}</Text>
                <Text style={{ width: '10%', justifyContent: 'center', alignItems: 'center' }}>{"   /   /   "}</Text>
                <Text style={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>{" "}</Text>
              </View>
            ))}
            <Text style={styles.footer_text} fixed>Emitido em {format(new Date(), 'dd-MM-yyyy H:mm')}</Text>
          </Page>
        </Document>
      );
      
      const asPdf = pdf(doc); // {} is important, throws without an argument
      asPdf.updateContainer(doc);
      const pdfBlob = await asPdf.toBlob();
      const url = URL.createObjectURL(pdfBlob);
      const aTag = document.createElement('a');
      aTag.href = url;
      aTag.download = 'PROTOCOLO.pdf';
      document.body.appendChild(aTag);
      aTag.click();
    } catch (error) {
      console.error(error);
      alert('Error generating PDF');
    }
  }

  return (
    <div css={ViewCss}>
      <span className='title'>Salas</span>
      <div css={FilterCss}>
        {is_above_school_director && (
          <SelectComponent
            placeholder='Selecionar Unidade'
            small
            options={companies_options}
            input={{
              value: companyFilterValue,
              onChange: (e: any) => {
                setCompanyFilterValue(e.target.value);
                fetchRoomsMethod({
                  newPagination: { ...defaultPagination },
                  company_filter: e.target.value,
                });
              },
            }}
          />
        )}
        <InputComponent
          placeholder={`Buscar por nome`}
          search
          small
          onSearch={() => {
            fetchRoomsMethod({});
          }}
          input={{
            value: nameFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setNameFilterValue(e.target.value);
            },
          }}
        />
        <InputComponent
          placeholder={`Buscar pelo produto`}
          search
          small
          onSearch={() => {
            fetchRoomsMethod({});
          }}
          input={{
            value: productFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setProductFilterValue(e.target.value);
            },
          }}
        />
      </div>
      <Table css={TableCss} size='small' aria-label='ktwelve_subjects'>
        <TableHead>
          <TableRow>
            {is_above_school_director && <TableCell align='center'>Unidade</TableCell>}
            <TableCell align='center'>Ano Letivo</TableCell>
            <TableCell align='center'>Nome</TableCell>
            <TableCell align='center'>Produto</TableCell>
            <TableCell align='center'>Série</TableCell>
            {[Role.SUPERUSER, Role.PEDAGOGICAL_ADMIN, Role.SCHOOL_MANAGER, Role.COORDINATOR].includes(profile.role) && (
              <TableCell align='right'>
                {' '}
                <button disabled={editRoom.length > 0} onClick={() => setCreate(!create)} className='green small'>
                  <span> Adicionar Sala </span>
                </button>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} align='right'>
              <Collapse in={create} timeout='auto' unmountOnExit>
                <RoomsFormContainer
                  onSave={async () => {
                    await fetchRoomsMethod({ newPagination: defaultPagination });
                    setCreate(false);
                  }}
                  close_form={() => setCreate(false)}
                />
              </Collapse>
            </TableCell>
          </TableRow>
          {rooms.map((room) => {
            const close_editing_form = () => {
              setEditingRoom((current) => current.filter((item) => item !== ~~room.id));
            };
            return (
              <React.Fragment key={room.id}>
                <TableRow>
                  {is_above_school_director && <TableCell align='center'>{room.company_name}</TableCell>}
                  <TableCell align='center'>{room.product_year}</TableCell>
                  <TableCell align='center'>{room.name}</TableCell>
                  <TableCell align='center'>{room.product_name}</TableCell>
                  <TableCell align='center'>{room.ktwelve_degree_name}</TableCell>
                  <TableCell align='right'>
                    <TooltipButton
                      tooltipProps={{
                        title: 'Gerar protocolo',
                        style: {
                          display: [
                            Role.SUPERUSER,
                            Role.PEDAGOGICAL_ADMIN,
                            Role.SCHOOL_MANAGER,
                            Role.COORDINATOR,
                          ].includes(profile.role)
                            ? 'initial'
                            : 'none',
                        },
                      }}
                      Icon={CiBoxList}
                      iconButtonProps={{
                        onClick: () => generateProtocolPdf(room.id),
                      }}
                    />
                    <TooltipButton
                      tooltipProps={{
                        title: editRoom.includes(~~room.id) ? 'Cancelar edição' : 'Editar Sala',
                        style: {
                          display: [
                            Role.SUPERUSER,
                            Role.PEDAGOGICAL_ADMIN,
                            Role.SCHOOL_MANAGER,
                            Role.COORDINATOR,
                          ].includes(profile.role)
                            ? 'initial'
                            : 'none',
                        },
                      }}
                      Icon={PenIcon}
                      iconButtonProps={{
                        disabled: create || (editRoom.length > 0 && !editRoom.includes(~~room.id)),
                        onClick: () =>
                          setEditingRoom((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />{' '}
                    <TooltipButton
                      tooltipProps={{
                        title: expandedRoomAssets.includes(~~room.id) ? 'Ocultar alunos' : 'Ver alunos',
                      }}
                      Icon={PiStudent}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedRoomStudents((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />{' '}
                    <TooltipButton
                      tooltipProps={{
                        title: expandedClassTimes.includes(~~room.id) ? 'Ocultar horários' : 'Ver horários',
                      }}
                      Icon={CiViewTable}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedClassTimes((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />{' '}
                    <TooltipButton
                      tooltipProps={{
                        title: expandedRoomSchedules.includes(~~room.id)
                          ? 'Ocultar agendamentos de horário'
                          : 'Ver agendamentos de horário',
                        style: {
                          display: [
                            Role.SUPERUSER,
                            Role.PEDAGOGICAL_ADMIN,
                            Role.SCHOOL_MANAGER,
                            Role.COORDINATOR,
                          ].includes(profile.role)
                            ? 'initial'
                            : 'none',
                        },
                      }}
                      Icon={RiCalendarScheduleLine}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedRoomSchedules((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />
                    <TooltipButton
                      tooltipProps={{
                        title: expandedRoomSchedules.includes(~~room.id)
                          ? 'Ocultar notas e frequência'
                          : 'Ver notas e frequência',
                      }}
                      Icon={PiExam}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedStudentResults((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={editRoom.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <RoomsFormContainer
                        onSave={async () => {
                          close_editing_form();
                          await fetchRoomsMethod({ newPagination: defaultPagination });
                        }}
                        close_form={close_editing_form}
                        room_id={~~room.id}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedRoomAssets.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <RoomStudentsTable room={room} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedClassTimes.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <ClassTimeTable room_id={room.id} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedRoomSchedules.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <RoomSchedulesTable room={room} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedStudentResults.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <StudentResultsTable room={room} />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
        {pagination && handlePageChange && handleChangePageSize && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                colSpan={8}
                count={pagination.totalCount}
                rowsPerPage={pagination.pageSize}
                page={pagination.pageNumber}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangePageSize}
                labelRowsPerPage='Itens Por página'
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                }
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </div>
  );
};

export default RoomsTable;
