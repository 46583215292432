import React from 'react'

import CompanyFormComponent from '../form/CompanyForm'
import { CompanyFormData, companyTypes } from '../../utils/constants'
import { css } from '@emotion/react';

interface DefaultOptionType {
  label: string | number,
  value: string | number
}



export const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    display: flex;
    flex-grow: 1;
    padding: 2rem 15%;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
  `,
};


const getTitle = (initialValues: CompanyFormData, kind: string) => {
  if(initialValues.id){
    return `Editar companhia ${initialValues.name}`
  } else {
    const companyKindInfo = companyTypes.find((type) => type.value === kind)
    return `Criar uma companhia ${companyKindInfo && `tipo ${companyKindInfo?.label?.toLowerCase()}`}`
  }
}

const CreateCompanyView = (props : {
    kind: string,
    onSubmit: (arg: any) => void,
    companyOptions: DefaultOptionType[],
    initialValues: CompanyFormData
  }) => {
  const { kind, initialValues } = props
  return (
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>
       {getTitle(initialValues, kind)}
      </span>
      <CompanyFormComponent {...props} />
    </div>
  )
}

export default CreateCompanyView
