/* eslint-disable camelcase */
/* eslint-disable standard/computed-property-even-spacing */
import axios from 'axios'
import { AppDispatch } from '../store/configureStore'
import { AnyAction } from '@reduxjs/toolkit'
import { differenceInDays, endOfDay, format, getMonth, isAfter, isBefore, isSameDay, parse, startOfDay } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Company, CompanyAttributes, CompanyOption, BillingAttributes, DefaultOptionType, DiscountTypes, emailRegex, InvoiceAttributes, InvoiceChargeStatusEnum, InvoiceChargeStatusTypes, InvoiceFormAttributes, InvoiceItemAttributes, InvoiceRenegotiationFormAttributes, InvoiceStatusEnum, InvoiceStatusTypes, MenuOptionsType, monetaryValueMaskProps, NestedInvoiceItemAttributes, NestedWalletCreditAttributes, percentageMaskProps, RequestParams, Role, WalletCreditAttributes, PaymentOptionAttributes, PaymentCollectionAttributes, PaymentSimulationBillings, PaymentOptionDateKindEnum, PaymentSimulationBillingKindEnum, PaymentOptionPortionsEnum, InvoicePaymentOptionEnum, OperationKindEnum, FormulaFormData, FormulaSampleData } from './constants'
import { CompanyJson } from '../store/companies'
import { SetProfileType } from '../store/auth'
import { compact, filter, find, get, head, includes, isEmpty, isEqual, isNaN, isNil, map, sum, toNumber, uniq, uniqBy } from 'lodash'
import { InvoiceItemBaseValues } from '../store/invoice_items'
import { getMonthAndYearFromBilling } from './paymentSimulation'

export const evaluate_permissions = {
  can_download_contract: (role: Role) => {
    return [Role.SUPERUSER].includes(role)
  },
  is_school_admin_or_above: (role: Role) => {
    return [Role.SUPERUSER, Role.SCHOOL_ADMIN, Role.FUND_ADMIN, Role.HUB_ADMIN, Role.GROUP_ADMIN, Role.SCHOOL_MANAGER].includes(role)
  },
  is_above_school_director: (role: Role) => {
    return [Role.SUPERUSER, Role.FUND_ADMIN, Role.HUB_ADMIN, Role.GROUP_ADMIN].includes(role)
  },
  is_school_director_or_above: (role: Role) => {
    return [Role.SUPERUSER, Role.FUND_ADMIN, Role.HUB_ADMIN, Role.GROUP_ADMIN, Role.SCHOOL_MANAGER].includes(role)
  },
  is_above_group_admin: (role: Role) => {
    return [Role.SUPERUSER, Role.FUND_ADMIN, Role.HUB_ADMIN].includes(role)
  },
  is_school_admin: (role: Role) => [Role.SCHOOL_ADMIN, Role.SCHOOL_MANAGER].includes(role),
  is_school_staff: (role: Role) => [Role.SCHOOL_ADMIN, Role.SCHOOL_SECRETARY, Role.SCHOOL_MANAGER].includes(role),
  can_adjust_invoice: (props:
    {
      role: Role,
      invoice_status: InvoiceStatusEnum,
      invoice_created_at: string,
      invoice_expiration_date: string,
      days_config?: number
    }
  ) => {
    const { role, invoice_created_at, invoice_expiration_date, invoice_status, days_config } = props
    const currentDate = endOfDay(new Date());
    const expirationDate = endOfDay(invoice_expiration_date ? new Date(invoice_expiration_date) : new Date())
    const maxDateConfig = startOfDay(new Date(invoice_created_at));
    if (days_config) {
      maxDateConfig.setDate(maxDateConfig.getDate() + days_config)
    }
    const isBeforeConfig = isBefore(currentDate, maxDateConfig) || isSameDay(currentDate, maxDateConfig)
    const isBeforeExpiration = isBefore(currentDate, expirationDate) || isSameDay(currentDate, expirationDate)
    const allowedStatusToAdjust = ![InvoiceStatusEnum.FINISHED, InvoiceStatusEnum.CANCELED, InvoiceStatusEnum.RENEGOTIATED].includes(invoice_status as InvoiceStatusEnum)
    const schoolStaffCanAdjustInvoice = evaluate_permissions.is_school_staff(role) && isBeforeConfig
    const topAdminCanAdjustInvoice = evaluate_permissions.is_above_school_director(role)
    return (schoolStaffCanAdjustInvoice || topAdminCanAdjustInvoice) && allowedStatusToAdjust && isBeforeExpiration
  },
  can_adjust_invoice_dates: (days_config: number, invoice_created_at: string, status: InvoiceStatusEnum) => {
    const currentDate = endOfDay(new Date());
    const maxDateConfig = startOfDay(new Date(invoice_created_at));
    if (days_config) {
      maxDateConfig.setDate(maxDateConfig.getDate() + days_config)
    }
    const isBeforeConfig = isBefore(currentDate, maxDateConfig)
    const is_same_day = isSameDay(currentDate, maxDateConfig)

    return status === InvoiceStatusEnum.TO_PAYMENT_SERVICE && (isBeforeConfig || is_same_day)
  },
  can_add_credits_to_invoice: (role: Role, invoice_expiration_date: string) => {
    if ([Role.SCHOOL_ADMIN, Role.SCHOOL_SECRETARY, Role.SCHOOL_MANAGER].includes(role)) {
      return false
    }
    const currentDate = endOfDay(new Date());
    const expirationDate = endOfDay(invoice_expiration_date ? new Date(invoice_expiration_date) : new Date())
    const isBeforeExpiration = isBefore(currentDate, expirationDate) || isSameDay(currentDate, expirationDate)
    return isBeforeExpiration
  },
  can_unlink_invoice: (role: Role, status: InvoiceStatusTypes, charge_status: InvoiceChargeStatusTypes) => {
    return role === Role.SUPERUSER &&
      !(status === InvoiceStatusEnum.TO_PAYMENT_SERVICE && charge_status === InvoiceChargeStatusEnum.NOT_CHARGEABLE) &&
      !(status === InvoiceStatusEnum.RENEGOTIATED && charge_status === InvoiceChargeStatusEnum.NOT_CHARGEABLE) &&
      !(status === InvoiceStatusEnum.FINISHED && charge_status === InvoiceChargeStatusEnum.REFUNDED)
  }
}


export const checkResourceActivity = (starts_at: string | Date, ends_at: string | Date, period_ends_at: string | Date, date_to_check = new Date()) => {
  let is_after_start_date = true
  if (starts_at) {
    const active_starts_at_date = new Date(starts_at)
    is_after_start_date = isSameDay(active_starts_at_date, date_to_check) || isAfter(date_to_check, active_starts_at_date)
  }
  const active_ends_at_date = ends_at ? new Date(ends_at) : new Date(period_ends_at)
  const is_before_end_date = isSameDay(active_ends_at_date, date_to_check) || isBefore(date_to_check, active_ends_at_date)
  return is_after_start_date && is_before_end_date
}

/* eslint-disable no-useless-escape */
export const removeSpecialSymbols = (value: string) => {
  return value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/\D/g, '')
}

export const newShade = (hexColor: string, magnitude: number) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};

export function isIsoDate(str: string) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str)
  return d instanceof Date && !isNaN(d) && d.toISOString() === str; // valid date 
}

export const compactSum = (num: number[]) => {
  return sum(compact(num))
}

export const convertToDate = (date: string | Date | undefined) => {
  return typeof date === 'string' ? new Date(date) : date instanceof Date ? date : undefined
}

export function generateCharOnlyId(len: number) {
  const charBank = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ012346789";

  let random = '';
  for (let i = 0; i < len; i++) {
    random += charBank[parseInt((Math.random() * charBank.length).toString())];
  }

  return random;
}
export function generateId(len: number) {
  function dec2hex(dec: number) {
    return dec.toString(16).padStart(2, '0');
  }
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}


export function toFixedNumber(num: number, digits = 2, base?: number) {
  const pow = Math.pow(base || 10, digits);
  return Math.round(num * pow) / pow;
}

export const invoiceIsExpired = (invoice: InvoiceAttributes) => {
  const use_expiration_date = convertToDate(invoice.expiration_date)
  const parsed_paid_at = invoice.paid_at && parse(invoice.paid_at, 'yyyy-LL-dd', new Date(), { locale: ptBR })
  const date_to_calculate_fees = parsed_paid_at || new Date()
  const daysDifference = toNumber(use_expiration_date && differenceInDays(date_to_calculate_fees, new Date(use_expiration_date)));
  return Number((daysDifference > 0));
}


export const checkPaymentOptionAndCollections = (props: {
  payment_option: PaymentOptionAttributes;
  payment_collections: PaymentCollectionAttributes[];
  payment_simulation_billings: PaymentSimulationBillings[];
}) => {
  const { payment_simulation_billings, payment_collections, payment_option } = props;
  if (isEmpty(payment_simulation_billings) || isEmpty(payment_option) || isEmpty(payment_option.date_kind)) {
    return false;
  } else if (payment_option.date_kind === PaymentOptionDateKindEnum.DAYS_AFTER_BILLING) {
    return payment_option.days_after_billing && payment_option.days_after_billing > 0;
  } else if (!payment_option.days_after_billing || payment_option.days_after_billing < 1) {
    return false;
  } else if (payment_option.date_kind === PaymentOptionDateKindEnum.FIXED_DATE) {
    if (payment_option.portions === PaymentOptionPortionsEnum.MONTHLY) {
      const result = payment_simulation_billings.every((billing) => {
        if (billing.kind === PaymentSimulationBillingKindEnum.REGISTRATION_FEE) {
          return payment_collections.find((payment_collection) => payment_collection.number === 0);
        }
        const { month } = getMonthAndYearFromBilling(billing);
        const result = payment_collections.find((payment_collections) => payment_collections.number === month);
        return result;
      });
      return result;
    } else if ([PaymentOptionPortionsEnum.SEMI_ANUALY, PaymentOptionPortionsEnum.YEARLY].includes(payment_option.portions)) {
      const hasRegistrationFee = payment_simulation_billings.some(
        (billing) => billing.kind === PaymentSimulationBillingKindEnum.REGISTRATION_FEE,
      );
      let registration_fee_check = true;
      if (hasRegistrationFee) {
        registration_fee_check = payment_collections.some((collection) => collection.number === 0);
      }
      const collections_without_reg_fee = payment_collections.filter((collection) => collection.kind !== 'advance');
      return registration_fee_check && collections_without_reg_fee.length > 0;
    }
  }
};


export const invoiceItemValues = (args: {
  invoice_item: NestedInvoiceItemAttributes | InvoiceItemAttributes,
  invoice: InvoiceFormAttributes | InvoiceAttributes,
  invoice_items: (InvoiceItemAttributes | NestedInvoiceItemAttributes)[],
  wallet_credits: (WalletCreditAttributes | NestedWalletCreditAttributes)[]
}
) => {
  const { invoice, invoice_item, invoice_items, wallet_credits = [] } = args
  const {
    renegotiation_allowance,
    contractual_addition,
    installment_addition,
    anticipation_discount,
    expiration_date,
    punctuality_expiration_date,
  } = invoice
  const use_expiration_date = convertToDate(expiration_date)
  const use_punctuality_expiration_date = punctuality_expiration_date && (convertToDate(punctuality_expiration_date) as Date)
  if (use_punctuality_expiration_date) {
    use_punctuality_expiration_date.setHours(0)
  }
  const punctualityDaysDifference = toNumber(use_punctuality_expiration_date && differenceInDays(new Date(), new Date(use_punctuality_expiration_date)))
  const baseValues = invoice_item.base_values as InvoiceItemBaseValues
  const parsed_paid_at = invoice.paid_at && parse(invoice.paid_at, 'yyyy-LL-dd', new Date(), { locale: ptBR })
  const is_punctuality_expired = (invoice.effective_conditional_discount || 0) <= 0 && ((parsed_paid_at && use_punctuality_expiration_date) ? differenceInDays(use_punctuality_expiration_date as Date, parsed_paid_at) <= 0 : punctualityDaysDifference > 0)

  const contract_full_billing_percentage_base = baseValues.contract_full_billing_percentage_base
  const contract_full_billing_percentage = contract_full_billing_percentage_base * 100
  const contract_conditional_discount_billing_percentage_base = baseValues.contract_conditional_discount_billing_percentage_base
  const contract_conditional_discount_billing_percentage = (contract_conditional_discount_billing_percentage_base || 0) * 100
  const billing_full_value = toNumber(
    contract_full_billing_percentage_base * (get(invoice_item, 'contract_full') || 0),
  );
  const wallet_credits_full_value = wallet_credits.reduce((acc, wallet_credit) => {
    if (get(wallet_credit, '_destroy')) {
      return acc
    }
    return acc + (wallet_credit.credit_out || 0)
  }, 0)

  const billing_conditional_discount = contract_conditional_discount_billing_percentage_base * (get(invoice_item, 'contract_conditional_discount') || 0)

  const invoiceContractFull = sum(invoice_items.map(invoice_item => {
    return contract_full_billing_percentage_base * (get(invoice_item, 'contract_full') || 0)
  }))

  const invoiceContractConditionalDiscount = sum(invoice_items.map(invoice_item => {
    return contract_conditional_discount_billing_percentage_base * (get(invoice_item, 'contract_conditional_discount') || 0)
  }))


  const initial_contractual_addition = toNumber(baseValues && baseValues.initial_contractual_addition * contract_full_billing_percentage_base);

  const initial_installment_addition = toNumber(baseValues && baseValues.initial_installment_addition * contract_full_billing_percentage_base);

  const initial_renegotiation_allowance = toNumber(baseValues && baseValues.initial_renegotiation_allowance * contract_full_billing_percentage_base);

  const initial_anticipation_discount = toNumber(baseValues && baseValues.initial_anticipation_discount * contract_full_billing_percentage_base);
  const initial_fees = toNumber(baseValues && baseValues.initial_fees);
  const initial_penalty = toNumber(baseValues && baseValues.initial_penalty);

  const initial_charge = compactSum(
    [
      billing_full_value,
      initial_fees,
      initial_penalty,
      initial_installment_addition,
      initial_contractual_addition,
      -initial_renegotiation_allowance
    ]
  )

  const initial_conditional_discount_charge = compactSum([billing_conditional_discount, initial_anticipation_discount]);

  const invoice_item_ratio_over_invoice = (billing_full_value / invoiceContractFull) || 0;
  const invoice_item_percentage_over_invoice = (invoice_item_ratio_over_invoice * 100) || 0;

  const invoice_item_conditional_discount_ratio_over_invoice = is_punctuality_expired ? 0 : (billing_conditional_discount / invoiceContractConditionalDiscount) || 0;
  const invoice_item_conditional_discount_percentage_over_invoice = (invoice_item_conditional_discount_ratio_over_invoice * 100) || 0;

  const current_installment_addition = invoice_item_ratio_over_invoice * toNumber(installment_addition);
  const current_contractual_addition = invoice_item_ratio_over_invoice * toNumber(contractual_addition);
  const current_renegotiation_allowance = invoice_item_ratio_over_invoice * toNumber(renegotiation_allowance);
  const current_anticipation_discount = invoice_item_ratio_over_invoice * toNumber(anticipation_discount);
  const current_credits_discount = invoice_item_ratio_over_invoice * wallet_credits_full_value
  const original_charge = compactSum([initial_charge, current_installment_addition, current_contractual_addition, -current_renegotiation_allowance])
  const conditional_charge = compactSum([initial_anticipation_discount, current_anticipation_discount, billing_conditional_discount])
  const expected_liquid_value = compactSum([original_charge, -conditional_charge])
  const reminiscing_charge_value = compactSum([original_charge, -current_credits_discount])
  let current_charge = compactSum([initial_charge, current_installment_addition, current_contractual_addition, -current_renegotiation_allowance, -current_credits_discount])
  const current_conditional_charge = is_punctuality_expired ? 0 : conditional_charge
  const effective_conditional_discount = invoice_item_conditional_discount_ratio_over_invoice * toNumber(invoice.effective_conditional_discount) || current_conditional_charge
  const reminiscing_liquid_value = compactSum([reminiscing_charge_value, -effective_conditional_discount])
  const date_to_calculate_fees = parsed_paid_at || new Date()
  const is_paid = invoice.status === InvoiceStatusEnum.FINISHED
  const paid_with_interest = is_paid && (invoice.effective_liquid || 0) > reminiscing_charge_value
  const daysDifference = toNumber(use_expiration_date && differenceInDays(date_to_calculate_fees, new Date(use_expiration_date)));
  const is_expired = Number(is_paid ? paid_with_interest : daysDifference > 0);
  const invoiceFees = invoice.accumulated_fines?.accumulated_fees || 0
  const accumulated_fees = (invoice_item_ratio_over_invoice * invoiceFees)

  const calculated_penalty = invoice_item_ratio_over_invoice * (invoice.accumulated_fines?.accumulated_penalty || 0)
  const accumulated_penalty = (calculated_penalty) * is_expired;

  const invoice_liquid_value = compactSum([original_charge, accumulated_fees, accumulated_penalty, -current_conditional_charge])
  if (is_expired) {
    current_charge = invoice_liquid_value
  }
  const refunded_value = invoice_item_ratio_over_invoice * (invoice.refunded || 0)
  const retained_value = invoice_liquid_value - refunded_value
  return {
    accumulated_fees,
    accumulated_penalty,
    calculated_penalty,
    conditional_charge,
    billing_conditional_discount,
    billing_full_value,
    contract_conditional_discount_billing_percentage_base,
    contract_conditional_discount_billing_percentage,
    contract_full_billing_percentage_base,
    contract_full_billing_percentage,
    current_anticipation_discount,
    current_charge,
    current_conditional_charge,
    current_contractual_addition,
    current_credits_discount,
    current_installment_addition,
    current_renegotiation_allowance,
    effective_conditional_discount,
    expected_liquid_value,
    initial_anticipation_discount,
    initial_charge,
    initial_conditional_discount_charge,
    initial_contractual_addition,
    initial_fees,
    initial_installment_addition,
    initial_penalty,
    initial_renegotiation_allowance,
    invoice_item_conditional_discount_percentage_over_invoice,
    invoice_item_conditional_discount_ratio_over_invoice,
    invoice_item_percentage_over_invoice,
    invoice_item_ratio_over_invoice,
    invoice_liquid_value,
    is_expired,
    is_punctuality_expired,
    original_charge,
    refunded_value,
    reminiscing_charge_value,
    reminiscing_liquid_value,
    retained_value
  }
}





export const totalInvoiceValues = (args: {
  invoice_items: NestedInvoiceItemAttributes[] | InvoiceItemAttributes[],
  invoice: InvoiceAttributes | InvoiceFormAttributes,
  wallet_credits: (WalletCreditAttributes | NestedWalletCreditAttributes)[]
}) => {
  const { invoice, invoice_items, wallet_credits } = args
  return invoice_items.map((invoice_item) => {
    return invoiceItemValues({ invoice_item, invoice, invoice_items, wallet_credits })
  }).reduce((acc, result) => {
    return ({
      accumulated_fees: acc.accumulated_fees + result.accumulated_fees,
      accumulated_penalty: acc.accumulated_penalty + result.accumulated_penalty,
      conditional_charge: acc.conditional_charge + result.conditional_charge,
      billing_conditional_discount: acc.billing_conditional_discount + result.billing_conditional_discount,
      billing_full_value: acc.billing_full_value + result.billing_full_value,
      current_anticipation_discount: acc.current_anticipation_discount + result.current_anticipation_discount,
      current_charge: acc.current_charge + result.current_charge,
      current_conditional_charge: acc.current_conditional_charge + result.current_conditional_charge,
      current_contractual_addition: acc.current_contractual_addition + result.current_contractual_addition,
      current_installment_addition: acc.current_installment_addition + result.current_installment_addition,
      current_renegotiation_allowance: acc.current_renegotiation_allowance + result.current_renegotiation_allowance,
      effective_conditional_discount: acc.effective_conditional_discount + result.effective_conditional_discount,
      expected_liquid_value: acc.expected_liquid_value + result.expected_liquid_value,
      initial_anticipation_discount: acc.initial_anticipation_discount + result.initial_anticipation_discount,
      initial_charge: acc.initial_charge + result.initial_charge,
      initial_conditional_discount_charge: acc.initial_conditional_discount_charge + result.initial_conditional_discount_charge,
      initial_contractual_addition: acc.initial_contractual_addition + result.initial_contractual_addition,
      initial_fees: acc.initial_fees + result.initial_fees,
      initial_installment_addition: acc.initial_installment_addition + result.initial_installment_addition,
      initial_penalty: acc.initial_penalty + result.initial_penalty,
      initial_renegotiation_allowance: acc.initial_renegotiation_allowance + result.initial_renegotiation_allowance,
      invoice_liquid_value: acc.invoice_liquid_value + result.invoice_liquid_value,
      is_expired: Boolean(result.is_expired),
      original_charge: acc.original_charge + result.original_charge,
      refunded_value: acc.refunded_value + result.refunded_value,
      reminiscing_charge_value: acc.reminiscing_charge_value + result.reminiscing_charge_value,
      reminiscing_liquid_value: acc.reminiscing_liquid_value + result.reminiscing_liquid_value,
      retained_value: acc.retained_value + result.retained_value,
      total_credits: acc.total_credits + result.current_credits_discount,
      total_invoice_conditional_ratio: acc.total_invoice_conditional_ratio + result.invoice_item_conditional_discount_ratio_over_invoice,
      total_invoice_full_ratio: acc.total_invoice_full_ratio + result.invoice_item_ratio_over_invoice,
    })
  }, {
    accumulated_fees: 0,
    accumulated_penalty: 0,
    conditional_charge: 0,
    billing_conditional_discount: 0,
    billing_full_value: 0,
    current_anticipation_discount: 0,
    current_charge: 0,
    current_conditional_charge: 0,
    current_contractual_addition: 0,
    current_installment_addition: 0,
    current_renegotiation_allowance: 0,
    effective_conditional_discount: 0,
    expected_liquid_value: 0,
    initial_anticipation_discount: 0,
    initial_charge: 0,
    initial_conditional_discount_charge: 0,
    initial_contractual_addition: 0,
    initial_fees: 0,
    initial_installment_addition: 0,
    initial_penalty: 0,
    initial_renegotiation_allowance: 0,
    invoice_liquid_value: 0,
    is_expired: false,
    original_charge: 0,
    refunded_value: 0,
    reminiscing_charge_value: 0,
    reminiscing_liquid_value: 0,
    retained_value: 0,
    total_credits: 0,
    total_invoice_conditional_ratio: 0,
    total_invoice_full_ratio: 0,
  })

}
export const prepareInvoiceItemAttributesForRenegotiation = (args: { invoice_item: InvoiceItemAttributes, invoice: InvoiceFormAttributes | InvoiceAttributes, invoice_items: InvoiceItemAttributes[] }) => {
  const { invoice, invoice_item, invoice_items } = args
  const { billing_id } = invoice_item

  const {
    initial_fees,
    initial_penalty,
    contract_conditional_discount_billing_percentage_base,
    contract_full_billing_percentage_base,
    is_punctuality_expired,
  } = invoiceItemValues({ invoice_item, wallet_credits: [], invoice, invoice_items })
  const { accumulated_fees, calculated_penalty } = invoiceItemValues({
    invoice_item, wallet_credits: [], invoice, invoice_items: invoice_items.filter(ii => {
      return ii.invoice_id.toString() === invoice.id
    })
  })
  const new_base_values: InvoiceItemBaseValues = {
    contract_conditional_discount_billing_percentage_base: is_punctuality_expired ? 0 : contract_conditional_discount_billing_percentage_base,
    contract_full_billing_percentage_base: contract_full_billing_percentage_base,
    initial_contractual_addition: 0,
    initial_renegotiation_allowance: 0,
    initial_anticipation_discount: 0,
    initial_fees: initial_fees + accumulated_fees,
    initial_penalty: initial_penalty + calculated_penalty,
    initial_installment_addition: 0,
  }
  const new_invoice_item = {
    active: true,
    billing_id,
    parent_id: ~~invoice_item.id,
    base_values: new_base_values,
    contract_full: invoice_item.contract_full,
    contract_conditional_discount: invoice_item.contract_conditional_discount,
    portion: invoice_item.portion,
    product_name: invoice_item.product_name
  }
  return new_invoice_item
}

export const handleRenegotiationFormInitialData = async (args: {
  invoice_items: InvoiceItemAttributes[];
  invoices: InvoiceAttributes[];
  selected_invoice_ids: number[];
}) => {
  const { invoice_items, invoices, selected_invoice_ids } = args;
  const use_selected_invoice_items = selected_invoice_ids.reduce((acc, invoice_id) => {
    const invoice_items_for_this_invoice = invoice_items.filter((ii) => ii.invoice_id === invoice_id);
    return acc.concat(invoice_items_for_this_invoice);
  }, [] as InvoiceItemAttributes[]);
  const invoices_from_selected_invoice_items = selected_invoice_ids.map((invoice_id) => {
    return find(invoices, (invoice) => ~~invoice.id === invoice_id);
  }) as InvoiceAttributes[];
  const selected_invoice_item_ids = use_selected_invoice_items.map((item) => item.id);
  const possible_representatives = [
    ...new Set(
      use_selected_invoice_items.map((invoice_item) => {
        return invoice_item.billable_representative_id;
      }),
    ),
  ];

  const possible_payment_option_fees_and_penalties = use_selected_invoice_items.reduce((acc, invoice_item) => {
    return acc.concat({ fees: invoice_item.billable_fees, penalty: invoice_item.billable_penalty });
  }, [] as { fees: number; penalty: number }[]);
  const has_one_single_fee = uniq(possible_payment_option_fees_and_penalties.map((item) => item.fees)).length === 1;
  const has_one_single_penalty =
    uniq(possible_payment_option_fees_and_penalties.map((item) => item.penalty)).length === 1;
  const { renegotiation_allowance, anticipation_discount, contractual_addition, installment_addition } =
    use_selected_invoice_items.reduce(
      (acc, invoice_item) => {
        const invoice = find(invoices, (invoice) => ~~invoice.id === invoice_item.invoice_id) as InvoiceAttributes;
        const invoice_invoice_items = filter(
          invoice_items,
          (invoice_item) => invoice_item.invoice_id === ~~invoice.id,
        ) as InvoiceItemAttributes[];
        const {
          current_renegotiation_allowance,
          current_contractual_addition,
          current_anticipation_discount,
          current_installment_addition,
        } = invoiceItemValues({
          invoice_items: invoice_invoice_items,
          invoice_item,
          invoice,
          wallet_credits: [],
        });
        return {
          renegotiation_allowance: acc.renegotiation_allowance + current_renegotiation_allowance,
          contractual_addition: acc.contractual_addition + current_contractual_addition,
          anticipation_discount: acc.anticipation_discount + current_anticipation_discount,
          installment_addition: acc.installment_addition + current_installment_addition,
        };
      },
      { renegotiation_allowance: 0, anticipation_discount: 0, contractual_addition: 0, installment_addition: 0 },
    );
  const resultingInvoice = {
    registration_id: head(invoices_from_selected_invoice_items)?.registration_id,
    kind: 'single',
    payment_method: InvoicePaymentOptionEnum.BANK_BILLET,
    renegotiation_allowance,
    anticipation_discount,
    contractual_addition,
    installment_addition,
    invoice_items_attributes: use_selected_invoice_items.map((invoice_item) => {
      const invoice = find(invoices, (invoice) => ~~invoice.id === invoice_item.invoice_id) as InvoiceAttributes;
      return {
        ...prepareInvoiceItemAttributesForRenegotiation({
          invoice_item,
          invoice,
          invoice_items: use_selected_invoice_items,
        }),
      };
    }),
    ...(possible_representatives.length === 1 && { representative_id: head(possible_representatives) }),
    ...(has_one_single_fee && { fees: head(possible_payment_option_fees_and_penalties)?.fees }),
    ...(has_one_single_penalty && { penalty: head(possible_payment_option_fees_and_penalties)?.penalty }),
  };

  const resultingInvoiceReference = {
    ...resultingInvoice,
    invoice_items_attributes: use_selected_invoice_items.map((invoice_item) => {
      const invoice = find(invoices, (invoice) => ~~invoice.id === invoice_item.invoice_id) as InvoiceAttributes;
      return {
        ...prepareInvoiceItemAttributesForRenegotiation({
          invoice_item,
          invoice,
          invoice_items: use_selected_invoice_items,
        }),
        id: invoice_item.id,
        parent_id: invoice_item.parent_id,
      };
    }),
    ...(possible_representatives.length === 1 && { representative_id: head(possible_representatives) }),
    ...(has_one_single_fee && { fees: head(possible_payment_option_fees_and_penalties)?.fees }),
    ...(has_one_single_penalty && { penalty: head(possible_payment_option_fees_and_penalties)?.penalty }),
  };

  const new_initial_values = invoices_from_selected_invoice_items.reduce(
    (acc, invoice) => {
      const invoice_items_for_this_invoice = invoice_items.filter(
        (invoice_item) => invoice_item.invoice_id === ~~invoice.id,
      );
      let new_invoice_renegotiations = acc.remaining_invoice_renegotiations_attributes;
      const remainingActiveInvoiceItems = invoice_items_for_this_invoice.filter(
        (invoice_item) => !includes(selected_invoice_item_ids, invoice_item.id),
      );

      if (!isEmpty(remainingActiveInvoiceItems)) {
        const { renegotiation_allowance, anticipation_discount, contractual_addition, installment_addition } =
          remainingActiveInvoiceItems.reduce(
            (acc, invoice_item) => {
              const invoice = find(
                invoices,
                (invoice) => ~~invoice.id === invoice_item.invoice_id,
              ) as InvoiceAttributes;
              const invoice_invoice_items = filter(
                invoice_items,
                (invoice_item) => invoice_item.invoice_id === ~~invoice.id,
              ) as InvoiceItemAttributes[];
              const {
                current_renegotiation_allowance,
                current_contractual_addition,
                current_anticipation_discount,
                current_installment_addition,
              } = invoiceItemValues({
                invoice_item,
                invoice,
                invoice_items: invoice_invoice_items,
                wallet_credits: [],
              });
              return {
                renegotiation_allowance: acc.renegotiation_allowance + current_renegotiation_allowance,
                contractual_addition: acc.contractual_addition + current_contractual_addition,
                anticipation_discount: acc.anticipation_discount + current_anticipation_discount,
                installment_addition: acc.installment_addition + current_installment_addition,
              };
            },
            {
              renegotiation_allowance: 0,
              anticipation_discount: 0,
              contractual_addition: 0,
              installment_addition: 0,
            },
          );

        const { id, ...rest } = invoice;
        new_invoice_renegotiations = new_invoice_renegotiations.concat({
          invoice_attributes: {
            ...rest,
            renegotiation_allowance,
            anticipation_discount,
            contractual_addition,
            installment_addition,
            invoice_items_attributes: remainingActiveInvoiceItems.map((invoice_item) => {
              return {
                ...prepareInvoiceItemAttributesForRenegotiation({
                  invoice_item,
                  invoice,
                  invoice_items: remainingActiveInvoiceItems,
                }),
              };
            }),
          },
        });
      }
      return {
        invoices_attributes: acc.invoices_attributes.concat({
          ...invoice,
          invoice_items_attributes: invoice_items_for_this_invoice.map((invoice_item) => ({
            ...invoice_item,
            active: false,
          })),
        }),
        invoice_renegotiations_attributes: acc.invoice_renegotiations_attributes,
        remaining_invoice_renegotiations_attributes: new_invoice_renegotiations,
        invoice_reference: acc.invoice_reference,
      };
    },
    {
      invoices_attributes: [] as InvoiceFormAttributes[],
      invoice_renegotiations_attributes: [
        { invoice_attributes: resultingInvoice },
      ] as InvoiceRenegotiationFormAttributes[],
      invoice_reference: resultingInvoiceReference as InvoiceFormAttributes,
      remaining_invoice_renegotiations_attributes: [] as InvoiceRenegotiationFormAttributes[],
    },
  );

  const representative_options = uniqBy(
    map(possible_representatives, (representative_id) => {
      const representative_name = get(
        find(use_selected_invoice_items, (item) => item.billable_representative_id === representative_id),
        'billable_representative_name',
      );
      return {
        value: ~~representative_id,
        label: representative_name as string,
      };
    }),
    'label',
  );
  return {
    representative_options,
    new_initial_values
  }
};



function preformatFloat(float: string) {
  if (!float) {
    return '';
  }

  const posC = float.indexOf(',');

  if (posC === -1) {
    return float;
  }

  const posFS = float.indexOf('.');

  if (posFS === -1) {
    return float.replace(/\,/g, '.');
  }

  return ((posC < posFS) ? (float.replace(/\,/g, '')) : (float.replace(/\./g, '').replace(',', '.')));
}

export const totalInvoiceRenegotiationTotals = (
  invoice_renegotiations: InvoiceRenegotiationFormAttributes[],
  billings: BillingAttributes[],
  wallet_credits: (WalletCreditAttributes | NestedWalletCreditAttributes)[]
) => {
  let invoice_renegotiation_total_current_charge = 0
  let invoice_renegotiation_total_current_conditional_charge = 0
  invoice_renegotiations.forEach((invoice_renegotiation) => {
    const current_invoice_renegotiation_invoice = invoice_renegotiation.invoice_attributes
    const current_invoice_renegotiation_invoice_items = current_invoice_renegotiation_invoice.invoice_items_attributes
    const { current_charge, current_conditional_charge } = totalInvoiceValues({
      invoice_items: current_invoice_renegotiation_invoice_items,
      invoice: current_invoice_renegotiation_invoice,
      wallet_credits
    })

    invoice_renegotiation_total_current_charge += current_charge
    invoice_renegotiation_total_current_conditional_charge += current_conditional_charge
  })
  return { invoice_renegotiation_total_current_charge, invoice_renegotiation_total_current_conditional_charge }
}


export const calculateDiscount = (discountKind: DiscountTypes, discountValue: number, baseValue: number) => {
  return discountKind === 'percentage' ? baseValue * discountValue / 100 : discountKind === 'full_value' ? discountValue : 0
}

export const parseMonetaryValue = (value: string | number) => {
  if (typeof value === 'string') {
    let returnValue = value.replace('R$', '')
    if (returnValue[returnValue.length - 1] === ',' || returnValue[returnValue.length - 1] === '.') {
      returnValue = returnValue.concat('0')
    }
    const result = parseFloat(preformatFloat(returnValue));
    return result
  }
  return value;
}
export function leftFillNum(num: number, targetLength = 2) {
  return num.toString().padStart(targetLength, '0');
}

export function cpfValidation(str: string) {
  let Soma;
  let Resto;
  Soma = 0;
  if (str.length !== 11 ||
    str === "00000000000" ||
    str === "11111111111" ||
    str === "22222222222" ||
    str === "33333333333" ||
    str === "44444444444" ||
    str === "55555555555" ||
    str === "66666666666" ||
    str === "77777777777" ||
    str === "88888888888" ||
    str === "99999999999")
    return false;
  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(str.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(str.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(str.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(str.substring(10, 11))) return false;
  return true;
}


export const verifyDifferentValues = (newValues: any, initialValues: any, omit?: string[]) => {
  const remaining = Object.keys(newValues).filter(value => {
    const newValue = newValues[value]
    const initialValue = initialValues[value]

    return !isEqual(newValue, initialValue) || omit?.includes(value)
  }).reduce((ac, value) => {
    return {
      ...ac,
      [value]: newValues[value]
    }
  }, {})
  return remaining
}

export const setQueryParams = (newQueryParams: RequestParams | undefined) => {
  if (isEmpty(newQueryParams)) {
    return ''
  }
  const queryParams = new URLSearchParams()
  if (newQueryParams) {
    const { filters } = newQueryParams
    Object.keys(filters).forEach((param) => {
      queryParams.append(param, filters[param] as string)
    })
  }
  return queryParams
}

export const checkUndefined = (value: string | number) => {
  if (!value || (typeof value !== 'number' && value.includes('undefined'))) {
    return null
  }
  return value
}
export const toDate = (dateStr: string) => {
  const [year, month, day] = dateStr.split("-")
  return new Date(~~year, ~~month - 1, ~~day)
}

export const parsePercentageDiscount = (value: string | number) => {
  if (typeof value === 'string') {
    let returnValue = value.substring(0, value.length - 1)
    if (returnValue[returnValue.length - 1] === ',' || returnValue[returnValue.length - 1] === '.') {
      returnValue = returnValue.concat('0')
    }
    return Number(returnValue)
  }
  return value;
}

export const getPropsAndParser = (kind: DiscountTypes) => {
  return ({
    props: kind === 'full_value' ? monetaryValueMaskProps : kind === 'percentage' ? percentageMaskProps : {},
    parser: kind === 'full_value' ? parseMonetaryValue : kind === 'percentage' ? parsePercentageDiscount : {},
  })
}

export const getPortionMonth = (month: number, month_format = 'LLL') => format(new Date(new Date().getFullYear(), month - 1), month_format, { locale: ptBR })


export const getMonthInteger = (partial: string) => getMonth(parse(partial, 'LLL/yyyy', new Date(), { locale: ptBR })) + 1

export const isEmailValid = (value: string) => emailRegex.test(value)

export const convertToCPF = (value: string) => {
  const firstPortion = value.substring(0, 3)
  const secondPortion = value.substring(3, 6)
  const thirdPortion = value.substring(6, 9)
  const fourthPortion = value.substring(9, 11)
  let customValue = value
  if (value.length <= 3) {
    customValue = `${firstPortion}`
  } else if (value.length <= 6) {
    customValue = `${firstPortion}.${secondPortion}`
  } else if (value.length <= 9) {
    customValue = `${firstPortion}.${secondPortion}.${thirdPortion}`
  } else if (value.length < 11 || value.length >= 11) {
    customValue = `${firstPortion}.${secondPortion}.${thirdPortion}-${fourthPortion}`
  }
  return customValue
}

export const getCompaniesChildIds = (data: CompanyOption[]) => {
  return data
    .reduce((ac: number[], company) => {
      if (company.child_ids.length) {
        return ac.concat(company.child_ids)
      }
      return ac
    }, [])
    .map((id: number) => id)
}

export const calculateMenuOptions = (menuOptions: MenuOptionsType[], profile: SetProfileType, companies: any[], company: string) => {
  const currentCompanyKind = companies.find((co: any) => co.id === company)?.kind
  const profileValue = profile.role
  return menuOptions.filter((item) => {
    return (item.profiles && item.profiles.includes(profileValue)) && (item.companies && item.companies.includes(currentCompanyKind))
  })
}

export const isValidDate = (value: Date | string | number) => {
  return (typeof value === 'string' ? isIsoDate(value) : value instanceof Date && !isNaN(value.valueOf()))
}

export const formatCompanyData = (res: CompanyJson) => {
  const {
    attributes: { name, kind, child_ids }, id
  } = res
  const formattedData = {
    value: id,
    label: name,
    kind, child_ids
  }
  return formattedData
}

export const organizeCompanies = (data: CompanyOption[], currentOptions: CompanyOption[]) => {
  const newOptions: CompanyOption[] = []
  data.forEach((item) => {
    if (!currentOptions.map((option) => option.value).includes(item.value)) {
      newOptions.push({ ...item })
    }
  })
  let companyIds: number[] = []
  let newOptionsValue = currentOptions
  if (newOptions.length) {
    newOptionsValue = [...currentOptions, ...newOptions]
    const ids = getCompaniesChildIds(newOptions)
    companyIds = companyIds.concat(ids)
  }
  return { companyIds, newOptionsValue }
}


export const convertToCNPJ = (value: string) => {
  const firstPortion = value.substring(0, 2)
  const secondPortion = value.substring(2, 5)
  const thirdPortion = value.substring(5, 8)
  const fourthPortion = value.substring(8, 12)
  const fifthPortion = value.substring(12, 14)
  if (value.length <= 2) {
    return `${firstPortion}`
  } else if (value.length <= 5) {
    return `${firstPortion}.${secondPortion}.`
  } else if (value.length <= 8) {
    return `${firstPortion}.${secondPortion}.${thirdPortion}/`
  } else if (value.length <= 12) {
    return `${firstPortion}.${secondPortion}.${thirdPortion}/${fourthPortion}-`
  } else if (value.length < 15 || value.length >= 15) {
    return `${firstPortion}.${secondPortion}.${thirdPortion}/${fourthPortion}-${fifthPortion}`
  }
  return ''
}

export const logFormData = (formData: FormData) => {
  // eslint-disable-next-line no-console
  console.log('form data entries', formData.entries())
  for (const pair of formData.entries()) {
    // eslint-disable-next-line no-console
    console.log(pair[0], pair[1]);
  }
}

export const checkDocumentType = (value: string) => {
  const formatted = removeSpecialSymbols(value)
  if (formatted.length === 11) {
    return 'cpf'
  } else if (formatted.length === 14) {
    return 'cnpj'
  }
}

export const convertDocument = (value: string) => {
  const formatted = removeSpecialSymbols(value)
  if (formatted.length <= 11) {
    return convertToCPF(formatted)
  } else if (formatted.length > 11 && formatted.length <= 14) {
    return convertToCNPJ(formatted)
  }
  return formatted
}


const zipcodeUrl = (zipcode: string) => `https://viacep.com.br/ws/${zipcode}/json/`

export const fetchCepInfo = (cep: string) => {
  return axios.get(zipcodeUrl(cep)).then((res) => res)
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function nameToInitials(fullName: string) {
  const namesArray = fullName?.trim().split(' ')
  if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`
  else {
    return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`
  }
}

export const countDecimals = function (value: number) {
  if (Math.floor(value) === value) return 0
  return value.toString().split('.')[1].length || 0
}

export const formatToCurrency = (value: number) => {
  const fixedValue = Number((value || parseFloat("0")).toFixed(2))
  return `R$${Intl.NumberFormat('pt-BR').format(fixedValue)}${fixedValue % 1 === 0 ? ',00' : countDecimals(fixedValue) === 1 ? '0' : ''}`
}

export const mergeRefs = (...refs: any[]) => {
  const filteredRefs = refs.filter(Boolean)
  if (!filteredRefs.length) return null
  if (filteredRefs.length === 0) return filteredRefs[0]
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst)
      } else if (ref) {
        ref.current = inst
      }
    }
  }
}
interface Change {
  (form: string, field: string, value: any): AnyAction
}
interface Untouch {
  (form: string, field: string): AnyAction
}

export const resetFields = (form: string, fields: string[], dispatch: AppDispatch, change: Change, untouch: Untouch) => {
  fields.forEach((item) => {
    dispatch(change(form, item, null))
    dispatch(untouch(form, item))
  })
}

export const parseText = function (text: string, limit: number) {
  if (isNil(text)) {
    return ''
  }
  if (text.length > limit) {
    return text.slice(0, limit).concat('...')
  } else {
    return text
  }
}



export const urlToObject = async (image: string) => {
  const filename = image.substr(image.lastIndexOf('/') + 1, image?.length)
  const response = await fetch(image);
  const blob = await response.blob();
  const file = new File([blob], filename, { type: blob.type });
  return file
}

export function ensure<T>(argument: T | undefined | null, message = 'This value was promised to be there.'): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

export function withPayloadType<T>() {
  return (t: T) => ({ payload: t })
}


export function encodeQueryData(data: { [key: string]: any }) {
  const ret = []
  for (const d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
  }
  return ret.join('&')
}

export function dateRange(startDate: string | Date, endDate: string | Date) {
  const formattedStartDate = typeof startDate === 'string' ? startDate : startDate instanceof Date ? startDate.toISOString() : ''
  const formattedEndDate = typeof endDate === 'string' ? endDate : endDate instanceof Date ? endDate.toISOString() : ''
  const start = formattedStartDate.split('-');
  const end = formattedEndDate.split('-');
  const startYear = parseInt(start[0]);
  const endYear = parseInt(end[0]);
  const dates = [];

  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    const startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const monthLabel = format(new Date(i, j), 'LLL', { locale: ptBR })
      dates.push([monthLabel, i].join('/'));
    }
  }
  return dates;
}

export const generateFormDataNestedAttributePrefixString = (attr: string, field: string, index: number) => {
  return `data[attributes][${attr}][${index}][${field}]`
}

export const getCompanyFilterOptions = (props: {
  companies: CompanyAttributes[];
  company_descendants: CompanyAttributes[];
  current_company_id: string;
  is_above_school_director: boolean;
}) => {
  const { companies, company_descendants: descendants, current_company_id, is_above_school_director } = props;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === current_company_id,
  ) as CompanyAttributes;
  return !is_above_school_director
    ? [{ value: current_company_id, label: current_company.name }]
    : current_company.kind === Company.SCHOOL
      ? [current_company_id]
      : descendants.reduce((acc, co) => {
        if (co.kind === Company.SCHOOL) {
          return acc.concat({ value: co.id.toString(), label: co.name });
        }
        return acc;
      }, [] as DefaultOptionType[]);
};

export const get_company_search = (props: { companies: CompanyAttributes[], company_descendants: CompanyAttributes[], current_company_id: string, company_filter: string }) => {
  const { companies, company_descendants, company_filter, current_company_id } = props
  const current_company = companies.find((item: CompanyAttributes) => item.id === current_company_id) as CompanyAttributes;
  return company_filter
    ? [company_filter]
    : current_company.kind === Company.SCHOOL
      ? [current_company_id]
      : company_descendants.map((item: CompanyAttributes) => item.id);
}

const default_addition_formula = (len = 2, kind = OperationKindEnum.EXAM_PLACEMENT_ORDER) => {
  return {
    operation_attributes: {
      kind: OperationKindEnum.DIVISION,
      weight: 1,
      order: 1,
      operable_type: null,
      children_attributes: [
        {
          id: generateCharOnlyId(5),
          order: 1,
          weight: 1,
          kind: OperationKindEnum.ADDITION,
          children_attributes: [...Array(len)].map((_, i) => {
            return (
              {
                id: generateCharOnlyId(5),
                order: i + 1,
                weight: 1,
                kind,
                number_input: i + 1
              }
            )
          })
        },
        {
          id: generateCharOnlyId(5),
          order: 2,
          weight: 1,
          kind: OperationKindEnum.NUMBER_INPUT,
          number_input: len
        }
      ]
    }
  }
}

const default_choose_between_all = (len = 5) => {
  return {
    operation_attributes: {
      kind: OperationKindEnum.MAX_VALUE,
      number: 1,
      weight: 1,
      order: 1,
      operable_type: null,
      children_attributes: [...Array(len)].map((_, i) => {
        return (
          {
            id: generateCharOnlyId(5),
            order: i + 1,
            weight: 1,
            kind: OperationKindEnum.EXAM_PLACEMENT_ORDER,
            number_input: i + 1
          }
        )
      })
    }
  }
}

const default_choose_between_exams = (total_len = 4, chosen = 2) => {
  return {
    operation_attributes: {
      kind: OperationKindEnum.DIVISION,
      number: 1,
      weight: 1,
      order: 1,
      operable_type: null,
      children_attributes: [
        {
          id: generateCharOnlyId(5),
          order: 1,
          weight: 1,
          kind: OperationKindEnum.ADDITION,
          children_attributes: [
            {
              id: generateCharOnlyId(5),
              order: 1,
              weight: 1,
              kind: OperationKindEnum.ADDITION,
              number_input: chosen,
              children_attributes: [...Array(total_len - 1)].map((_, i) => {
                return (
                  {
                    id: generateCharOnlyId(5),
                    order: i + 1,
                    weight: 1,
                    kind: OperationKindEnum.EXAM_PLACEMENT_ORDER,
                    number_input: i + 1
                  }
                )
              })
            },
            {
              id: generateCharOnlyId(5),
              order: 2,
              weight: 1,
              kind: OperationKindEnum.EXAM_PLACEMENT_ORDER,
              number_input: total_len
            }
          ]
        },
        {
          id: generateCharOnlyId(5),
          order: 2,
          weight: 1,
          kind: OperationKindEnum.NUMBER_INPUT,
          number_input: total_len - 1
        }
      ]
    }
  }
}

export const default_formulas = (defaultInitialValues: Partial<FormulaFormData>): FormulaSampleData[] => [
  {
    id: '1',
    kind: 'partial',
    label: '(AV1 + AV2) / 2',
    formula: {
      ...defaultInitialValues,
      ...(default_addition_formula(2))
    }
  },
  {
    id: '2',
    kind: 'partial',
    label: '(AV1 + AV2 + AV3) / 3',
    formula: {
      ...defaultInitialValues,
      ...(default_addition_formula(3))
    }
  }, {
    id: '3',
    kind: 'partial',
    label: '(AV1 + AV2 + AV3 + AV4) / 4',
    formula: {
      ...defaultInitialValues,
      ...(default_addition_formula(4))
    }
  }, {
    id: '4',
    kind: 'partial',
    label: '(MAX1(AV1 + AV2) + AV3) / 2',
    formula: {
      ...defaultInitialValues,
      ...(default_choose_between_exams(3, 1))
    },
  },
  {
    id: '5',
    kind: 'partial',
    label: '(MAX2(AV1 + AV2 + AV3) + AV4) / 3',
    formula: {
      ...defaultInitialValues,
      ...(default_choose_between_exams(4, 2))
    },
  },
  {
    id: '6',
    kind: 'partial',
    label: '(AV1 + AV2 + MAX(AV3 | AV4))) / 3',
    formula: {
      ...defaultInitialValues,
      operation_attributes: {
        kind: OperationKindEnum.DIVISION,
        weight: 1,
        order: 1,
        operable_type: null,
        children_attributes: [
          {
            id: generateCharOnlyId(5),
            order: 1,
            weight: 1,
            kind: OperationKindEnum.ADDITION,
            children_attributes: [
              {
                id: generateCharOnlyId(5),
                order: 1,
                weight: 1,
                number_input: 1,
                kind: OperationKindEnum.EXAM_PLACEMENT_ORDER,
              },
              {
                id: generateCharOnlyId(5),
                order: 2,
                weight: 1,
                number_input: 2,
                kind: OperationKindEnum.EXAM_PLACEMENT_ORDER,
              },
              {
                id: generateCharOnlyId(5),
                order: 3,
                weight: 1,
                number_input: 1,
                kind: OperationKindEnum.MAX_VALUE,
                children_attributes: [
                  {
                    id: generateCharOnlyId(5),
                    order: 1,
                    weight: 1,
                    number_input: 3,
                    kind: OperationKindEnum.EXAM_PLACEMENT_ORDER,
                  },
                  {
                    id: generateCharOnlyId(5),
                    order: 2,
                    weight: 1,
                    number_input: 4,
                    kind: OperationKindEnum.EXAM_PLACEMENT_ORDER,
                  }
                ]
              }
          ]
          },
          {
            id: generateCharOnlyId(5),
            order: 2,
            weight: 1,
            kind: OperationKindEnum.NUMBER_INPUT,
            number_input: 3
          }
        ]
      }
    },
  },  {
    id: '7',
    kind: 'partial',
    label: '(MAX3(AV1 + AV2 + AV3 + AV4) + AV5) / 4',
    formula: {
      ...defaultInitialValues,
      ...(default_choose_between_exams(5, 3))
    },
  },{
    id: '8',
    kind: 'partial',
    label: 'MAX(AV1 | AV2 | AV3 | AV4 | AV5)',
    formula: {
      ...defaultInitialValues,
      ...(default_choose_between_all(5))
    },
  }, {
    id: '9',
    kind: 'final',
    label: '(MP1 + MP2 + MP3 + MP4) / 4',
    formula: {
      ...defaultInitialValues,
      ...(default_addition_formula(4, OperationKindEnum.COMPOSITION_PERIOD_ORDER))
    },
  }, {
    id: '10',
    kind: 'final',
    label: '(AV1 + M1) / 2',
    formula: {
      ...defaultInitialValues,
      operation_attributes: {
        kind: OperationKindEnum.DIVISION,
        weight: 1,
        order: 1,
        operable_type: null,
        children_attributes: [
          {
            id: generateCharOnlyId(5),
            order: 1,
            weight: 1,
            kind: OperationKindEnum.ADDITION,
            children_attributes: [
              {
                id: generateCharOnlyId(5),
                order: 1,
                weight: 1,
                kind: OperationKindEnum.FORMULA_STEP,
                number_input: 1
              },
              {
                id: generateCharOnlyId(5),
                order: 2,
                weight: 1,
                kind: OperationKindEnum.EXAM_PLACEMENT_ORDER,
                number_input: 1
              }
            ]
          },
          {
            id: generateCharOnlyId(5),
            order: 2,
            weight: 1,
            kind: OperationKindEnum.NUMBER_INPUT,
            number_input: 2
          }
        ]
      }

    },
  }
]