/* eslint-disable camelcase */
import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';
import {
  colors,
  PaginationType,
  Role,
  defaultPagination,
  WalletsViewAttributes,
} from '../../../utils/constants';
import TablePaginationActions from '../../shared/TablePaginationActions';
import InputComponent from '../../input/form/input';
import SelectComponent from '../../input/form/select';
import { convertToCPF, evaluate_permissions, formatToCurrency, getCompanyFilterOptions } from '../../../utils/functions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { css, useTheme } from '@emotion/react';

export const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    tableTitle: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      background: ${colors.green};
      padding: 1rem 4rem;
      border: none;
    `,
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
    table: css`
      background: ${colors.lightBlue};
      padding: 2rem;
      border-radius: 4px;
      display: grid;

      & .subtitle {
        text-align: start;
        margin-bottom: 1rem;
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};


const WalletsView = ({
  wallets,
  pagination,
  handleChangePageSize,
  handlePageChange,
  nameFilterValue,
  setNameFilterValue,
  fetchWalletsMethod,
  companyFilterValue,
  documentNumberFilterValue,
  setDocumentNumberFilterValue,
  setCompanyFilterValue,
}: {
  wallets: WalletsViewAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  documentNumberFilterValue: string;
  setDocumentNumberFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fetchWalletsMethod: ({ newPagination, company_filter, }: {
    newPagination?: PaginationType | undefined;
    period?: string | undefined;
    company_filter?: string | undefined;
}) => Promise<void>
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
}) => {
  const classes = useStyles();

  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;

  const is_above_group_admin = evaluate_permissions.is_above_group_admin(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director: is_above_group_admin,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const columns = [...(is_above_group_admin ? ['Unidade'] : []), 'Nome', 'CPF', 'Saldo'];

  return (
    <div css={classes.view}>
      <div css={classes.tableTitle}>
        <span className='title'>Carteiras</span>
      </div>
      <div css={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div css={classes.filters}>
          {is_above_group_admin && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchWalletsMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchWalletsMethod({});
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value);
              },
            }}
          />
        <InputComponent
            placeholder={`Buscar por CPF`}
            search
            small
            onSearch={() => {
              fetchWalletsMethod({});
            }}
            input={{
              value: documentNumberFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setDocumentNumberFilterValue(e.target.value);
              },
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {wallets.map((wallet) => {
                return (
                  <TableRow key={wallet.id}>
                    {is_above_group_admin && <TableCell>{wallet.company}</TableCell>}
                    <TableCell>{wallet.name}</TableCell>
                    <TableCell>{convertToCPF(wallet.document_number)}</TableCell>
                    <TableCell>{formatToCurrency(wallet.balance)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default WalletsView;
