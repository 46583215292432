/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import {
  Field,
  FieldArray,
  FormSection,
  WrappedFieldArrayProps,
  change,
  formValueSelector,
  reduxForm,
  reset,
} from 'redux-form';
import {
  CoordinatorAttributes,
  CoordinatorFormData,
  DefaultOptionType,
  NestedCoordinatorAttributes,
  Paths,
  Role,
  UserFormFields,
  UserOptionsData,
  colors,
  defaultCoordinatorFormInitialValues,
  validation,
} from '../../utils/constants';
import SelectComponent from '../input/form/select';
import { RootState } from '../../store/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import { evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';
import DeleteButton from '../shared/DeleteButton';
import { css } from '@emotion/react';
import AddButton from '../shared/AddButton';
import { FETCH_COURSES } from '../../store/courses';
import { error } from 'react-notification-system-redux';
import { isEmpty } from 'lodash';
import UserForm from './UserForm';

const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding: 2rem 10%;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #fdfeff;
    padding: 1rem;

    & .user-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 1rem;

      & > div {
        flex-basis: 100%;
      }

      & #input-nationality_country {
        flex-basis: 32.6666666%;
        order: 1;
      }

      & #input-nationality_state {
        flex-basis: 32.6666666%;
        order: 2;
      }

      & #input-nationality_city {
        flex-basis: 32.6666666%;
        order: 3;
      }

      & #input-document_number {
        order: 4;
        flex-basis: 24.5%;
      }

      & #input-name {
        flex-basis: 24.5%;
        order: 5;
      }

      & #input-email {
        flex-basis: 24.5%;
        order: 6;
      }

      & div[id$="personal_email"] {
        flex-basis: 24.5%;
        order: 7;
      }

      & #input-phone {
        flex-basis: 32.6666666%;
        order: 8;
      }

      & #input-gender {
        flex-basis: 32.6666666%;
        order: 9;
      }

      & #input-birthdate {
        flex-basis: 32.6666666%;
        order: 10;
      }

      & .nationality-span {
        display: none;
      }

      & #input-address_form {
        order: 11;
      }

      & div[id$="street"] {
        flex-basis: 40%;
      }

      & div[id$="number"] {
        flex-basis: 24%;
      }

      & div[id$="complement"] {
        flex-basis: 35%;
      }

      & div[id$="neighbourhood"] {
        flex-basis: 30%;
      }

      & #input-address_form > div[id$="country_id"] {
        flex-basis: 20%;
      }

      & #input-address_form > div[id$="state_id"] {
        flex-basis: 25%;
      }

      & #input-address_form > div[id$="city_id"] {
        flex-basis: 22.5%;
      }

      & .zipcode {
        display: flex;
        max-width: 100%;
        width: 50rem;
        gap: 1rem;

        & > .search {
          display: flex;
          align-items: center;
          font-size: 1.25rem;
          cursor: pointer;
          margin-bottom: 1rem;
          border: none;
          gap: 1rem;
          width: fit-content;
          background: inherit;

          & > svg {
            font-size: 3rem;
            color: ${colors.darkBlue};

            &:hover {
              color: #5a5a72;
            }
          }
        }
      }
    }
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

const form_name = 'coordinatorForm';

interface CoordinatorFormProps extends WrappedFieldArrayProps {
  account_preffix: string
}

const renderCoordinatorsForm = (props: CoordinatorFormProps) => {
  const { fields, account_preffix } = props;
  const [course_options, setCourseOptions] = React.useState<DefaultOptionType[]>([])
  const dispatch = useDispatch();
  const all_coordinators = fields.getAll() as NestedCoordinatorAttributes[]
  const selected_courses = all_coordinators.filter(item => !item._destroy).map(item => item.course_id as number)
  const formatted_options = course_options.map(option => ({
    ...option,
    disabled: selected_courses.includes(~~option.value)
  }))
  const addCordinator = React.useCallback(() => {
    fields.push({});
  }, [fields]);

  const deleteCoordinator = React.useCallback(
    (current_coordinator: NestedCoordinatorAttributes, coordinator: string, index: number) => {
      current_coordinator.id
        ? dispatch(change(form_name, `${account_preffix}.${coordinator}._destroy`, true))
        : fields.remove(index);
    },
    [fields],
  );
  const loadCourseOptions = React.useCallback(async () => {
    try {
      const courses = await dispatch(
        FETCH_COURSES.request({
          params: {
            filters: {
              'page[size]': '30',
            },
          },
        }),
      );
      const {
        data: { data },
      } = courses;
      const formattedCourses = data.map((item) => {
        return {
          value: ~~item.id,
          label: item.attributes.name,
        };
      });
      setCourseOptions(formattedCourses);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar as opções de curso',
        }),
      );
    }
  }, []);

  React.useEffect(() => {
    loadCourseOptions()
  }, [])
  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar curso</span>
        <AddButton onClick={addCordinator} tooltip='Adicionar curso' />
      </div>
      {fields.map((coordinator, index) => {
        const currentCoordinator = fields.get(index) as NestedCoordinatorAttributes;
        if(currentCoordinator._destroy){
          return null
        }
        return (
          <FormSection name={coordinator} key={coordinator}>
            <div
              css={css`
                display: flex;
                & > div:first-of-type {
                  flex-basis:100%;
                }
              `}
>
              <Field
                name='course_id'
                component={SelectComponent}
                options={formatted_options}
                label={'Curso'}
                placeholder={'Selecione o curso'}
                validate={[validation.required]}
              />
              <DeleteButton
                tooltip='Remover coordenador deste curso'
                onClick={() => deleteCoordinator(currentCoordinator, coordinator, index)}
              />
            </div>
          </FormSection>
        );
      })}
    </div>
  );
};

const renderAccountForm = (props: WrappedFieldArrayProps) => {
  const { fields } = props;
  return (
    <div>
      {fields.map((account_attribute) => {
        return (
          <FormSection name={account_attribute} key={account_attribute}>
            <FieldArray account_preffix={account_attribute} name='coordinators_attributes' component={renderCoordinatorsForm} validate={[validation.required]} />
          </FormSection>
        );
      })}
    </div>
  );
};

const CoordinatorsForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  initialValues: CoordinatorAttributes;
  setInitialValues: React.Dispatch<React.SetStateAction<Partial<CoordinatorFormData>>>;
  handleSelectUser: (user_option: UserOptionsData) => Promise<void>
  allowUserUpdate: boolean
}) => {
  const {
    handleSubmit,
    onSubmit,
    initialValues,
    setInitialValues,
    handleSelectUser,
    allowUserUpdate
  } = props;
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company: companyId, profile, company_descendants },
    account: { companies },
  } = state;
  const formValues = formValueSelector(form_name);
  const companyIdValue = formValues(state, 'company_id') as string;
  const userIdValue = formValues(state, 'user_id') as string;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const company_id_to_use = is_above_school_director ? companyIdValue : companyId;

  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: companyId,
    company_descendants,
    companies,
  });
  const mustSelectCompanyId = is_above_school_director && !initialValues?.id;
  const userFormData = [
    'document_number',
    'name',
    'email',
    'additional_data.personal_email',
    'phone',
    'gender',
    'birthdate',
    'nationality_country',
    'nationality_state',
    'nationality_city',
  ] as UserFormFields[];
  const handleClearDocumentField = () => {
    if (initialValues.id) {
      setInitialValues({ ...defaultCoordinatorFormInitialValues(company_id_to_use) });
      dispatch(reset(form_name));
      dispatch(change(form_name, 'document_number', null));
    } else {
      dispatch(change(form_name, 'document_number', null));
    }
  };
  return (
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar coordenador</span>
      <form css={useStyles.form}>
        {mustSelectCompanyId && (
          <>
            <span style={{ justifySelf: 'self-start' }} className='subtitle-one'>
              {' '}
              Unidade{' '}
            </span>
            <Field
              label={'Unidade'}
              placeholder={'Selecionar unidade'}
              name='company_id'
              component={SelectComponent}
              options={companies_options as DefaultOptionType[]}
              validate={[...(mustSelectCompanyId ? [validation.required] : [])]}
            />
          </>
        )}
        {companyIdValue ? (
          <>
            <div className='user-form'>
              <UserForm
                data={userFormData}
                form_name={form_name}
                handleClearDocumentField={handleClearDocumentField}
                handleSelectUser={handleSelectUser}
                company_id_to_use={company_id_to_use}
                disabled_document_number={!isEmpty(userIdValue)}
                document_clearable={!isEmpty(userIdValue)}
                disable_all={!allowUserUpdate}
              />
            </div>
            <FieldArray component={renderAccountForm} name='accounts_attributes' />
            <div css={useStyles.buttons}>
              <Link to={Paths.COORDINATORS_LIST} className='red small'>
                <span> Cancelar </span>
              </Link>
              <button onClick={handleSubmit(onSubmit)} className='blue small'>
                <span> Salvar coordenador </span>
              </button>
            </div>
          </>
        ) : null}
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: 'coordinatorForm',
  }),
)(CoordinatorsForm);
