import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { colors, CompositionPeriodAttributes, ExamPlaceableType, ExamPlacementAttributes } from '../../utils/constants';
import { css } from '@emotion/react';
import TooltipButton from '../shared/TooltipButton';
import PenIcon from '../icon/PenIcon';
import { Collapse } from '@mui/material';
import ExamPlacementFormContainer from '../../containers/ExamPlacementsFormContainer';
import { FETCH_COMPOSITION_PERIOD } from '../../store/composition_periods';
import { useDispatch } from 'react-redux';
import { concat, isEmpty, map, max, orderBy, uniq } from 'lodash';
import { DELETE_EXAM_PLACEMENT, ExamPlacementJson, FETCH_EXAM_PLACEMENT } from '../../store/exam_placements';
import Loading from '../loading/Loading';
import { error, warning } from 'react-notification-system-redux';
import { FETCH_SUBJECT_PERIOD } from '../../store/subject_periods';
import { IconModal } from '../modal/Modal';
import TrashIcon from '../icon/TrashIcon';

const TableCss = css`
  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const ExamPlacementsTable = ({
  exam_placeable_type,
  exam_placeable_id,
  exam_placeable_name,
  composition_period,
}: {
  exam_placeable_type: ExamPlaceableType;
  exam_placeable_id: number;
  exam_placeable_name: string;
  composition_period: CompositionPeriodAttributes;
}) => {
  const [create, setCreate] = React.useState(false);
  const [editingExamPlacement, setEditingExamPlacement] = React.useState<number[]>([]);
  const [exam_placements, setExamPlacements] = React.useState<ExamPlacementAttributes[]>([]);
  const [loading, setLoading] = React.useState(true);
  const composition_period_id = composition_period.id
  const dispatch = useDispatch();

  const fetchExamPlacements = React.useCallback(async () => {
    let exam_placements_attributes = [] as ExamPlacementAttributes[];
    try {
      const response = await dispatch(
        FETCH_COMPOSITION_PERIOD.request({
          id: composition_period_id,
          params: {
            filters: {
              include: 'exam_placements',
            },
          },
        }),
      );
      const {
        data: { included = [] },
      } = response;
      exam_placements_attributes = map(
        included.filter((incl) => incl.type === 'exam_placements') as ExamPlacementJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      if (exam_placeable_type === ExamPlaceableType.SUBJECT_PERIOD) {
        const response = await dispatch(
          FETCH_SUBJECT_PERIOD.request({
            id: exam_placeable_id,
            params: {
              filters: {
                include: 'exam_placements',
              },
            },
          }),
        );
        const {
          data: { included = [] },
        } = response;
        exam_placements_attributes = concat(
          exam_placements_attributes,
          map(included.filter((incl) => incl.type === 'exam_placements') as ExamPlacementJson[], (item) => ({
            id: item.id,
            ...item.attributes,
          })),
        );
      }
      setExamPlacements(exam_placements_attributes);
      setLoading(false);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar avaliações',
        }),
      );
      setLoading(false);
    }
  }, [exam_placeable_id, exam_placeable_type]);

  const init = async () => {
    setLoading(true);
    await fetchExamPlacements();
    setLoading(false);
  };

  const verifyIfCanDeleteExamPlacement = async (props: { handleClose: () => void; id: string }) => {
    const { handleClose, id } = props;
    try {
      const response = await dispatch(
        FETCH_EXAM_PLACEMENT.request({
          id,
          params: {
            filters: {
              include: ['operations'].join(','),
            },
          },
        }),
      );
      const {
        data: { included },
      } = response;
      if (!isEmpty(included)) {
        const operations = uniq(map(included, (item) => item.attributes.formula_name));
        dispatch(
          warning({
            message: `Não é possível excluir avaliação pois ela está sendo usada nas formulas: ${operations.join(',')}`,
          }),
        );
        handleClose();
      }
    } catch (err) {
      dispatch(
        error({
          message: `Erro ao verificar avaliação`,
        }),
      );
      handleClose();
    }
  };

  const deleteExamPlacement = async (props: {
    id: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleClose: () => void;
  }) => {
    const { id, handleClose } = props;
    setLoading(true);
    try {
      await dispatch(
        DELETE_EXAM_PLACEMENT.request({
          id,
        }),
      );
      setLoading(false);
      handleClose();
      await init();
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao remover avaliação',
        }),
      );
    }
  };

  React.useEffect(() => {
    init();
  }, []);
  const handleSavingWhenCreate = async () => {
    setCreate(false);
    await init();
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <Table css={TableCss} size='small' aria-label='exam_placements'>
      <TableHead>
        <TableRow>
          <TableCell align='left'>Ordem</TableCell>
          <TableCell align='center'>Nome</TableCell>
          <TableCell align='center'>Origem</TableCell>
          <TableCell align='right'>
            <button
              disabled={editingExamPlacement.length > 0}
              onClick={() => setCreate(!create)}
              className='green small'
            >
              <span> Criar Avaliação </span>
            </button>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={create} timeout='auto' unmountOnExit>
              <ExamPlacementFormContainer
                latest_order={max(exam_placements.map((item) => item.order)) as number}
                exam_placeable_id={exam_placeable_id}
                exam_placeable_type={exam_placeable_type}
                close_form={() => setCreate(false)}
                onSave={handleSavingWhenCreate}
                exam_placeable_name={exam_placeable_name}
                not_allowed_orders={exam_placements.filter(ep => ep.exam_placeable_type === exam_placeable_type).map(item => item.order.toString())}
              />
            </Collapse>
          </TableCell>
        </TableRow>
        {orderBy(exam_placements, 'order', 'asc').map((exam_placement) => {
          const toggle_form = () => {
            setEditingExamPlacement((current) => {
              if (current.includes(~~exam_placement.id)) {
                return current.filter((item) => item !== ~~exam_placement.id);
              } else {
                return current.concat(~~exam_placement.id);
              }
            });
          };
          return (
            <React.Fragment key={exam_placement.id}>
              <TableRow>
                <TableCell align='left'>{exam_placement.order}</TableCell>
                <TableCell align='center'>{exam_placement.name}</TableCell>
                <TableCell align='center'>{exam_placement.exam_placeable_type === ExamPlaceableType.COMPOSITION_PERIOD ? composition_period.name : exam_placeable_name}</TableCell>
                <TableCell align='right'>
                  <TooltipButton
                    tooltipProps={{
                      title: editingExamPlacement.includes(~~exam_placement.id)
                        ? 'Cancelar edição'
                        : 'Editar avaliação',
                      style: {
                        ...(exam_placeable_type === ExamPlaceableType.SUBJECT_PERIOD &&
                        exam_placement.exam_placeable_type === ExamPlaceableType.COMPOSITION_PERIOD
                          ? { display: 'none' }
                          : {}),
                      },
                    }}
                    Icon={PenIcon}
                    iconProps={{ style: { color: colors.blue } }}
                    iconButtonProps={{
                      disabled:
                        create ||
                        (editingExamPlacement.length > 0 && !editingExamPlacement.includes(~~exam_placement.id)),
                      onClick: toggle_form,
                    }}
                  />
                  <IconModal
                    icon={TrashIcon}
                    onConfirm={(props) => deleteExamPlacement({ ...props, id: exam_placement.id })}
                    tooltipText='Remover avaliação'
                    title='Remover avaliação'
                    message={`Deseja remover avaliação ${exam_placement.name}?`}
                    onOpen={(args) =>
                      verifyIfCanDeleteExamPlacement({
                        ...args,
                        id: exam_placement.id,
                      })
                    }
                    tooltipProps={{
                      style: {
                        ...(exam_placeable_type === ExamPlaceableType.SUBJECT_PERIOD &&
                        exam_placement.exam_placeable_type === ExamPlaceableType.COMPOSITION_PERIOD
                          ? { display: 'none' }
                          : {}),
                      },
                    }}
                    disabled={create || editingExamPlacement.length > 0}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                  <Collapse in={editingExamPlacement.includes(~~exam_placement.id)} timeout='auto' unmountOnExit>
                    <ExamPlacementFormContainer
                      exam_placeable_id={exam_placeable_id}
                      exam_placement_id={exam_placement.id}
                      exam_placeable_type={exam_placeable_type}
                      exam_placeable_name={exam_placeable_name}
                      not_allowed_orders={exam_placements.filter(ep => ep.order !== exam_placement.order && ep.exam_placeable_type === exam_placeable_type).map(item => item.order.toString())}
                      close_form={toggle_form}
                      onSave={async () => {
                        toggle_form();
                        await init();
                      }}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ExamPlacementsTable;
