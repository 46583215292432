/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, RequestParams, RoomStudentRoomStatusEnum } from '../../utils/constants'

export interface RegistrationClosureAttrs {
  closure_date: string
  details: string
  last_date: string
  penalty: number
  reason: string
  refund_registration_fee: boolean
  registration_product_id: number
  request_date: string
  status: string
  room_status: RoomStudentRoomStatusEnum | null
}

export type RegistrationClosureJson = JsonFormat<'registration_closures', RegistrationClosureAttrs>

export interface FetchRegistrationClosurePayload {
  id: string
  params?: RequestParams
}

export interface DeleteRegistrationClosurePayload {
  id: string
}
export interface CreateRegistrationClosurePayloadData {
  registration_product_id: string
  status: string
  reason: string
  room_status: string | null
  closure_date: string
  details: string
}
export interface CreateRegistrationClosurePayload {
  data: CreateRegistrationClosurePayloadData
}

export interface UpdateRegistrationClosurePayload extends CreateRegistrationClosurePayload {
  id: string
}

export type FetchRegistrationClosureResponse = JsonResponseFormat<RegistrationClosureJson[]>


export const CREATE_REGISTRATION_CLOSURE = createPromiseAction('registration_closure: CREATE_REGISTRATION_CLOSURE')<CreateRegistrationClosurePayload, FetchRegistrationClosureResponse, any>()
export const FETCH_REGISTRATION_CLOSURE = createPromiseAction('regitration_closure: FETCH_REGISTRATION_CLOSURE')<FetchRegistrationClosurePayload, FetchRegistrationClosureResponse, any>()
export const UPDATE_REGISTRATION_CLOSURE = createPromiseAction('registration_closure: UPDATE_REGISTRATION_CLOSURE')<UpdateRegistrationClosurePayload, FetchRegistrationClosureResponse, any>()
export const DELETE_REGISTRATION_CLOSURE = createPromiseAction('registration_closure: DELETE_REGISTRATION_CLOSURE')<DeleteRegistrationClosurePayload, AxiosResponse, any>()