/* eslint-disable camelcase */
import React from 'react'
import { compose } from 'redux'
import { change, Field, FieldArray, FormSection, reduxForm, WrappedFieldArrayProps } from 'redux-form'
import { colors, compositionPeriodKindOptions, NestedCompositionPeriodAttributes, validation } from '../../utils/constants'
import InputComponent from '../input/form/input'
import { useDispatch } from 'react-redux'
import { css } from '@emotion/react'
import AddButton from '../shared/AddButton'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import DeleteButton from '../shared/DeleteButton'
import DatePickerComponent from '../input/form/datepicker'
import { isEmpty } from 'lodash'
import SelectComponent from '../input/form/select'
const form_name = 'compositionForm'

const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #fdfeff;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

const AccordionCss = css`
  background: inherit;
  box-shadow: none;
  border-bottom: 1px solid ${colors.grayBlue}
`
const AccordionSummaryCss = css`
  display: flex;
  align-items: center;
  & .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    width: inherit;
  }
`


interface CustomCompositionPeriodFormProps {
  composition_period: string;
  current_composition_period: NestedCompositionPeriodAttributes;
  forceUpdate: () => Promise<void>;
}

const CompositionPeriodsFormContainer = (props: CustomCompositionPeriodFormProps) => {
  const { composition_period, current_composition_period, forceUpdate } = props;
  const starts_at = current_composition_period.starts_at as string | Date;
  const ends_at = current_composition_period.ends_at as string | Date;
  const insert_starts_at = current_composition_period.insert_starts_at as string | Date;
  const insert_ends_at = current_composition_period.insert_ends_at as string | Date;
  return (
    <FormSection name={composition_period}>
      <div style={{ width: '100%' }}>
        <Field
          name='name'
          component={InputComponent}
          label={'Nome'}
          placeholder={'Insira o nome do periodo'}
          validate={[validation.required]}
        />
        <Field
          name='kind'
          component={SelectComponent}
          options={compositionPeriodKindOptions}
          label={'Tipo de período'}
          placeholder={'Selecione o tipo de período'}
          validate={[validation.required]}
        />
        <Field
          label='Ordem'
          component={InputComponent}
          name='order'
          onlyNumbers
          placeholder={'Definir Ordem do período'}
          validate={[validation.required]}
        />
        <Field
          name={`starts_at`}
          label={'Início do período*'}
          placeholder={'01/01/2024'}
          onChange={forceUpdate}
          component={DatePickerComponent}
          datePickerProps={{
            ...(ends_at && { maxDate: ends_at instanceof Date ? ends_at : new Date(ends_at) }),
            showDropdownIcon: true,
          }}
          validate={[validation.dateRequired]}
        />
        <Field
          name={`ends_at`}
          label={'Fim do período*'}
          placeholder={'31/12/2024'}
          component={DatePickerComponent}
          onChange={forceUpdate}
          datePickerProps={{
            ...(starts_at && { minDate: starts_at instanceof Date ? starts_at : new Date(starts_at) }),
            showDropdownIcon: true,
          }}
          validate={[validation.dateRequired]}
        />
        <Field
          name={`insert_starts_at`}
          label={'Início do período de lançamento das notas*'}
          placeholder={'01/01/2024'}
          component={DatePickerComponent}
          onChange={forceUpdate}
          datePickerProps={{
            ...(insert_ends_at && {
              maxDate: insert_ends_at instanceof Date ? insert_ends_at : new Date(insert_ends_at),
            }),
            showDropdownIcon: true,
          }}
          validate={[validation.dateRequired]}
        />
        <Field
          name={`insert_ends_at`}
          label={'Fim do período de lançamento das notas*'}
          placeholder={'31/12/2024'}
          component={DatePickerComponent}
          onChange={forceUpdate}
          datePickerProps={{
            ...(insert_starts_at && {
              minDate: insert_starts_at instanceof Date ? insert_starts_at : new Date(insert_starts_at),
            }),
            showDropdownIcon: true,
          }}
          validate={[validation.dateRequired]}
        />
      </div>
    </FormSection>
  );
};


interface RenderCompositionPeriodProps extends WrappedFieldArrayProps {
  forceUpdate: () => Promise<void>;
}

const renderCompositionPeriods = (props: RenderCompositionPeriodProps) => {
  const dispatch = useDispatch();
  const { fields, forceUpdate } = props;
  const allCompositionPeriods = fields.getAll() || [] as NestedCompositionPeriodAttributes[];
  const validCompositionPeriods = allCompositionPeriods?.filter(item => isEmpty(item._destroy)) || []
  const addCompositionPeriod = React.useCallback(() => {
    fields.push({
      order: validCompositionPeriods.length + 1
    } as NestedCompositionPeriodAttributes);
  }, [fields]);
  const deleteCompositionPeriod = React.useCallback(
    (currentCompositionPeriod: NestedCompositionPeriodAttributes, composition_period: string, index: number) => {
      currentCompositionPeriod.id
        ? dispatch(change(form_name, `${composition_period}._destroy`, true))
        : fields.remove(index);
    },
    [fields],
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
          & div[id$='subject_id'] {
            width: 100%;
          }
        `}
      >
        <span>Adicionar Periodo</span>
        <AddButton onClick={addCompositionPeriod} tooltip='Adicionar período' />
      </div>
      {fields.map((composition_period, index) => {
        const currentCompositionPeriod = allCompositionPeriods[index] as NestedCompositionPeriodAttributes;
        if (currentCompositionPeriod._destroy) {
          return null;
        }
        return (
          <Accordion TransitionProps={{ unmountOnExit: true }} key={composition_period} css={AccordionCss}>
            <AccordionSummary css={AccordionSummaryCss}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <ExpandMore />
                <span>{currentCompositionPeriod.name || 'Novo Período'}</span>
              </div>
              <DeleteButton
                onClick={() => deleteCompositionPeriod(currentCompositionPeriod, composition_period, index)}
                tooltip='Remover período'
              />
            </AccordionSummary>
            <AccordionDetails>
              <div key={composition_period} style={{ display: 'flex' }}>
                <CompositionPeriodsFormContainer
                  composition_period={composition_period}
                  current_composition_period={currentCompositionPeriod}
                  forceUpdate={forceUpdate}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};


const CompositionForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  close_form?: () => void
}) => {
  const { handleSubmit, onSubmit, close_form } = props
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(async () => {
    updateState({});
  }, []);
  return(
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar composição</span>
      <form css={useStyles.form}>
        <div style={{ width: '100%' }}>
          <Field
            name='name'
            component={InputComponent}
            label={'Nome'}
            placeholder={'Insira o nome da composição'}
            validate={[validation.required]}
          />
          <FieldArray
            name='composition_periods_attributes'
            component={renderCompositionPeriods}
            forceUpdate={forceUpdate}
          />
        </div>
        <div css={useStyles.buttons}>
          <button
            onClick={(e) => {
              e.preventDefault()
              close_form?.()
            }}
            className='red small'>
            <span> Cancelar </span>
          </button>
          <button onClick={handleSubmit(onSubmit)} className='blue small'>
            <span> Salvar composição </span>
          </button>
        </div>
      </form>
    </div>
  )
}

export default compose<any>(
  reduxForm({
      form: form_name,
  }),
)(CompositionForm);
