/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { colors, WalletCreditAttributes } from '../../utils/constants';
import { formatToCurrency, parseText } from '../../utils/functions';
import { sum } from 'lodash';
import { Tooltip } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { css } from '@emotion/react';

export const useStyles = {
  table: css`
    & tbody > tr:nth-child(even) {
      background-color: ${colors.darkGrayBlue} !important;
    }

    & tbody > tr:nth-child(odd) {
      background-color: ${colors.grayBlue} !important;
    }

    & .include {
      display: flex;
      margin-left: auto;
      margin-bottom: 1rem;
    }

    & tfoot {
      background: white;

      & td {
        font-weight: bold;
        font-size: 1rem;
      }

      & td:last-of-type {
        color: ${colors.blue};
      }
    }

    & .MuiTableContainer-root {
      background: inherit;
      box-shadow: none;

      & .MuiTableCell-root {
        border-bottom: 5px solid ${colors.lightBlue};
      }

      & th {
        color: ${colors.darkGrayBlue};
      }

      & td {
        color: ${colors.darkBlue};

        & svg {
          color: ${colors.darkGrayBlue};
          cursor: pointer;
        }
      }

      & td.MuiTableCell-footer {
        border-bottom: none;
      }
    }
  `,
};


const WalletCreditsBody = ({ credits }: { credits: WalletCreditAttributes[] }) => {
  return (
    <TableBody>
      {credits.map((credit) => {
        const description = parseText(credit.description, 60);
        const credit_value = sum([credit.credit_in, -credit.credit_out]);
        return (
          <React.Fragment key={credit.id}>
            <TableRow>
              <TableCell
                align='center'
                style={{ backgroundColor: credit_value < 0 ? colors.lightRed : credit_value > 0 ? colors.green : 'initial', color: 'white' }}
              >
                {formatToCurrency(credit_value)}
              </TableCell>
              <TableCell align='center'>{credit.invoice_code}</TableCell>
              <TableCell align='center'>
                <Tooltip title={credit.description}>
                  <span>{description}</span>
                </Tooltip>
              </TableCell>
              <TableCell align='center'>{format(parseISO(credit.created_at), 'dd-MM-yyyy HH:mm')}</TableCell>
              <TableCell align='center'>{credit.created_by_user?.name}</TableCell>
            </TableRow>
          </React.Fragment>
        );
      })}
    </TableBody>
  );
};

const WalletCredits = ({ credits }: { credits: WalletCreditAttributes[] }) => {
  return (
    <Table css={useStyles.table} size='small' aria-label='wallet-credit'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Valor</TableCell>
          <TableCell align='center'>Código da fatura</TableCell>
          <TableCell align='center'>Descrição</TableCell>
          <TableCell align='center'>Data</TableCell>
          <TableCell align='center'>Criado por</TableCell>
        </TableRow>
      </TableHead>
      <WalletCreditsBody credits={credits} />
    </Table>
  );
};

export default WalletCredits;
