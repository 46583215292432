/* eslint-disable camelcase */
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { Theme } from '@mui/system';
import IconButton from '@mui/material/IconButton';

import {
  colors,
  Paths,
  FormattedRegistrationViewRegData,
  PaginationType,
  CompanyAttributes,
} from '../../utils/constants';
import { convertToCNPJ, convertToCPF, evaluate_permissions, parseText } from '../../utils/functions';
import PenIcon from '../icon/PenIcon';
import SearchIcon from '../icon/SearchIcon';
import { DeleteItemModal } from '../modal/Modal';
import TablePaginationActions from '../shared/TablePaginationActions';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { Tooltip } from '@mui/material';
import AddCircle from '@mui/icons-material/AddCircle';
import { get } from 'lodash';
import { css, useTheme } from '@emotion/react';

const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    table: css`
      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }

        & .pagination-actions {
          display: flex;
          margin-left: 1rem;
          gap: 0.5rem;

          & button {
            height: 2rem;
            width: 2rem;
            padding: unset;

            & svg {
              height: 1rem;
            }
          }

          & button:not(.Mui-disabled) {
            background-color: ${colors.darkBlue};

            & svg {
              color: white;
            }
          }
        }
      }
    `,
  };
};

const RegistrationsTable = ({
  registrations,
  deleteRegistrationMethod,
  pagination,
  handlePageChange,
  handleChangePageSize,
}: {
  registrations: FormattedRegistrationViewRegData[];
  deleteRegistrationMethod: (id: string) => Promise<void>;
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const {
    auth: { profile, company_descendants },
  } = useSelector((state: RootState) => state);
  const classes = useStyles();
  const history = useHistory();
  const descendants = company_descendants as CompanyAttributes[];
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role)
  const columns = isTabletOrMobile
    ? ['Matrícula']
    : [...(is_above_school_director ? ['Unidade'] : []), 'Código', 'Nome', 'CPF', 'Telefone', 'Responsável', 'Ações'];

  return (
    <div css={classes.table}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {registrations.map((row) => {
              const {
                status,
                account_id,
                user: { name, phone, document_number: document, user_id },
                representatives,
                classroom,
                registration_products,
                account,
                ...rest
              } = row;
              const registration_company = descendants.find(
                (item) => ~~item.id === account.company_id,
              ) as CompanyAttributes;
              const canDeleteRegistration = registration_products.length === 0
              const representative =
                get(representatives, 'pedagogical_financial_representatives[0][name]') ||
                get(representatives, 'financial_representatives[0][name]') ||
                get(representatives, 'pedagogical_representatives[0][name]') ||
                '';
              const formattedDocument =
                document && document?.length === 11
                  ? convertToCPF(document)
                  : document?.length === 14
                  ? convertToCNPJ(document)
                  : document;
              return (
                <>
                  <TableRow key={row.id}>
                    {isTabletOrMobile ? (
                      <TableCell>
                        <div
                          style={{
                            display: 'flex',
                            gap: '0.5rem',
                            flexWrap: 'wrap',
                          }}
                        >
                          {is_above_school_director && <span>
                            <strong>Unidade:</strong>
                            {registration_company.name}
                          </span>}
                          <span>
                            <strong>Código:</strong>
                            {row.code}
                          </span>
                          {name && (
                            <span>
                              <strong>Nome:</strong>
                              {name}
                            </span>
                          )}
                          {formattedDocument && (
                            <span>
                              <strong>Documento:</strong>
                              {formattedDocument}
                            </span>
                          )}
                          {phone && (
                            <span>
                              <strong>Telefone:</strong>
                              {phone}
                            </span>
                          )}
                          {!!representative && (
                            <span>
                              <strong>Responsável:</strong>
                              {representative}
                            </span>
                          )}
                        </div>
                      </TableCell>
                    ) : (
                      <>
                        {is_above_school_director && <TableCell> {registration_company.name} </TableCell>}
                        <TableCell>{row.code}</TableCell>
                        <TableCell>{parseText(name as string, 40)}</TableCell>
                        <TableCell>{parseText(formattedDocument as string, 14)}</TableCell>
                        <TableCell> {phone} </TableCell>
                        <TableCell>{representative && parseText(representative, 40)}</TableCell>
                      </>
                    )}
                    <TableCell>
                      <Tooltip title='Visualizar detalhes da matrícula'>
                        <IconButton
                          onClick={() => {
                            history.push(Paths.REGISTRATION_DETAILS, {
                              user_id: user_id,
                              registration_id: rest.id,
                            });
                          }}
                        >
                          <SearchIcon style={{ color: colors.darkBlue }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Editar detalhes do aluno'>
                        <IconButton
                          onClick={() => {
                            history.push(Paths.REGISTRATIONS_FORM, {
                              user_id: user_id,
                              registration_id: rest.id,
                            });
                          }}
                        >
                          <PenIcon style={{ color: colors.blue }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Adicionar produto'>
                        <IconButton
                          onClick={() => {
                            history.push(Paths.REGISTRATIONS_FORM, {
                              user_id: user_id,
                              registration_id: rest.id,
                              initialStep: 2,
                            });
                          }}
                        >
                          <AddCircle style={{ color: colors.green }} />
                        </IconButton>
                      </Tooltip>
                      {canDeleteRegistration && (
                        <DeleteItemModal
                          tooltipText='Excluir matrícula'
                          message={`Deseja excluir a matrícula de ${name} ?`}
                          onConfirm={async ({ setLoading, handleClose }) => {
                            setLoading(true);
                            await deleteRegistrationMethod(rest.id);
                            setLoading(false);
                            handleClose();
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                colSpan={columns?.length + 1}
                count={pagination.totalCount}
                rowsPerPage={pagination.pageSize}
                page={pagination.pageNumber}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangePageSize}
                labelRowsPerPage='Itens Por página'
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                }
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RegistrationsTable;
