import React from 'react';

import { Checkbox, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { every, filter, isEqual } from 'lodash';
import { Theme } from '@mui/system';
import {
  colors,
  DefaultOptionType,
  PaymentOptionAttributes,
  PaymentOptionPortionsEnum,
  semiAnualPortionsConfig,
  UpdateRegistrationProductFormAttributes,
} from '../../utils/constants';
import { Field, change } from 'redux-form';
import { formatToCurrency } from '../../utils/functions';
import { useDispatch } from 'react-redux';
import { css, useTheme } from '@emotion/react';

const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    table: css`
      background: #fdfefe;
      padding: 1rem;
      border-radius: 1rem;

      & .delete {
        color: ${colors.lightRed};
        cursor: pointer;

        &:hover {
          color: #cc8080;
        }
      }

      & th {
        color: ${colors.darkGrayBlue};
        border-bottom: none;
      }

      & .MuiPaper-root {
        flex-grow: 1;
      }

      & span.empty {
        color: ${colors.lightRed};
      }

      & span.empty:empty:before {
        content: '\\200b';
      }

      & tbody {
        position: relative;
      }

      & td {
        padding: 2px;
        color: ${colors.darkBlue};
        font-size: 1rem;
        border-bottom: 1px solid ${colors.grayBlue};

        ${theme.breakpoints?.down('lg')} {
          font-size: 0.75rem;
        }
      }

      & .contract {
        background: #fdfefe;

        & td:last-of-type,
        th:last-of-type {
          border-left: 1px solid ${colors.grayBlue};
          border-right: 1px solid ${colors.grayBlue};
        }

        & th:last-of-type {
          border-top: 1px solid ${colors.grayBlue};
        }

        & tfoot {
          & td:last-of-type {
            color: ${colors.green};
            padding-right: 0.5rem;
          }

          & td:first-of-type {
            padding-left: 0.5rem;
          }

          & > tr {
            border: 1px solid ${colors.grayBlue};
            border-radius: 0px 0px 8px 8px;
            background: white;

            & > td {
              padding: 0.5rem 0;
              font-weight: bold;
            }
          }
        }
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & td {
          color: ${colors.darkBlue};

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};

const PaymentDataTableBody = ({
  punctuality_portions_rows,
  getValueForRow,
}: {
  punctuality_portions_rows: DefaultOptionType[];
  getValueForRow: (portion: DefaultOptionType) => {
    final: number;
    financial: number;
    final_financial: number;
  };
}) => {
  return (
    <Field
      name={`punctuality_discount_portions`}
      component={({ input }: any) => {
        return (
          <TableBody>
            {punctuality_portions_rows.map((item) => {
              const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                const arr = [...input.value];
                if (event.target.checked) {
                  arr.push(item.value);
                } else {
                  arr.splice(arr.indexOf(item.value), 1);
                }
                arr.sort((a, b) => a - b);
                input.onBlur(arr);
                return input.onChange(arr);
              };
              const selected = input.value.includes(item.value);
              const { final, financial, final_financial } = getValueForRow(item);
              const disabled = final === 0;
              return (
                <TableRow key={item.value}>
                  <TableCell align='left'>{item.label}</TableCell>
                  <TableCell align='center'>{formatToCurrency(final)}</TableCell>
                  <TableCell
                    align='center'
                    style={{
                      color: !selected ? '#bfe5f7' : colors.blue,
                    }}
                  >
                    {formatToCurrency(financial)}
                  </TableCell>
                  <TableCell align='center'>
                    <Checkbox
                      name={`${input.name}[${item.value}]`}
                      onChange={handleChange}
                      disabled={disabled}
                      checked={selected}
                    />
                  </TableCell>
                  <TableCell align='center'>{formatToCurrency(final_financial)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        );
      }}
    />
  );
};

const PaymentDataTable = (props: {
  current_registration_product: UpdateRegistrationProductFormAttributes;
  current_payment_option: PaymentOptionAttributes;
  service_range: DefaultOptionType[];
  getValuesForMonths: (months: number[]) => {
    final: number;
    financial: number;
    final_financial: number;
    initial: number;
    subsidy: number;
  };
}) => {
  const dispatch = useDispatch();
  const { current_registration_product, current_payment_option, service_range, getValuesForMonths } = props;
  const { provision_months } = current_registration_product;
  const classes = useStyles();
  const number_of_portions =
    current_payment_option.portions === PaymentOptionPortionsEnum.MONTHLY
      ? current_registration_product.provision_months.filter((item) => item !== 0).length
      : current_payment_option.portions === PaymentOptionPortionsEnum.SEMI_ANUALY
      ? 2
      : 1;
  const punctuality_portions_rows =
    current_payment_option.portions === PaymentOptionPortionsEnum.MONTHLY
      ? current_registration_product.provision_months.map(
          (item) => service_range.find((range) => range.value === item) as DefaultOptionType,
        )
      : [
          ...(current_registration_product.provision_months.includes(0)
            ? [service_range.find((range) => range.value === 0) as DefaultOptionType]
            : []),
          ...Array(number_of_portions)
            .fill(1)
            .map((_, index) => {
              return {
                label: `Parcela ${index + 1}`,
                value: index + 1,
              };
            }),
        ];

  const getValuesForSemiAnualPortion = (portion: number) => {
    return semiAnualPortionsConfig.reduce(
      (acc, config) => {
        if (config.portion === portion) {
          const desired_months = config.months.filter((month) => provision_months.includes(month));
          return getValuesForMonths(desired_months);
        }
        return acc;
      },
      { final: 0, financial: 0, final_financial: 0 },
    );
  };
  const checkableMonths = filter(punctuality_portions_rows, (portion) => {
    if (portion.value === 0 || current_payment_option.portions === PaymentOptionPortionsEnum.MONTHLY) {
      return getValuesForMonths([portion.value as number]).final > 0;
    } else if (current_payment_option.portions === PaymentOptionPortionsEnum.YEARLY) {
      return getValuesForSemiAnualPortion(portion.value as number).final > 0;
    } else {
      return getValuesForMonths(provision_months).final > 0;
    }
  });
  const allMonthsChecked = checkableMonths.length
    ? every(checkableMonths, (item) =>
        current_registration_product.punctuality_discount_portions.includes(item.value as number),
      )
    : false;

  const checkAllMonths = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const new_punctuality_discount_portions = e.target.checked
        ? punctuality_portions_rows.map((item) => item.value)
        : [];
      dispatch(change('registration-productstep', 'punctuality_discount_portions', new_punctuality_discount_portions));
    },
    [current_registration_product],
  );

  const getValueForRow = (portion: DefaultOptionType) => {
    return portion.value === 0 || current_payment_option.portions === PaymentOptionPortionsEnum.MONTHLY
      ? getValuesForMonths([portion.value as number])
      : current_payment_option.portions === PaymentOptionPortionsEnum.SEMI_ANUALY
      ? getValuesForSemiAnualPortion(portion.value as number)
      : getValuesForMonths(provision_months.filter((item) => item !== 0));
  };

  const { final, financial, final_financial } = punctuality_portions_rows.reduce(
    (acc, portion) => {
      const { final, financial, final_financial } = getValueForRow(portion);
      return {
        final: acc.final + final,
        financial: acc.financial + financial,
        final_financial: acc.final_financial + final_financial,
      };
    },
    { final: 0, financial: 0, final_financial: 0 },
  );

  React.useEffect(() => {
    if (
      current_registration_product.registration_product_subsidies_attributes.filter((item) => !item._destroy).length > 0
    ) {
      const to_be_changed = punctuality_portions_rows.filter((portion) => {
        const { final_financial } = getValueForRow(portion);
        return final_financial > 0;
      });
      if (!isEqual(to_be_changed, punctuality_portions_rows)) {
        dispatch(
          change(
            'registration-productstep',
            'punctuality_discount_portions',
            to_be_changed.map((item) => item.value),
          ),
        );
      }
    }
  }, [current_registration_product.registration_product_subsidies_attributes]);
  return (
    <>
      <span className='subtitle-one'>Desconto Condicional</span>
      <div css={classes.table}>
        <TableContainer>
          <Table className='contract'>
            <TableHead>
              <TableRow>
                <TableCell align='left'>Parcelas</TableCell>
                <TableCell align='center'>Valor Contrato</TableCell>
                <TableCell align='center'>Desconto Condicional</TableCell>
                <TableCell align='center'>
                  <span>Meses com Desconto</span>
                  <Checkbox
                    disabled={checkableMonths.length === 0}
                    checked={allMonthsChecked}
                    onChange={checkAllMonths}
                  />
                </TableCell>
                <TableCell align='center'>Valor Pagamento</TableCell>
              </TableRow>
            </TableHead>
            <PaymentDataTableBody
              punctuality_portions_rows={punctuality_portions_rows}
              getValueForRow={getValueForRow}
            />
            <TableFooter>
              <TableRow>
                <TableCell align='left'>Valor total:</TableCell>
                <TableCell align='center'>{formatToCurrency(final)}</TableCell>
                <TableCell align='center' style={{ color: colors.blue }}>
                  {formatToCurrency(financial)}
                </TableCell>
                <TableCell />
                <TableCell align='center'>{formatToCurrency(final_financial)}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default PaymentDataTable;
