/* eslint-disable camelcase */
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { Paths, validation } from '../../utils/constants'
import InputComponent from '../input/form/input'
import TextAreaComponent from '../input/form/textarea'
import CheckboxComponent from '../input/form/checkbox'
import { css } from '@emotion/react';

const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding: 2rem 10%;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #fdfeff;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `
};


const ProductFamiliesForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
}) => {
  const { handleSubmit, onSubmit } = props
  return(
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar família de produtos</span>
      <form css={useStyles.form}>
        <Field
          name='name'
          component={InputComponent}
          label={'Nome'}
          placeholder={'Insira o nome da família de produtos'}
          validate={[validation.required]}
        />
        <Field
          name='description'
          component={TextAreaComponent}
          label={'Descrição'}
          placeholder={'Insira a descrição da familia de produtos'}
        />
        <Field name='invoices_through_product' component={CheckboxComponent} placeholder={'Faturas geradas apenas através dos produtos'} />
        <Field name='roomable' component={CheckboxComponent} placeholder={'Produtos ensaláveis'} />
        <div css={useStyles.buttons}>
          <Link to={Paths.PRODUCT_FAMILY_LIST} className='red small'>
            <span> Cancelar </span>
          </Link>
          <button onClick={handleSubmit(onSubmit)} className='blue small'>
              <span> Salvar família </span>
          </button>
        </div>
      </form>
    </div>
  )
}

export default compose<any>(
  reduxForm({
      form: 'productFamilyForm',
  }),
)(ProductFamiliesForm);
