/* eslint-disable camelcase */

import React from 'react';
import { colors, PaginationType, defaultPagination, CalendarEventAttributes, Role } from '../../utils/constants';
// import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { error, success } from 'react-notification-system-redux';
import { format, parseISO } from 'date-fns';
import { css } from '@emotion/react';
import DatePickerComponent from '../input/form/datepicker';
import { RootState } from '../../store/configureStore';
import { UI_SET_LOADING_OPEN } from '../../store/ui';
import { evaluate_permissions, get_company_search, getCompanyFilterOptions } from '../../utils/functions';
import { DELETE_CALENDAR_EVENT, FETCH_CALENDAR_EVENTS } from '../../store/calendar_events';
import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import CalendarEventsFormContainer from '../../containers/CalendarEventsFormContainer';
import TooltipButton from '../shared/TooltipButton';
import PenIcon from '../icon/PenIcon';
import TrashIcon from '../icon/TrashIcon';
import { IconModal } from '../modal/Modal';
import SelectComponent from '../input/form/select';
import InputComponent from '../input/form/input';

const WraperCss = css`
  flex: 1;
  padding: 2rem 10%;
  display: flex;
  flex-direction: column;
  & .title {
    text-align: left;
  }
`;

const FilterCss = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  & > span {
    margin-bottom: 14px;
  }
  & .inputArea {
    width: 10rem !important;
    padding-right: 3rem;
  }
`;

const TableCss = css`
  & .include {
    display: flex;
    margin-left: auto;
    margin-bottom: 1rem;
  }

  & .actionsIcon {
    color: ${colors.darkGrayBlue};
    cursor: pointer;
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
    & tbody > tr {
      background: white;
    }
  }
`;

const CalendarEventsTable = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, company_descendants, profile },
    account: { companies },
  } = state;

  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [calendarEvents, setCalendarEvents] = React.useState<CalendarEventAttributes[]>([]);
  const [nameFilterValue, setNameFilterValue] = React.useState('');
  const [startsAtDateFilterValue, setStartsAtFilterValue] = React.useState<Date | null>(null);
  const [endsAtFilterValue, setEndsAtFilterValue] = React.useState<Date | null>(null);
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const [editingCalendarEvent, setEditingCalendarEvents] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });

  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const fetchCalendarEventsMethod = React.useCallback(
    async ({
      newPagination = pagination,
      company_filter = companyFilterValue,
      starts_at_filter = startsAtDateFilterValue,
      ends_at_filter = endsAtFilterValue,
    }: {
      newPagination?: PaginationType;
      starts_at_filter?: Date | null;
      ends_at_filter?: Date | null;
      company_filter?: string;
    }) => {
      setLoading(true);
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter,
        current_company_id: company,
      });

      const date_filter_object = {
        ...(starts_at_filter && {
          'q[starts_at_gteq]': new Date(starts_at_filter.setHours(0, 0, 0)).toISOString(),
        }),
        ...(ends_at_filter && {
          'q[ends_at_lteq]': new Date(ends_at_filter.setHours(23, 59, 59)).toISOString(),
        }),
      };

      try {
        const calendar_events = await dispatch(
          FETCH_CALENDAR_EVENTS.request({
            params: {
              filters: {
                'q[name_cont]': nameFilterValue,
                'q[company_id_in]': companies_array,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
                ...date_filter_object,
              },
            },
          }),
        );
        const {
          data: { data, meta },
        } = calendar_events;
        const formattedCalendarEvents = data.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setCalendarEvents(formattedCalendarEvents);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar eventos',
          }),
        );
      }
    },
    [pagination, nameFilterValue, company, companyFilterValue, startsAtDateFilterValue, endsAtFilterValue],
  );

  const deleteCalendarEvent = React.useCallback(
    async (
      id: string,
      {
        setLoading,
        handleClose,
      }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; handleClose: () => void },
    ) => {
      try {
        setLoading(true);
        await dispatch(
          DELETE_CALENDAR_EVENT.request({
            id,
          }),
        );
        setLoading(false);
        dispatch(
          success({
            message: 'Evento removido com sucesso',
          }),
        );
        fetchCalendarEventsMethod({});
        handleClose();
      } catch (e) {
        handleClose();
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao remover evento',
          }),
        );
      }
    },
    [],
  );
  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchCalendarEventsMethod({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchCalendarEventsMethod({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  const init = React.useCallback(async () => {
    await fetchCalendarEventsMethod({});
  }, [company]);

  const handleSavingWhenCreate = async () => {
    setCreate(false);
    await init();
  };
  React.useEffect(() => {
    init();
  }, []);

  const columns = [
    ...(is_above_school_director ? ['Unidade'] : []),
    'Nome',
    'Data Início',
    'Data Fim',
    'Permite Aulas',
    '',
  ];

  return (
    <div css={WraperCss}>
      <span className='title'>Eventos da Escola</span>
      <div css={FilterCss}>
        {is_above_school_director && (
          <SelectComponent
            placeholder='Selecionar Unidade'
            small
            options={companies_options}
            input={{
              value: companyFilterValue,
              onChange: (e: any) => {
                setCompanyFilterValue(e.target.value);
                fetchCalendarEventsMethod({
                  newPagination: { ...defaultPagination },
                  company_filter: e.target.value,
                });
              },
            }}
          />
        )}
        <InputComponent
          placeholder={`Buscar por nome`}
          search
          small
          onSearch={() => {
            fetchCalendarEventsMethod({});
          }}
          input={{
            value: nameFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setNameFilterValue(e.target.value);
            },
          }}
        />
        <DatePickerComponent
          placeholder='Data Início a partir de'
          label='Data Início a partir de'
          datePickerProps={{
            allowSameDateSelection: true,
          }}
          clearFieldFunction={() => {
            setStartsAtFilterValue(null);
            fetchCalendarEventsMethod({
              starts_at_filter: null,
            });
          }}
          input={{
            value: startsAtDateFilterValue,
            name: 'starts_at',
            onChange: (e: Date) => {
              setStartsAtFilterValue(e);
              fetchCalendarEventsMethod({
                starts_at_filter: e,
              });
            },
          }}
        />
        <DatePickerComponent
          placeholder='Data fim até'
          label='Data fim até'
          datePickerProps={{
            allowSameDateSelection: true,
          }}
          clearFieldFunction={() => {
            setEndsAtFilterValue(null);
            fetchCalendarEventsMethod({
              ends_at_filter: null,
            });
          }}
          input={{
            value: endsAtFilterValue,
            name: 'year',
            onChange: (e: Date) => {
              setEndsAtFilterValue(e);
              fetchCalendarEventsMethod({
                ends_at_filter: e,
              });
            },
          }}
        />
      </div>
      <div css={TableCss}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell align='center' key={item}>
                    {item}
                  </TableCell>
                ))}
                <TableCell align='right'>
                  <button
                    disabled={editingCalendarEvent.length > 0}
                    onClick={() => setCreate(!create)}
                    className='green small'
                  >
                    <span> Criar Evento </span>
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                  <Collapse in={create} timeout='auto' unmountOnExit>
                    <CalendarEventsFormContainer close_form={() => setCreate(false)} onSave={handleSavingWhenCreate} />
                  </Collapse>
                </TableCell>
              </TableRow>
              {calendarEvents.map((calendar_event) => {
                const close_editing_form = () => {
                  setEditingCalendarEvents((current) => current.filter((item) => item !== ~~calendar_event.id));
                };
                return (
                  <React.Fragment key={calendar_event.id}>
                    <TableRow>
                      {is_above_school_director && <TableCell align='center'>{calendar_event.company_name}</TableCell>}
                      <TableCell align='center'>{calendar_event.name}</TableCell>
                      <TableCell align='center'>{format(parseISO(calendar_event.starts_at), 'dd-MM-yyyy')}</TableCell>
                      <TableCell align='center'>{format(parseISO(calendar_event.ends_at), 'dd-MM-yyyy')}</TableCell>
                      <TableCell align='center'>{calendar_event.allow_class ? 'Permite aulas' : ''}</TableCell>
                      <TableCell align='right'>
                        <TooltipButton
                          tooltipProps={{
                            title: editingCalendarEvent.includes(~~calendar_event.id)
                              ? 'Cancelar edição'
                              : 'Editar evento',
                          }}
                          Icon={PenIcon}
                          iconProps={{ style: { color: colors.blue } }}
                          iconButtonProps={{
                            disabled:
                              create ||
                              (editingCalendarEvent.length > 0 && !editingCalendarEvent.includes(~~calendar_event.id)),
                            onClick: () =>
                              setEditingCalendarEvents((current) => {
                                if (current.includes(~~calendar_event.id)) {
                                  return current.filter((item) => item !== ~~calendar_event.id);
                                } else {
                                  return current.concat(~~calendar_event.id);
                                }
                              }),
                          }}
                        />
                        <IconModal
                          icon={TrashIcon}
                          title={'Excluir evento do calendário'}
                          tooltipText='Excluir evento do calendário'
                          onConfirm={(props) => deleteCalendarEvent(calendar_event.id, props)}
                          message='Tem certeza que deseja remover o evento do calendário?'
                          iconProps={{ style: { color: 'red' } }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 2}>
                        <Collapse in={editingCalendarEvent.includes(~~calendar_event.id)} timeout='auto' unmountOnExit>
                          <CalendarEventsFormContainer
                            onSave={async () => {
                              close_editing_form();
                              await init();
                            }}
                            calendar_event_id={calendar_event.id}
                            close_form={close_editing_form}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 2}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : ` 'mais de' ${to}`}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CalendarEventsTable;
